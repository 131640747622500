let state = {
    start_date: '2018-09-01',
    basic_tag: {
        id: 0,
        user_id: 0,
        title: '',
        tag: '',
        contact_filter: 'both',
        push_tag_able: 1,
        tag_color: '#2CBCB6',
        tag_action: 0,
        conditions: [{
            id: 0,
            auto_tag_id: 0,
            watched: 'watched',
            video_type: 'specific',
            timeline_type: 'any_time',
            start_date: '2018-09-01',
            end_date: '',
            combination: 'OR',
            project_id: 0,
            specific_videos: [],
        }]
    },
    auto_tag_filter: [{
        value: 'watched',
        label: 'Watched'
    }, {
        value: 'not_watch',
        label: 'Did not Watch'
    }, {
        value: 'clicked_link',
        label: 'Clicked Link'
    }, {
        value: 'not_click',
        label: 'Did not Click Link'
    }, {
        value: 'subscribed',
        label: 'Subscribed'
    }, {
        value: 'not_subscribe',
        label: 'Did not Subscribe'
    }],
    autoTagColors: [
        '#2CBCB6', '#6661A7', '#23919F', '#2B6CB0', '#C9697D', '#105167', '#5EACC9', '#0376CB', '#67B6A8', '#99BD62',
        '#8D512C', '#98BCA9', '#9386CE', '#C25DA6', '#CC9864', '#5F714E', '#653456', '#D8978E', '#65658D', '#6B719F',
        '#BF7580', '#40B8D2', '#A06DB4', '#993B75', '#2A2D78', '#52123F', '#B0A9A9', '#BD5880', '#165CA0', '#6E47FF',
    ]
};

export default state;
