const GET_PLANS_API = '/plans'
const GET_LOCATION_API = '/admin/location'
const GET_BRAINTREE_CLIENT_TOKEN_API = '/billing/paypal/client-token'
const SET_PAYPAL_TRIAL_API = '/billing/paypal/paid/trial'
const COLLABORATORS_API = id => (`/project/${id}/collaborators`)
const SUBSCRIPTION_API = '/subscription'

export {
  GET_PLANS_API,
  GET_LOCATION_API,
  GET_BRAINTREE_CLIENT_TOKEN_API,
  SET_PAYPAL_TRIAL_API,
  COLLABORATORS_API,
  SUBSCRIPTION_API
}
