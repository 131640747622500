<template>
    <transition name="slide-fade">
        <div class="confirm-modal" v-if='show'>
            <div class="confirm-content">
                <div class="confirm-container" :style='{borderColor: modalColors.bg}'>
                    <div class="confirm-head text-center pt-3 pb-3 helvetica-medium" :style='{borderColor: modalColors.bg}'>
                        {{ title || "CONFIRM" }}
                    </div>
                    <div class="confirm-body pt-3 pb-3 flex-column text-center">
                        <div class="body-text pt-2 pb-2 helvetica-medium">
                            {{ text || "Are you sure you want to take this action?" }}
                        </div>
                        <div class="body-text pt-2 pb-2 helvetica-medium" v-if="subText">
                            {{ subText }}
                        </div>
                    </div>
                    <div class="confirm-foot pt-3 pb-4 d-flex justify-content-center">
                        <div class="button-container">
                            <div class="btn cancel-action-btn" @click="$emit('cancel')">
                                <span v-if='!loading'>Cancel</span>
                                <BaseLoader :lightTheme='true' :showLoader='loading' />
                            </div>
                        </div>
                        <div class="button-container">
                            <div class="btn confirm-btn" @click="$emit('confirm')">
                                <span v-if='!loading'>Confirm</span>
                                <BaseLoader :lightTheme='true' :showLoader='loading' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<style lang="less" scoped>
    .confirm-modal {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.5);
        z-index: 15;
        .confirm-content {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            .confirm-container {
                background: #fff;
                width: 520.64px;
                border: 1px solid #0DABD8;
                border-radius: 10px;
                position: relative;
                font-size: 14px;
                .confirm-head {
                    border-bottom: 1px solid #0DABD8;
                    font-size: 20px;
                    padding: 15px 0;
                }
                .confirm-body {
                    padding: 30px 0;
                    .body-text {
                        font-size: 15px;
                    }
                }
                .confirm-foot {
                    padding: 30px 0;
                    .btn {
                        width: 150px;
                        border-radius: 12px;
                        margin: 0 10px;
                    }
                    .cancel-action-btn {
                        background: #707070;
                        color: #fff;
                    }
                    .confirm-btn {
                        background: #07A2D0;
                        color: #fff;
                    }
                }
            }
        }
    }
</style>

<script>
export default {
    name: 'ConfirmModal',
    data: () => ({
        selected: 'yes',
        modalColors: {
            bg: '',
            color: '',
        },
        loading: false,
    }),
    props: [
        'show',
        'title',
        'text',
        'subText',
        'type',
        'loading',
    ],
    mounted() {
        this.getTypeColor();
    },
    methods: {
        getTypeColor() {
            const type = this.$props.type;
            const button = {
                bg: '',
                color: '#fff',
            };
            switch (type) {
                case 'delete':
                    button.bg = '#C1420A';
                break;
                default:
                    button.bg = '#07A2D0';
                break;
            }
            this.modalColors = button;
        }
    }
}
</script>