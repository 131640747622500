import axios from "axios";

const actions = {
	removeProfileAvatar({commit}, del_data) {
		return new Promise((resolve, reject) => {
			axios.post("/settings/avatar-delete", del_data)
				.then((response) => {
					resolve(response);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},

	getSubscriptionData({commit}) {
		return new Promise((resolve, reject) => {
			axios.get("/subscription")
				.then((response) => {
					commit("setUserSubscription", response.data);
					resolve(response);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},

	getSubscriptionPlans({commit}) {
		return new Promise(async (resolve, reject) => {
			await axios.get("/plans")
				.then((response) => {
					commit("setSubscriptionPlans", response.data);
					resolve(response);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},

	getBillingStatus({commit}) {
		return new Promise((resolve, reject) => {
			axios.get("/billing/status")
				.then((response) => {
					commit("setUserBillingStatus", response.data);
					resolve(response);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},

	getUsageData({commit}) {
		return new Promise(async (resolve, reject) => {
			await axios.get("/billing/usage")
				.then((response) => {
					commit("setUserUsage", response.data);
					resolve(response);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},

	getBillingEstimateData({commit}) {
		return new Promise(async (resolve, reject) => {
			await axios.get("/billing/estimate")
				.then((response) => {
					commit("setBillingEstimate", response.data);
					resolve(response);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},

	getBillingInformationData({commit}) {
		return new Promise(async (resolve, reject) => {
			await axios.get("/billing/information")
				.then((response) => {
					commit("setBillingInformation", response.data);
					resolve(response);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},

	getBillingInvoicesData({commit}) {
		return new Promise(async (resolve, reject) => {
			await axios.get("/billing/invoices")
				.then((response) => {
					commit("setBillingInvoices", response.data);
					resolve(response);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
};

export default actions;
