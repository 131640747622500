import auth_state from '../auth/state';
import contacts_state from '../contacts/state';
import settings_state from '../settings/state';
import analytics_state from '../analytics/state';
import admin_state from '../admin/state';
import project_state from '../projects/state';
import plan_state from '../plans/state';
import collaborations_state from '../collaborations/state';

let state = Object.assign(
    admin_state,
    auth_state,
    contacts_state,
    settings_state,
    analytics_state,
    project_state,
    plan_state,
    collaborations_state,
    {
        siteName: "4KPlayer",
        pageTitle: "4KPlayer", // ###document_title
        userData: {
            settings: {
                city: '',
                coutry: '', 
                company: '',
                timezone: ''
            },
            currentTeam: {
                role: ''
            }
        }
    },
);

export default state
