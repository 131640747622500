import axios from 'axios'
import { COLLABORATORS_API } from '../../../config/endpoints'

const actions = {
  async getCollaborators ({ commit }, id) {
    commit('setCollaborationLoading', true)
    return await axios
      .get(COLLABORATORS_API(id))
      .then(res => {
        commit('setCollaborators', res.data)
      })
      .catch(err => {
        commit('setCollaborationLoading', false)
        console.warn(err)
      })
  },
  async updateCollaborators ({ commit }, { id, changes }) {
    commit('setCollaborationLoading', true)
    return await axios
      .put(`${COLLABORATORS_API(id)}_access`, { data: changes })
      .then(() => {
        return axios.get(COLLABORATORS_API(id))
      })
      .then(res => {
        commit('setCollaborators', res.data)
        commit('setCollaborationLoading', false)
        return res.data
      })
      .catch(err => {
        commit('setCollaborationLoading', false)
        console.warn(err)
      })
  }
}

export default actions
