const USER_ROLES = {
    OWNER: {
        title: "Owner",
        value: "owner"
    },
    SUBUSER: {
        title: "Sub-user",
        value: "subuser"
    }
};

const FILTER_TYPES = {
    SELECT: "select",
    CONDITIONS: "conditions",
    LOCATION: "location",
    DATE: "date",
    TITLE: "title"
};

const DATE_COND_TYPES = {
    users: {
        LAST: {
            id: "last",
            value: "greater",
            title: "is in the last"
        },
        EQ: {
            id: "eq",
            value: "equal",
            title: "is equal to"
        },
        BT: {
            id: "bt",
            value: "between",
            title: "is between"
        },
        AFTER: {
            id: "after",
            value: "greater",
            title: "is after"
        },
        ON_AFTER: {
            id: "on_after",
            value: "greater",
            title: "is on or after"
        },
        BEFORE: {
            id: "before",
            value: "less",
            title: "is before"
        },
        ON_BEFORE: {
            id: "on_before",
            value: "less",
            title: "is before or on"
        }
    },
    projects: {
        LAST_UPLOAD: {
            id: "last_upload",
            title: "Uploaded in the last"
        },
        LAST_UPDATE: {
            id: "last_update",
            title: "Updated in the last"
        },
        BEFORE_UPLOAD: {
            id: "before_upload",
            title: "Uploaded before"
        },
        BEFORE_UPDATE: {
            id: "before_update",
            title: "Updated before"
        },
        AFTER_UPLOAD: {
            id: "after_upload",
            title: "Uploaded after"
        },
        AFTER_UPDATE: {
            id: "after_update",
            title: "Updated after"
        }
    }
};
const USER_FILTERS = [
    {
        id: 1,
        title: "Plan",
        type: FILTER_TYPES.SELECT,
        field: "plan"
    },
    {
        id: 2,
        title: "User Type",
        type: FILTER_TYPES.SELECT,
        field: "userType"
    },
    {
        id: 3,
        title: "Related Users",
        type: FILTER_TYPES.CONDITIONS,
        field: "relatedUsers"
    },
    {
        id: 4,
        title: "Location",
        type: FILTER_TYPES.LOCATION,
        field: "location"
    },
    {
        id: 5,
        title: "Last Activity",
        type: FILTER_TYPES.DATE,
        field: "lastActivity"
    },
    {
        id: 6,
        title: "Contact Size",
        type: FILTER_TYPES.CONDITIONS,
        field: "contactSize"
    },
    {
        id: 7,
        title: "Views",
        type: FILTER_TYPES.CONDITIONS,
        field: "views"
    },
    {
        id: 8,
        title: "Bandwidth Used",
        type: FILTER_TYPES.CONDITIONS,
        field: "bandwidthUsage",
        dataType: "size"
    }
];

const PROJECT_FILTERS = [
    {
        id: 1,
        title: "Date",
        type: FILTER_TYPES.DATE,
        field: "createdAt"
    },
    {
        id: 2,
        title: "Title",
        type: FILTER_TYPES.TITLE,
        field: "userType"
    },
    {
        id: 3,
        title: "Views",
        type: FILTER_TYPES.CONDITIONS,
        field: "relatedUsers"
    },
    // {
    //     id: 4,
    //     title: "Clicks",
    //     type: FILTER_TYPES.CONDITIONS,
    //     field: "relatedUsers"
    // },
    // {
    //     id: 5,
    //     title: "Leads",
    //     type: FILTER_TYPES.CONDITIONS,
    //     field: "relatedUsers"
    // },
    {
        id: 1,
        title: "Date",
        type: FILTER_TYPES.DATE,
        field: "createdAt"
    },
    {
        id: 2,
        title: "Title",
        type: FILTER_TYPES.TITLE,
        field: "userType"
    },
    {
        id: 3,
        title: "Views",
        type: FILTER_TYPES.CONDITIONS,
        field: "relatedUsers"
    },
    /*{
      id: 4,
      title: "Clicks",
      type: FILTER_TYPES.CONDITIONS,
      field: "relatedUsers"
    },
    {
      id: 5,
      title: "Leads",
      type: FILTER_TYPES.CONDITIONS,
      field: "relatedUsers"
    }*/
];
const PROJECT_VIDEO_FILTERS = [
    {
        id: 1,
        title: "Date",
        type: FILTER_TYPES.DATE,
        field: "createdAt"
    },
    {
        id: 2,
        title: "Title",
        type: FILTER_TYPES.TITLE,
        field: "userType"
    },
    {
        id: 3,
        title: "Views",
        type: FILTER_TYPES.CONDITIONS,
        field: "relatedUsers"
    }

// >>>>>>> 8e619cf90d00be9c21d83257f394cca3a43d0dce
];

const CONDITION_TYPES = {
    EQ: {
        value: "equal",
        title: "is equal to"
    },
    BT: {
        value: "between",
        title: "is between"
    },
    GT: {
        value: "greater",
        title: "is greater than"
    },
    LT: {
        value: "less",
        title: "is less than"
    }
};
const TITLE_TYPES = {
    IS: {
        id: "is",
        title: "Title is"
    },
    CONTAIN: {
        id: "contain",
        title: "Title contains"
    },
    NOT_CONTAIN: {
        id: "notContain",
        title: "Title does not contain"
    }
};
const DATE_TYPES = {
    DAY: {
        singular: "day",
        plural: "days"
    },
    WEEK: {
        singular: "week",
        plural: "weeks"
    },
    MONTH: {
        singular: "month",
        plural: "months"
    },
    YEAR: {
        singular: "year",
        plural: "years"
    }
};

const PAGINATION_LIMITS = [10, 20, 30, 40, 50];

const SORT_OPTIONS = [
    {
        value: "byDate",
        label: "By Date"
    },
    {
        value: "byName",
        label: "By Name"
    },
    {
        value: "byNoRelatedUsers",
        label: "By No Related Users"
    },
    {
        value: "byContactSize",
        label: "By Contact size"
    },
    {
        value: "byViewCount",
        label: "By View count"
    },
    {
        value: "byBandwidthUsage",
        label: "By Bandwidth Usage"
    },
    {
        value: "byNoOfVideos",
        label: "By No. of Videos"
    }
];

const CUSTOMIZE_COLUMNS = {
    user: "user",
    relatedUsers: "related users",
    other: "other",
    project: "project",
    plan: "plan",
    location: "location",
    subscriptionStatus: "subscription status",
    lastActivity: "last activity",
    role: "role",
    tags: "tags",
    stagePage: "stage page",
    businessInfo: "business info",
    contactSize: "contact size",
    views: "views",
    serviceCost: "service cost",
    bandwidth: "bandwidth",
    storage: "storage",
    referral: "referral",
    age: "age (member since)",
    signupPage: "sign up page",
    compliance: "compliance"
};

const STATUSES = {
    Active: "active",
    Grace: "grace",
    Free: "free",
    Suspended: "suspended",
    Inactive: "inactive",
    Trial: "trial",
    VerifyRequired: "inactive"
};

const DATE_FORMATS = {
    renewalDueDate: "MMM[.] D[,] YYYY",
    startDate: "Y[-]M[-]D",
    lastActivity: "MMM[. ]D[, ]Y[ at ]h[:]mm[ ]A",
    expireDate: "MM[/]YY"
};

const NO_AVATAR = "../../../../../static/img/nopic.png";

const DEFAULT_PLAN_ID = "starter-monthly";

const INTERVALS = {
    monthly: "Month",
    yearly: "Year",
    annual: "Year"
};

const ENGAGEMENT_SUMMARY_POPOVER_CONTENT = {
    avgEngagemnt:
        "Avg. Engagement is measured as Avg. View Duration as a percentage of Avg. Video Duration.",
    watchTime: "This is the total number of watch time.",
    avgViewDuration: "This is the average number of watched time."
};

const SUM_BODY_NUMBER_REG = /(\d)(?=(\d{3})+(?!\d))/g;

const USERS_TABLE_PARAMS = {
    active: {
        stateSection: "activeColumns",
        title: "active",
        placeholder: "Search users",
        showButton: true,
        buttonTitle: "create user"
    },
    suspended: {
        stateSection: "suspendedColumns",
        title: "suspended",
        placeholder: "Search suspended users",
        showButton: false,
        buttonTitle: ""
    },
    deleted: {
        title: "deleted",
        stateSection: "deletedColumns",
        placeholder: "Search deleted users",
        showButton: false,
        buttonTitle: ""
    },
    "account-users": {
        title: "account",
        stateSection: "accountUsersColumns",
        placeholder: "Search account users",
        showButton: false,
        buttonTitle: ""
    }
};

const COMPLIANCE_HEADERS = [
    {
        id: 1,
        name: "issue"
    },
    {
        id: 2,
        name: "resolution"
    },
    {
        id: 3,
        name: "moderator"
    }
];

const ADMIN_CHANGE_SUBSCRIPTION_PLAN = {
    firstText:
        "If the new plan requires no payment adjustment, it is an upgrade. Otherwise, it’s a downgrade. ",
    newPlan:
        "The plan change will take effect on the selected date. It also determines when the user will be billed for the new plan.",
    paymentAdjustment:
        "This is the amount they user must pay to start the new plan. It is calculated using credits from current billing cycle if any.",
    adjustmentResolution:
        "The plan change will take effect on the selected date. It also determines when the user will be billed for the new plan."
};

const SIZE_SCALES = {
    MB: "Mb",
    GB: "Gb",
    TB: "Tb"
};

const PLAN_STATUSES = {
    Active: "Active",
    VerifyRequired: "Verify Required",
    Inactive: "Inactive",
    Trial: "Trial",
    Expired: "Expired",
    Cancelled: "Cancelled",
    Failed: "Failed"
};

const PROJECT_EXPERIMENTATION = {
    titleText: "You don’t have any Experiments",
    mainText:
        "Use experiments to determine which video has a better engagement and conversion or which thumbnail has better play rate.",
    popoverThumbnailText: "Determine which thumbnail has a better play rate.",
    popoverVideosText: "Determine which video is more engaging."
};

const NEW_THUMBNAIL_EXPERIMENT = {
    videoAndThumbnail1:
        "Thumbnail experiments enables you to determine the best thumbnail for you video by comparing the",
    videoAndThumbnail2:
        " of the thumbnails. Video and Image thumbnails are supported.",
    perfomanceGoals1:
        "Performance goals are used to determine the best-performing Thumbnail. In Thumbnail Experiments, the video",
    perfomanceGoals2:
        " is used. Play rate is the percent of people who viewed your video thumbnail and clicked the play button to watch the video.",
    durationOfExperiment:
        "How long do you want to run this experiment? For the most reliable results, the video should receive enough views within the set duration.",
    postCompletionAction:
        "Set what happens after the set duration of experiment elapses.",
    playRate: "Play Rate"
};

const OWNER_WARNING =
    "You are currently viewing a sub-user account. To see billing information and more, go to owner’s account.";
const COMPLIANCE_WARNING = "This account has no record of compliance issues.";

const USER_INFO_MENU_ITEMS = [
    {
        id: 1,
        text: "Edit Profile Information",
        value: "editProfile"
    },
    {
        id: 2,
        text: "Edit Billing Information",
        value: "editBilling"
    },
    {
        id: 3,
        text: "Change Subscription plan",
        value: "changePlan"
    },
    {
        id: 4,
        text: "Prompt password reset",
        value: "passwordReset"
    },
    {
        id: 5,
        text: "View Billing history",
        value: "viewBillingHistory"
    },
    {
        id: 6,
        text: "View Billing Information",
        value: "viewBillingInfo"
    },
    {
        id: 7,
        text: "Add Bandwidth",
        value: "addBandwidth"
    },
    {
        id: 8,
        text: "Add Storage",
        value: "addStorage"
    },
    {
        id: 9,
        text: "Suspend User",
        value: "suspendUser"
    },

    {
        id: 10,
        text: "Delete User",
        value: "deleteUser"
    },
    {
        id: 11,
        text: "Unsuspend User",
        value: "unsuspendUser"
    },
    {
        id: 12,
        text: 'Restore User',
        value: 'restoreUser'
    }
];

const ADMIN_NOTES_MENU_ITEMS = [
    {
        id: 1,
        text: "Delete",
        value: "delete"
    },
    {
        id: 2,
        text: "Edit",
        value: "edit"
    }
];

const ADMIN_MODAL_SUSPEND = {
    periodOptions: [
        {
            id: 1,
            description: "Specific No. of days",
            title: "specificNumber"
        },
        {
            id: 2,
            description: "Forever",
            title: "forever"
        }
    ],
    warnings: ["No. of days required", "This is not a valid No. of days"],
    checkboxItems: [
        {
            id: 21,
            text: "Suspend all other users in the account",
            value: "suspendSubUsers",
            checkedDefault: true
        },
        {
            id: 32,
            text: "Notify user about this suspension",
            value: "notifyUser",
            checkedDefault: false
        }
    ],
    confirmationWord: "SUSPEND"
};

const ADMIN_MODAL_DELETE = {
    checkboxTems: [
        {
            id: 111,
            text: "Delete all other users in the account",
            value: "deleteSubUsers"
        },
        {
            id: 222,
            text: "Block email from being reused for new accounts",
            value: "blockEmail"
        }
    ],
    confirmationWord: "DELETE"
};

const ADMIN_MODAL_EDIT_PROFILE = {
    radioItems: [
        {
            id: 1,
            value: "autogenerate",
            text: "Autogenerate Password"
        },
        {
            id: 2,
            value: "custom",
            text: "Custom Password"
        }
    ],
    confirmationWord: "CONFIRM",
    checkboxItems: [
        {
            id: 21,
            value: "generatePassword",
            text: "Generate new account password"
        },
        {
            id: 22,
            value: "notifyUser",
            text: "Notify user about this account update"
        }
    ]
};

const ERRORS_FORM = {
    first_name: "First name is required.",
    name: "Are you sure you entered your name correctly?",
    invalidEmail: "Invalid Email Address.",
    email:
        "This email is already registered. Please try a different email address.",
    curr_password: "Current password invalid.",
    password:
        "Use 8 characters or more with a mix of letters, numbers & symbols.",
    password_confirmation: "Password does not match.",
    firstNameRequired: false,
    nameInvalid: false,
    emailRequired: false,
    emailInvalid: false,
    emailExist: false,
    currPasswordInvalid: false,
    passwordInvalid: false,
    passwordConfirmInvalid: false,
    passwordType: {
        uppercase: false,
        lowercase: false,
        number: false,
        special: false,
        minimum: false
    }
};

const ADMIN_ACTIVITY_LOG = {
    filterOptions: [{
        id: 1,
        text: 'Login/Logout',
        value: 'login/out'
    },
        {
            id: 2,
            text: 'Video',
            value: 'video'
        },
        {
            id: 3,
            text: 'Project',
            value: 'project'
        },
        {
            id: 4,
            text: 'Billing',
            value: 'billing'
        },
        {
            id: 5,
            text: 'Compliance',
            value: 'compliance'
        },
        {
            id: 6,
            text: 'Date',
            value: 'date'
        }
    ]
}

const ADMIN_ADD_BANDWIDTH_MODAL = {
    warningText: [
        "Size in GB is required.",
        "This is not a valid size in GB. Enter size in GB."
    ],
    options: [
        {
            id: 1,
            description: "Valid with each subscription renewal",
            title: "renewal"
        },
        {
            id: 2,
            description: "Valid for a specific No. of billing cycle",
            title: "specific"
        }
    ]
};

export {

    USER_FILTERS,
    PROJECT_FILTERS,
    FILTER_TYPES,
    USER_ROLES,
    CONDITION_TYPES,
    DATE_COND_TYPES,
    DATE_TYPES,
    TITLE_TYPES,
    PAGINATION_LIMITS,
    SORT_OPTIONS,
    CUSTOMIZE_COLUMNS,
    STATUSES,
    DATE_FORMATS,
    NO_AVATAR,
    DEFAULT_PLAN_ID,
    INTERVALS,
    ENGAGEMENT_SUMMARY_POPOVER_CONTENT,
    SUM_BODY_NUMBER_REG,
    USERS_TABLE_PARAMS,
    COMPLIANCE_HEADERS,
    ADMIN_CHANGE_SUBSCRIPTION_PLAN,
    SIZE_SCALES,
    PLAN_STATUSES,
    OWNER_WARNING,
    COMPLIANCE_WARNING,
    USER_INFO_MENU_ITEMS,
    ADMIN_NOTES_MENU_ITEMS,
    PROJECT_EXPERIMENTATION,
    NEW_THUMBNAIL_EXPERIMENT,
    ADMIN_MODAL_SUSPEND,
    ADMIN_MODAL_DELETE,
    ADMIN_MODAL_EDIT_PROFILE,
    ERRORS_FORM,
    ADMIN_ADD_BANDWIDTH_MODAL,
    ADMIN_ACTIVITY_LOG,
    PROJECT_VIDEO_FILTERS
};
