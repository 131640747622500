<template>
  <div class="first-access-holder">
    <router-view :user="user"></router-view>
  </div>
</template>

<script>
export default {
  name: "FirstAccess",
  data() {
    return {
      user: {},
    };
  },
  created() {
      console.log(document.querySelector('#app'));
    this.user = JSON.parse(localStorage.getItem("user_info"));
  },
  mounted(){
        
  }
};
</script>
<style lang="less" scoped>
.first-access-holder {
  width: 768px;
  max-width: 100%;
  background: #ffffff;
  margin: 10px auto;
  padding: 20px 30px;
  min-height: 400px;
  box-shadow: 0 60px 80px 50px #080e540f;
  border-radius: 20px;
}
</style>