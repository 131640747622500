<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        width="5.395"
        height="9.709"
        viewBox="0 0 5.395 9.709"
        class="separator"
    >
        <defs>
            <clipPath id="clip-path">
                <rect class="cls-1" width="9.709" height="5.395" />
            </clipPath>
        </defs>
        <g
            id="Arrow_-_Dropdown_-_Direction"
            data-name="Arrow - Dropdown - Direction"
            class="cls-2"
            transform="translate(0 9.709) rotate(-90)"
        >
            <path
                id="Path_2500"
                data-name="Path 2500"
                class="cls-3"
                d="M15.582,33.5a.328.328,0,0,0-.463,0l-4.075,4.083L6.959,33.5a.328.328,0,1,0-.463.463L10.8,38.267a.32.32,0,0,0,.232.1.334.334,0,0,0,.232-.1l4.307-4.307A.321.321,0,0,0,15.582,33.5Z"
                transform="translate(-6.168 -33.168)"
            />
        </g>
    </svg>
</template>
<script>
export default {
    name: 'ArrowRightIcon'
};
</script>
<style lang="less" scoped>
.cls-1 {
    fill: var(--textThemeColor);
}

.cls-2 {
    clip-path: url(#clip-path);
}

.cls-3 {
    // fill: #21455e;
    // stroke: #21455e;
      fill: var(--textThemeColor);
    stroke: var(--textThemeColor);
    stroke-width: 0.4px;
}
</style>
