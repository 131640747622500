<template>
    <span v-if='showLoader' class='loader d-flex' :class='centered ? "centered" : ""'>
        <div v-if='message' class='message'>{{ message }}</div>
        <svg xmlns="http://www.w3.org/2000/svg" :width="width? width : '19.675'" :height="height ? height : '19.773'" viewBox="0 0 19.675 19.773">
            <g id="Group_8709" data-name="Group 8709" transform="translate(-471.354 -1473.714)">
                <g id="Ellipse_3674" data-name="Ellipse 3674" transform="translate(471.354 1473.714)" fill="none" stroke="rgba(33,69,94,0.2)" stroke-width="2">
                <circle cx="9.837" cy="9.837" r="9.837" stroke="none"/>
                <circle cx="9.837" cy="9.837" r="8.837" fill="none"/>
                </g>
                <path id="Path_7455" data-name="Path 7455" d="M58.971,51.3A8.113,8.113,0,0,1,51.3,58.97v1.72a9.851,9.851,0,0,0,9.356-9.39Z" transform="translate(430.355 1432.797)" :fill="lightTheme ? '#fff' : '#0BACDB'"/>
            </g>
        </svg>
    </span>
</template>

<script>
export default {
    props: ['centered', 'lightTheme', 'message', 'showLoader', 'height', 'width'],
    name: 'BaseLoader',
}
</script>

<style scoped>
    .loader svg {
        transition: 1s;
        color: #0BACDB;
        margin: auto;
        animation: spin;
        animation-iteration-count: infinite;
        animation-duration: 1s;
        z-index: 10;
    }

    .message {
        color: #fff;
        font-size: 15px;
        padding: 0 10px;
    }

    .centered svg {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    @keyframes spin {
        to {
            transform: rotate(360deg);
        }
    }
</style>