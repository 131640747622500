<template>
    <div class='message-box-container'>
        <div class="message-box-content">
            <div class="message-box-head">{{ head }}</div>
            <div class="message-box-body">
                <div
                    v-for='(message, index) in messages'
                    :key='index'
                    class="message"
                >
                    {{ message }}
                </div>
            </div>
            <div class="message-box-footer text-center">
                <el-button round type="primary" @click='$emit("close")'>Close</el-button>
            </div>
        </div>
    </div>
</template>

<style scoped lang='less'>
    .message-box-container {
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        margin: auto;
        background-color: rgba(1, 26, 34, 0.3);
        transition: 1s;
        backdrop-filter: blur(2px);
        .message-box-content {
            position: absolute;
            top: 100px;
            left: 0;
            right: 0;
            margin: auto;
            height: max-content;
            width: max-content;
            background: #FFFFFF;
            border: 1px solid #0BACDB;
            border-radius: 12px;
            .message-box-head {
                padding: 15px;
                border-bottom: 1px solid #EBF9FA;
                text-transform: uppercase;
                text-align: center;
                font-weight: bold;
            }
            .message-box-body {
                padding: 30px 15px;
                .message {
                    padding: 5px 0;
                }
            }
            .message-box-footer {
                border-top: 1px solid #EBF9FA;
                padding: 15px;
            }
        }
    }
    @media (max-width: 960px) {
        .message-box-container {
            .message-box-content {
                width: 100%;
            }
        }
    }
</style>

<script>
export default {
    name: 'BaseMessageBox',
    props: ['head', 'messages']
}
</script>