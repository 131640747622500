<template>
    <div class='select-container' @mouseleave="showOptions = false">
        <div
            class="select-div form-control"
            :style='{
                width: width ? width : "auto",
                height: height ? height : "auto"
            }'>
            <div class="select-options" @click='showOptions = !showOptions'>
                <div class="selected">
                    <div class='d-flex justify-content-between'>
                        <div class="selected-text" v-if='options && options.length && options.filter(index => index.id === selected)[0]'>
                            {{ options.filter(index => index.id === selected)[0].text }}
                        </div>
                        <div class="arrow-icon" :class='showOptions ? "rotate" : ""'>
                            <img src="../../../assets/img/stage/arrow-down.svg" alt="arrow">
                        </div>
                    </div>
                </div>
                <div v-if='showOptions' class='options-container'>
                    <div
                        class="select-option d-flex"
                        v-for='(option, index) in options'
                        :key='index'
                        @click='selectOption(option.id)'
                    >
                        <div :style='{ opacity: option.id !== selected ? 0 : 1 }' class='icon-container'>
                            <i class="el-icon-check"></i>
                        </div>
                        <div class="option-text" :class='option.id === selected ? "highlight" : ""'>
                            {{ option.text }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="less" scoped>
    .select-container {
        cursor: pointer;
        .select-div {
            position: relative;
            .select-options {
                position: absolute;
                left: 0;
                width: 100%;
                .options-container {
                    margin-top: 20px;
                    border: 1px solid var(--color-blue);
                    background: #fff;
                    border-radius: 5px;
                    transition: 0.3s;
                    animation-name: appear;
                    animation-iteration-count: 1;
                    animation-duration: 0.3s;
                    .select-option {
                        .icon-container {
                            i {
                                position: relative;
                                top: 13px;
                                left: 5px;
                            }
                        }
                        .option-text {
                            padding: 13px;
                            width: 100%;
                            position: relative;
                            left: 0;
                        }
                        .highlight {
                            font-weight: 600;
                        }
                    }
                }
                .selected {
                    margin: 5px;
                    .arrow-icon {
                        margin-right: 12px;
                        transition: 0.3s;
                    }
                    .selected-text {
                        padding-left: 12px;
                    }
                }
            }
        }
    }

    @keyframes appear {
        from {
            opacity: 0;
        }
        top {
            opacity: 1;
        }
    }

    .rotate {
        transform: rotate(180deg);
    }
</style>

<script>

export default {
    name: 'BaseSelect',
    props: ['options', 'width', 'height'],
    data: () => ({
        selected: null,
        showOptions: false,
    }),
    mounted() {
        this.selectFirst();
    },
    methods: {
        selectOption(option) {
            this.selected = option;
            this.$emit('option', option);
        },
        selectFirst() {
            const options = this.$props.options;
            if (options && options.length && options[0].id) {
                this.selected = options[0].id;
            }
        }
    }
}
</script>