<template>
    <div class="search-bar">
        <input
            type="text"
            class="form-control"
            :placeholder="placeholder ? placeholder : 'Search'"
            v-model='value'>
        <img alt src="../../../assets/img/stage/icon_search.svg"/>
    </div>
</template>

<style lang="less" scoped>
    .search-bar {
        width: 260.85px;
        height: 35.64px;
        position: relative;
        input {
            width: -moz-available;
            width: fit-content;
            height: 35.64px;
            padding-left: 32px;
            border: 1px solid #99D6E9;
            border-radius: 20px;
        }
        input::placeholder {
            color: #A3BAC6;
        }
        img {
            position: absolute;
            left: 10px;
            top: 10px;
        }
    }
</style>

<script>
export default {
    name: 'BaseSearch',
    props: ['placeholder', 'onKey'],
    data: () => ({
        value: '',
    }),
    mounted() {
        const key = this.$props.onKey;
        const $this = this;
        document.addEventListener('keydown', function (e) {
            if (e.key === key || e.code === key) {
                $this.$emit('query', $this.value);
            }
        })
    }
}
</script>