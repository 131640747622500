<template>
  <div class="card-container">
    <el-row :gutter="20">
      
      
  
   
      <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
        <div class="grid-content text-center text-uppercase">
          <p>Basic</p>
        </div>
        <div class="text-center text-uppercase">
          <Button class="btn btn-primary" @click="PurchasePlan('basic')">Prchase</Button>
        </div>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
        <div class="grid-content text-center text-uppercase">
          <p>Pro</p>
        </div>
          <div class="text-center text-uppercase">
          <Button class="btn btn-primary" @click="PurchasePlan('pro')" >Prchase</Button>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      
    };
  },
  computed: {

  
  },
  methods:{
        PurchasePlan(plan) {
  
      let post_data = {
        url: "/whitelabel/user/get-licence",
      };

      this.$store
        .dispatch("post", { ...post_data })
        .then((response) => {
          if (response.data.success) {
            this.$router.push({ path: '/whitelabel/dashboard' })
            window.location.reload();
            // this.$router.push({name: 'WhiteBoardDashBoard'})
          }
        })
        .catch((error) => {});

    },
  }
};
</script>

<style lang="less" scoped>
.card-container {
  padding: 50px 30px;
  .el-row{
    margin: 0 !important;
    max-width: 1400px;
  }
  .grid-content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #6ec1ab;
    border-radius: 7px;
    margin-bottom: 70px;
    padding: 15px;
    min-height: 170px;
    max-width: 420px;
    p {
      font-size: 22px;
      color: #fff;
    }
    p:first-child {
      font-size: 60px;
      font-weight: bold;
      color: #fff;
    }
    .el-progress-bar {
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 0;
    }
    .el-progress-bar__outer {
      background: #0bd603;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-left-radius: 50px;
      height: 15px !important;
    }
    .el-progress-bar__inner {
      background: #d93636;
      border-radius: 0;
    }
  }
      .upgrade-plan {
        border-radius: 5px;
        background-image: linear-gradient(114deg, #094077 0%, #d93636 100%);
        p{
          font-size: 35px !important;
        }
      }
}

@media (max-width: 1440px) {
  .card-container {
        min-height: 150px;

    .grid-content.upgrade-plan{
        p{
          font-size: 22px !important;

        }


    }
  }
}

@media (max-width: 960px) {
  .card-container {
    min-height: 200px;

    .grid-content.upgrade-plan{
        p{
          font-size: 20px !important;

        }


    }
  }
}

</style>
