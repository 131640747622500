<template>
    <el-dialog
            class="huge-form"
            :visible.sync="visible"
            size="huge"
            :before-close="handleClose">
        <div class="dialog-container text-center">

            <el-row>
                <el-col :span="24">
                    <el-autocomplete class="special-input"
                                     ref="searchQuery"
                                     popper-class="search-autocomplete"
                                     custom-item="search-autocomplete"
                                     icon="search"
                                     id="search-query"
                                     :fetch-suggestions="querySearch"
                                     :trigger-on-focus="false"
                                     @select="handleSelect"
                                     @keyup.enter.native="submit"
                                     placeholder="Search" v-model="query">
                    </el-autocomplete>
                </el-col>
            </el-row>

        </div>
    </el-dialog>
</template>
<style lang="less">
    .special-input {
        .el-input__icon.el-icon-search {
            font-size: 25px;
        }

        .el-input-group__append {
            background: transparent;
            font-size: 25px;
            border: none;
        }
    }

    .search-autocomplete {
        li {
            line-height: normal;
            padding: 7px;

            .value {
                text-overflow: ellipsis;
                overflow: hidden;
            }
            .link {
                font-size: 12px;
                color: #b4b4b4;
            }
            .type {
            }
            .icon {
                float: left;
                width: 40px;
                margin-right: 5px;
                opacity: 0.6;
                margin-top: 5px;
            }
            .result {
                margin-left: 40px;
            }
        }
    }
</style>
<script>
    export default {
        data () {
            return {
                visible: false,
                query: '',
                loading: false
            }
        },

        methods: {
            handleOpen () {
                this.visible = true;
            },

            handleClose () {
                this.visible = false;
            },

            submit () {
                this.loading = true;
            },

            querySearch (queryString, cb) {
                console.log(queryString);
                this.axios({
                    url: 'search',
                    method: 'post',
                    params: {
                        query: queryString
                    }
                }).then(function (response) {
                    cb(response.data);
                });
            },

            handleSelect (item) {
                window.location = item.link;
            }
        },

        watch: {
            'visible' (value) {
                if (value === true) {
                    window.setTimeout(function () {
                        document.getElementById('search-query').getElementsByClassName('el-input__inner')[0].focus();
                    });
                    /* window.setTimeout ()->
                #document.getElementById('video-title-input').focus();
                    $('#import-query > div > input').focus();
                , 0
                    this.$refs.searchQuery.focus(); */
                }
            }
        },

        components: {}
    }
</script>
