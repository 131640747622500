import { render, staticRenderFns } from "./WhiteBoardSettings.vue?vue&type=template&id=687d8992&scoped=true&"
import script from "./WhiteBoardSettings.vue?vue&type=script&lang=js&"
export * from "./WhiteBoardSettings.vue?vue&type=script&lang=js&"
import style0 from "./WhiteBoardSettings.vue?vue&type=style&index=0&id=687d8992&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "687d8992",
  null
  
)

export default component.exports