import { render, staticRenderFns } from "./BaseAlert.vue?vue&type=template&id=03e9f4cb&scoped=true&"
import script from "./BaseAlert.vue?vue&type=script&lang=js&"
export * from "./BaseAlert.vue?vue&type=script&lang=js&"
import style0 from "./BaseAlert.vue?vue&type=style&index=0&id=03e9f4cb&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03e9f4cb",
  null
  
)

export default component.exports