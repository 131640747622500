<template>
  <div class="smtp">
    <FirstAccessTabs></FirstAccessTabs>
    <div>
      <div class="email-head d-flex align-items-center">
        <h3 class="head-title mr-3">SMTP Settings</h3>
        <el-button
          type="primary"
          v-if="testConnection()"
          plain
          @click="testEmailConnection()"
          >Test Connection</el-button
        >
      </div>

      <p class="head-title mr-3">Email Header</p>

      <el-row>
        <el-col :span="12" class="pr-2">
          <span class="key-label mb-5">From name</span>
          <input class="expand field" v-model="email_detail.from_name" />
        </el-col>
        <el-col :span="12">
          <span class="key-label mb-5">From Email Address</span>
          <input class="expand field" v-model="email_detail.from_email" />
        </el-col>
      </el-row>

      <p class="head-title mr-3">Email Footer (CAN SPAM)</p>

      <el-row>
        <el-col :span="12" class="pr-2">
          <div class="plan-tags mb-5">
            <div class="mt-4">
              <span class="key-label min65">Company Name:</span>

              <input
                class="expand field"
                type="text"
                v-model="email_detail.company_name"
              />
            </div>
          </div>
        </el-col>
        <el-col :span="12" class="pr-2">
          <div class="plan-tags mb-5">
            <div class="mt-4">
              <span class="key-label min65">Address:</span>
              <input class="expand field" v-model="email_detail.address" />
            </div>
          </div>
        </el-col>
        <el-col :span="24" class="pr-2">
          <div class="plan-tags mb-5">
            <div class="mt-4">
              <span class="key-label min65">City:</span>
              <input class="expand field" v-model="email_detail.city" />
            </div>
          </div>
        </el-col>
      </el-row>

      <p class="head-title mr-3">Authentication</p>
      <el-row>
        <el-col :span="12" class="pr-2">
          <div class="plan-tags mb-5">
            <div class="mt-4">
              <span class="key-label min65">SMTP Host:</span>

              <input class="expand field" v-model="email_detail.host" />
            </div>
          </div>
        </el-col>
        <el-col :span="12" class="pr-2">
          <div class="plan-tags mb-5">
            <div class="mt-4">
              <span class="key-label min65">SMTP Port Number:</span>

              <input class="expand field" v-model="email_detail.port_no" />
            </div>
          </div>
        </el-col>
        <el-col :span="12" class="pr-2">
          <div class="plan-tags mb-5">
            <div class="mt-4">
              <span class="key-label min65">SMTP Username:</span>

              <input class="expand field" v-model="email_detail.username" />
            </div>
          </div>
        </el-col>
        <el-col :span="12" class="pr-2">
          <div class="plan-tags mb-5">
            <div class="mt-4">
              <span class="key-label min65">SMTP Password</span>

              <input class="expand field" v-model="email_detail.password" />
            </div>
          </div>
        </el-col>
        <!-- <el-col :span="12" class="pr-2">
          <div class="plan-tags mb-5">
            <div class="mt-4">
              <span class="key-label min65">SMTP TLS Status:</span>

              <input class="expand field" v-model="email_detail.tls_status" />
            </div>
          </div>
        </el-col> -->
        <el-col :span="24" class="pr-2">
          <div class="mt-4 text-right mb-5">
            <button
              type="button"
              class="btn btn-primary btn-primary-outline"
              @click="storeEmail"
            >
              Save
            </button>
          </div>
        </el-col>
      </el-row>
    </div>

    <div class="email-head integrations">
      <!-- <h3 class="head-title mr-3">Integrations</h3> -->
      <!-- <div class="integrations"> -->
      <!-- <div class="integration-desc" :class="this.type === 'aweber' ? 'is-active' : ''">
          <ul>
            <li @click="storeIntegrationDetail('aweber')">Aweber</li>
          </ul>
        </div>
        <div class="aweber-info" v-if="type === 'aweber'">
          <div class="form-group">
            <label class="key-label">API KEY</label>
            <input class="expand field" v-model="aweber.api_key">
            <span
              class="error"
              v-if="aweber.msg !== '' && aweber.type === 'key'"
            >
              <img
                alt
                class="warning-icon"
                src="../../assets/img/auth/warning.svg"
              />
              {{ aweber.msg }}</span
            >
          </div>

          <div class="form-group">
            <label class="key-label">API SECRET</label>
            <input class="expand field" v-model="aweber.api_secret">
            <span
              class="error"
              v-if="aweber.msg !== '' && aweber.type === 'secret'"
            >
              <img
                alt
                class="warning-icon"
                src="../../assets/img/auth/warning.svg"
              />
              {{ aweber.msg }}</span
            >
          </div>

          <div class="aweber-info-buttons">
            <el-button type="info" class="btn-primary-gray" @click="storeIntegrationDetail"
              >Cancel</el-button
            >
            <el-button type="primary" class="btn-primary" @click="storeIntegration('aweber')"
              >Save</el-button
            >
          </div>
        </div>   -->

      <!-- <div class="integration-desc"  :class="this.type === 'mailchimp' ? 'is-active' : ''">
          <ul>
            <li @click="storeIntegrationDetail('mailchimp')">MailChimp</li>
          </ul>
        </div>
        <div class="aweber-info" v-if="type === 'mailchimp'">
          <div class="form-group">
            <label class="key-label">API KEY</label>
            <input class="expand field" v-model="mailchimp.api_key">
            <span
              class="error"
              v-if="mailchimp.msg !== '' && mailchimp.type === 'key'"
            >
              <img
                alt
                class="warning-icon"
                src="../../assets/img/auth/warning.svg"
              />
              {{ mailchimp.msg }}</span
            >
          </div>

          <div class="form-group">
            <label class="key-label">API SECRET</label>
            <input class="expand field" v-model="mailchimp.api_secret">
            <span
              class="error"
              v-if="mailchimp.msg !== '' && mailchimp.type === 'secret'"
            >
              <img
                alt
                class="warning-icon"
                src="../../assets/img/auth/warning.svg"
              />
              {{ mailchimp.msg }}</span
            >
          </div>

          <div class="aweber-info-buttons">
            <el-button type="info" class="btn-primary-gray" @click="storeIntegrationDetail"
              >Cancel</el-button
            >
            <el-button type="primary" class="btn-primary" @click="storeIntegration('mailchimp')"
              >Save</el-button
            >
          </div>
        </div> -->

      <!-- <div class="integration-desc" :class="this.type === 'convertkit' ? 'is-active' : ''">
          <ul>
            <li @click="storeIntegrationDetail('convertkit')">ConvertKit</li>
          </ul>
        </div>
        <div class="aweber-info" v-if="type === 'convertkit'">
          <div class="form-group">
            <label class="key-label">API KEY</label>
            <input class="expand field" v-model="convertkit.api_key">
            <span
              class="error"
              v-if="convertkit.msg !== '' && convertkit.type === 'key'"
            >
              <img
                alt
                class="warning-icon"
                src="../../assets/img/auth/warning.svg"
              />
              {{ convertkit.msg }}</span
            >
          </div>

          <div class="form-group">
            <label class="key-label">API SECRET</label>
            <input class="expand field" v-model="convertkit.api_secret">
            <span
              class="error"
              v-if="convertkit.msg !== '' && convertkit.type === 'secret'"
            >
              <img
                alt
                class="warning-icon"
                src="../../assets/img/auth/warning.svg"
              />
              {{ convertkit.msg }}</span
            >
          </div>

          <div class="aweber-info-buttons">
            <el-button type="info" class="btn-primary-gray" @click="storeIntegrationDetail"
              >Cancel</el-button
            >
            <el-button type="primary" class="btn-primary" @click="storeIntegration('convertkit')"
              >Save</el-button
            >
          </div>
        </div> -->

      <!-- <div class="integration-desc" :class="this.type === 'getresponse' ? 'is-active' : ''">
          <ul>
            <li @click="storeIntegrationDetail('getresponse')">GetResponse</li>
          </ul>
        </div>
        <div class="aweber-info" v-if="type === 'getresponse'">
          <div class="form-group">
            <label class="key-label">API KEY</label>
            <input class="expand field" v-model="getresponse.api_key">
            <span
              class="error"
              v-if="getresponse.msg !== '' && getresponse.type === 'key'"
            >
              <img
                alt
                class="warning-icon"
                src="../../assets/img/auth/warning.svg"
              />
              {{ getresponse.msg }}</span
            >
          </div>

          <div class="form-group">
            <label class="key-label">API SECRET</label>
            <input class="expand field" v-model="getresponse.api_secret">
            <span
              class="error"
              v-if="getresponse.msg !== '' && getresponse.type === 'secret'"
            >
              <img
                alt
                class="warning-icon"
                src="../../assets/img/auth/warning.svg"
              />
              {{ getresponse.msg }}</span
            >
          </div>

          <div class="aweber-info-buttons">
            <el-button type="info" class="btn-primary-gray" @click="storeIntegrationDetail"
              >Cancel</el-button
            >
            <el-button type="primary" class="btn-primary" @click="storeIntegration('getresponse')"
              >Save</el-button
            >
          </div>
        </div> -->
      <!-- <div class="integration-desc" :class="this.type === 'activecampaign' ? 'is-active' : ''">
          <ul>
            <li @click="storeIntegrationDetail('activecampaign')">
              ActiveCampaign
            </li>
          </ul>
        </div> -->
      <!-- <div class="aweber-info" v-if="type === 'activecampaign'">
          <div class="form-group">
            <label class="key-label">API KEY</label>
            <input class="expand field" v-model="activecampaign.api_key">
            <span
              class="error"
              v-if="activecampaign.msg !== '' && activecampaign.type === 'key'"
            >
              <img
                alt
                class="warning-icon"
                src="../../assets/img/auth/warning.svg"
              />
              {{ activecampaign.msg }}</span
            >
          </div>

          <div class="form-group">
            <label class="key-label">API SECRET</label>
            <input class="expand field" v-model="activecampaign.api_secret">
            <span
              class="error"
              v-if="
                activecampaign.msg !== '' && activecampaign.type === 'secret'
              "
            >
              <img
                alt
                class="warning-icon"
                src="../../assets/img/auth/warning.svg"
              />
              {{ activecampaign.msg }}</span
            >
          </div>

          <div class="aweber-info-buttons">
            <el-button type="info" class="btn-primary-gray" @click="storeIntegrationDetail"
              >Cancel</el-button
            >
            <el-button
              type="primary"
              class="btn-primary"
              @click="storeIntegration('activecampaign')"
              >Save</el-button
            >
          </div>
        </div> -->
      <div class="mt-5">
        <el-button
          type="custom"
          class="button__upload"
          style="float: left"
          @click="$router.push('/whitelabel/beginning/branding')"
        >
          Back
        </el-button>
        <el-button
          type="custom"
          class="button__upload"
          style="float: right"
          @click="$router.push('/whitelabel/beginning/plan-webhook')"
        >
          Next
        </el-button>
      </div>
      <!-- </div> -->
      <!-- <div class="integration-desc">
      <ul>
        <li @click="storeIntegrationDetail('aweber')">Aweber</li>
      </ul>
    </div>
    <div class="aweber-info" v-if="type === 'aweber'">
      <p>Aweber</p>
      <div class="form-group">
        <label>API KEY</label>
        <el-input v-model="aweber.api_key"></el-input>
        <span class="key-label error" v-if="aweber.msg !== '' && aweber.type === 'key'">
          <img
            alt
            class="warning-icon"
            src="../../../assets/img/auth/warning.svg"
          />
          {{ aweber.msg }}</span
        >
      </div>

      <div class="form-group">
        <label>API SECRET</label>
        <el-input v-model="aweber.api_secret"></el-input>
        <span
          class="error"
          v-if="aweber.msg !== '' && aweber.type === 'secret'"
        >
          <img
            alt
            class="warning-icon"
            src="../../../assets/img/auth/warning.svg"
          />
          {{ aweber.msg }}</span
        >
      </div> -->
    </div>
  </div>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import FirstAccessTabs from "./tabs.vue";

import { quillEditor } from "vue-quill-editor";
var toolbarOptions = ["bold", "italic", "underline", "strike"];

export default {
  components: {
    quillEditor,
    FirstAccessTabs,
  },
  data() {
    return {
      aweber: {
        api_key: "",
        api_secret: "",
        msg: "",
        type: "",
      },

      activecampaign: {
        api_key: "",
        api_secret: "",
        msg: "",
        type: "",
      },

      getresponse: {
        api_key: "",
        api_secret: "",
        msg: "",
        type: "",
      },

      convertkit: {
        api_key: "",
        api_secret: "",
        msg: "",
        type: "",
      },

      mailchimp: {
        api_key: "",
        api_secret: "",
        msg: "",
        type: "",
      },

      type: "aweber",

      // type: "on_signup",
      // on_signup: {
      //   subject: "",
      //   body: "",
      //   msg: "",
      //   type: "",
      // },
      // on_plan_change: {
      //   subject: "",
      //   body: "",
      //   msg: "",
      //   type: "",
      // },
      // on_cancellation: {
      //   subject: "",
      //   body: "",
      //   msg: "",
      //   type: "",
      // },
      // on_account_suspension: {
      //   subject: "",
      //   body: "",
      //   msg: "",
      //   type: "",
      // },

      // on_password_reset: {
      //   subject: "",
      //   body: "",
      //   msg: "",
      //   type: "",
      // },

      email_detail: {
        from_name: "",
        from_email: "",
        company_name: "",
        address: "",
        city: "",
        username: "",
        password: "",
        port_no: "",
        tls_status: "",
        connection: "",
        host: "",
      },
      editorOption: {
        modules: {
          toolbar: {
            handlers: {
              modules: {
                toolbar: toolbarOptions,
              },
            },
          },
        },
      },
    };
  },
  computed: {
    editor() {
      this.$refs.myQuillEditorOnPassword.quill;
      this.$refs.myQuillEditorOnSuspension.quill;
      this.$refs.myQuillEditorOnCancellation.quill;
      this.$refs.myQuillEditorOnPlanChange.quill;
      this.$refs.myQuillEditor.quill;
    },
  },
  mounted() {
    // console.log("this is current quill instance object", this.editor);
  },
  created() {
    this.getEmailDetail();
    this.getIntegration();
  },
  methods: {
    connectIntegration(name = "") {
      let post_data = {
        url: "/whitelabel/integration/connect",
        name: name,
      };

      var $this = this;
      this.$store.dispatch("post", { ...post_data }).then((response) => {});
    },
    storeIntegrationDetail(integration = "") {
      this.type = integration;
    },

    getIntegration() {
      let post_data = {
        url: "/whitelabel/integration/list",
      };

      var $this = this;
      this.$store
        .dispatch("post", { ...post_data })
        .then((response) => {
          if (response.data.integration) {
            response.data.integration.forEach(function (ele, index) {
              if (ele.name === "aweber") {
                $this.aweber.api_key = ele.api_key;
                $this.aweber.api_secret = ele.api_secret;
              }

              if (ele.name === "activecampaign") {
                $this.activecampaign.api_key = ele.api_key;
                $this.activecampaign.api_secret = ele.api_secret;
              }

              if (ele.name === "getresponse") {
                $this.getresponse.api_key = ele.api_key;
                $this.getresponse.api_secret = ele.api_secret;
              }

              if (ele.name === "convertkit") {
                $this.convertkit.api_key = ele.api_key;
                $this.convertkit.api_secret = ele.api_secret;
              }

              if (ele.name === "mailchimp") {
                $this.mailchimp.api_key = ele.api_key;
                $this.mailchimp.api_secret = ele.api_secret;
              }
            });
          }
        })
        .catch((error) => {});
    },
    storeIntegration(name) {
      var api_key = "";
      var api_secret = "";

      if (name === "aweber") {
        api_key = this.aweber.api_key;
        api_secret = this.aweber.api_secret;

        if (this.aweber.api_key === "") {
          this.aweber.msg = "Please enter api key";
          this.aweber.type = "key";
          return false;
        } else if (this.aweber.api_secret === "") {
          this.aweber.msg = "Please enter api secret";
          this.aweber.type = "secret";
          return false;
        } else {
          this.aweber.msg = "";
          this.aweber.type = "";
        }
      }

      if (name === "activecampaign") {
        api_key = this.activecampaign.api_key;
        api_secret = this.activecampaign.api_secret;

        if (this.activecampaign.api_key === "") {
          this.activecampaign.msg = "Please enter api key";
          this.activecampaign.type = "key";
          return false;
        } else if (this.activecampaign.api_secret === "") {
          this.activecampaign.msg = "Please enter api secret";
          this.activecampaign.type = "secret";
          return false;
        } else {
          this.activecampaign.msg = "";
          this.activecampaign.type = "";
        }
      }

      if (name === "getresponse") {
        api_key = this.getresponse.api_key;
        api_secret = this.getresponse.api_secret;

        if (this.getresponse.api_key === "") {
          this.getresponse.msg = "Please enter api key";
          this.getresponse.type = "key";
          return false;
        } else if (this.getresponse.api_secret === "") {
          this.getresponse.msg = "Please enter api secret";
          this.getresponse.type = "secret";
          return false;
        } else {
          this.getresponse.msg = "";
          this.getresponse.type = "";
        }
      }

      if (name === "convertkit") {
        api_key = this.convertkit.api_key;
        api_secret = this.convertkit.api_secret;

        if (this.convertkit.api_key === "") {
          this.convertkit.msg = "Please enter api key";
          this.convertkit.type = "key";
          return false;
        } else if (this.convertkit.api_secret === "") {
          this.convertkit.msg = "Please enter api secret";
          this.convertkit.type = "secret";
          return false;
        } else {
          this.convertkit.msg = "";
          this.convertkit.type = "";
        }
      }

      if (name === "mailchimp") {
        api_key = this.mailchimp.api_key;
        api_secret = this.mailchimp.api_secret;

        if (this.mailchimp.api_key === "") {
          this.mailchimp.msg = "Please enter api key";
          this.mailchimp.type = "key";
          return false;
        } else if (this.mailchimp.api_secret === "") {
          this.mailchimp.msg = "Please enter api secret";
          this.mailchimp.type = "secret";
          return false;
        } else {
          this.mailchimp.msg = "";
          this.mailchimp.type = "";
        }
      }

      let post_data = {
        url: "/whitelabel/integration/store",
        data: {
          name: name,
          api_key: api_key,
          api_secret: api_secret,
        },
      };
      var $this = this;

      this.$store
        .dispatch("post", { ...post_data })
        .then((response) => {
          $this.$message({
            showClose: true,
            message: "Detail successfully saved",
            type: "success",
          });
        })
        .catch((error) => {});
    },
    testConnection() {
      console.log(this.email_detail, "-*-*-*");

      if (
        this.email_detail.from_name !== null &&
        this.email_detail.from_name !== "" &&
        this.email_detail.from_email !== null &&
        this.email_detail.from_email !== "" &&
        this.email_detail.username !== null &&
        this.email_detail.username !== "" &&
        this.email_detail.password !== null &&
        this.email_detail.password !== "" &&
        this.email_detail.port_no !== null &&
        this.email_detail.port_no !== "" &&
        this.email_detail.host !== null &&
        this.email_detail.host !== ""
      ) {
        // alert('jhdgdfdhfg');
        this.testConn = true;
      }

      return this.testConn;
    },
    storeEmailDetail(type = "") {
      this.type = type;
    },

    testEmailConnection() {
      let post_data = {
        url: "/whitelabel/emails/test",
      };
      var $this = this;

      this.$store.dispatch("post", { ...post_data }).then((response) => {});
    },

    getEmailDetail() {
      let post_data = {
        url: "/whitelabel/emails/list",
      };
      var $this = this;

      this.$store
        .dispatch("post", { ...post_data })
        .then((response) => {
          this.email_detail.from_name = response.data.setting.from_name;
          this.email_detail.from_email = response.data.setting.from_email;
          this.email_detail.company_name = response.data.setting.company_name;
          this.email_detail.address = response.data.setting.address;
          this.email_detail.city = response.data.setting.city;
          this.email_detail.username = response.data.setting.username;
          this.email_detail.password = response.data.setting.password;
          this.email_detail.port_no = response.data.setting.port_no;
          this.email_detail.tls_status = response.data.setting.tls_status;
          this.email_detail.connection = response.data.setting.connection;
          this.email_detail.host = response.data.setting.host;

          // this.on_signup.subject = response.data.setting.signup_subject;
          // this.on_signup.body = response.data.setting.signup_body;

          // this.on_plan_change.subject =
          //   response.data.setting.change_plan_subject;
          // this.on_plan_change.body = response.data.setting.change_plan_body;

          // this.on_cancellation.subject =
          //   response.data.setting.cancellation_subject;
          // this.on_cancellation.body = response.data.setting.cancellation_body;

          // this.on_account_suspension.subject =
          //   response.data.setting.suspension_subject;
          // this.on_account_suspension.body =
          //   response.data.setting.suspension_body;

          // this.on_password_reset.subject =
          //   response.data.setting.pass_reset_subject;
          // this.on_password_reset.body = response.data.setting.pass_reset_body;

          console.log(response.data, "=========");
        })
        .catch((error) => {});
    },
    storeEmail(name) {
      var subject = "";
      var body = "";

      // if (name === "on_signup") {
      //   subject = this.on_signup.subject;
      //   body = this.on_signup.body;

      //   if (this.on_signup.subject === "") {
      //     this.on_signup.msg = "Please enter subject";
      //     this.on_signup.type = "key";
      //     return false;
      //   } else if (this.on_signup.body === "") {
      //     this.on_signup.msg = "Please enter body";
      //     this.on_signup.type = "secret";
      //     return false;
      //   } else {
      //     this.on_signup.msg = "";
      //     this.on_signup.type = "";
      //   }
      // }

      // if (name === "on_plan_change") {
      //   subject = this.on_plan_change.subject;
      //   body = this.on_plan_change.body;

      //   if (this.on_plan_change.subject === "") {
      //     this.on_plan_change.msg = "Please enter subject";
      //     this.on_plan_change.type = "key";
      //     return false;
      //   } else if (this.on_plan_change.body === "") {
      //     this.on_plan_change.msg = "Please enter body";
      //     this.on_plan_change.type = "secret";
      //     return false;
      //   } else {
      //     this.on_plan_change.msg = "";
      //     this.on_plan_change.type = "";
      //   }
      // }

      // if (name === "on_cancellation") {
      //   subject = this.on_cancellation.subject;
      //   body = this.on_cancellation.body;

      //   if (this.on_cancellation.subject === "") {
      //     this.on_cancellation.msg = "Please enter subject";
      //     this.on_cancellation.type = "key";
      //     return false;
      //   } else if (this.on_cancellation.body === "") {
      //     this.on_cancellation.msg = "Please enter body";
      //     this.on_cancellation.type = "secret";
      //     return false;
      //   } else {
      //     this.on_cancellation.msg = "";
      //     this.on_cancellation.type = "";
      //   }
      // }

      // if (name === "on_account_suspension") {
      //   subject = this.on_account_suspension.subject;
      //   body = this.on_account_suspension.body;

      //   if (this.on_account_suspension.subject === "") {
      //     this.on_account_suspension.msg = "Please enter subject";
      //     this.on_account_suspension.type = "key";
      //     return false;
      //   } else if (this.on_account_suspension.body === "") {
      //     this.on_account_suspension.msg = "Please enter body";
      //     this.on_account_suspension.type = "secret";
      //     return false;
      //   } else {
      //     this.on_account_suspension.msg = "";
      //     this.on_account_suspension.type = "";
      //   }
      // }

      // if (name === "on_password_reset") {
      //   subject = this.on_password_reset.subject;
      //   body = this.on_password_reset.body;

      //   if (this.on_password_reset.subject === "") {
      //     this.on_password_reset.msg = "Please enter subject";
      //     this.on_password_reset.type = "key";
      //     return false;
      //   } else if (this.on_password_reset.body === "") {
      //     this.on_password_reset.msg = "Please enter body";
      //     this.on_password_reset.type = "secret";
      //     return false;
      //   } else {
      //     this.on_password_reset.msg = "";
      //     this.on_password_reset.type = "";
      //   }
      // }

      let post_data = {
        url: "/whitelabel/emails/store",
        data: {
          // on_signup: this.on_signup,
          // on_plan_change: this.on_plan_change,
          // on_cancellation: this.on_cancellation,
          // on_account_suspension: this.on_account_suspension,
          // on_password_reset: this.on_password_reset,
          email_detail: this.email_detail,
        },
      };
      var $this = this;

      this.$store
        .dispatch("post", { ...post_data })
        .then((response) => {
          $this.$message({
            showClose: true,
            message: "Detail successfully saved",
            type: "success",
          });
        })
        .catch((error) => {});
    },
  },
};
</script>

<style lang="less" scopped>
.smtp {
  form {
    padding: 20px;
    background: #f9fbfb;
    > div {
      max-width: 1050px;
    }
  }
  .email-body-header {
    height: 70px;
    background: gray;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  .mb-0 {
    margin-bottom: 0;
  }
  .el-textarea {
    textarea {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
  .d-flexx {
    .el-form-item__content {
      display: flex;
    }
    .el-form-item__label {
      min-width: 180px;
      text-align: left;
    }
  }
  .signup-box {
    background: #ebf9fa;
    padding: 15px 35px;
    border-radius: 6px;
    .el-form-item__label {
      text-transform: uppercase;
    }
  }
  h3 {
    font-size: 20px;
    font-weight: bold;
    margin: 20px 25px 20px 0;
    // color: #0c4077;
  }
  p.head-title {
    font-size: 16px;
    font-weight: bold;
    margin: 20px 25px 20px 0;
    color: #0c4077;
    &.tag {
      margin: 40px 25px 40px 0;
    }
    span {
      font-weight: normal;
    }
  }
  .integration-desc {
    margin-top: 20px;
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      max-width: 1200px;
      li {
        min-height: 55px;
        width: 100%;
        display: flex;
        align-items: center;
        background: #05308614;
        border-radius: 6px;
        color: #282828;
        padding: 0 30px;
        font-size: 16px;
        font-weight: 500;
        cursor: pointer;
      }
    }
    &.is-active {
      li {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
  .el-button--primary {
    color: #fff;
    background-color: #0a44b1;
    border-color: #0a44b1;
  }
  .el-button--info {
    color: #fff;
    background-color: #a2adad;
    border-color: #a2adad;
  }
  .aweber-info-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;
  }
  // .email-head {
  //   align-items: center;
  //   width: 100%;
  // }
  .el-button--primary.is-plain {
    background: #ebf9fa;
    border-color: #33acdb;
    border-radius: 40px;
    color: #406e97;
    height: 45px;
  }
  .expand.field {
    input {
      max-width: 400px;
    }
  }
  .aweber-info {
    padding: 30px 25px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    max-width: 1200px;
    border-top: none;
    border: 2px solid #ebeef5;

    p {
      font-size: 18px;
      font-weight: bold;
      color: #0c4077;
    }
  }
}

.mb-5 {
  margin-bottom: 5px !important;
}
.mt-4 {
  margin-top: 4px !important;
}
.mt-5 {
  margin-top: 30px;
}
.min65 {
  min-width: 150px !important;
}
.plan-tags {
  max-width: 700px;
}
.key-label {
  font-weight: 400;
  color: #8a909d;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 5px;
  display: block;
}
input.expand.field {
  background-color: #ebf0f88c !important;
  border: none !important;
  border-radius: 7px !important;
  height: 40px;
  padding: 0 10px;
  min-width: 100%;
  max-width: 100% !important;
}
.pr-2 {
  padding-right: 20px;
}
.btn-primary {
  color: #053083 !important;
  background: transparent !important;
  border: 1px solid #053083 !important;
}
.btn-primary-gray {
  color: #a2adad !important;
  background: transparent !important;
  border: 1px solid #a2adad !important;
  border-radius: 30px;
}
</style>
