<template>
  <div class="card-container setting-user-crm">
     <h2 class="whitelable-page-heading">Users/CRM Sync</span>
                </h2>
        <div class="user-header">
            <div class="right-side">
                <div class="email-head d-flex">
          <!-- <h3 class="head-title mr-3 text-white"> -->
            <!-- {{ activeCrm.name }} -->
            <!-- <span class="text-white">(hello@4kplayer.com)</span> -->
          <!-- </h3> -->
          <!-- <el-button type="primary" plain>Change CRM</el-button> -->

          <el-dropdown
            @command="uploadAudioVideoCommand"
            trigger="click"
            class="action__dropdown project-controls"
            size="medium"
            placement="bottom"
            v-show="uploadButtonVisible"
          >
            <el-button type="primary" plain>
              {{ activeCrm.name }} <i class="el-submenu__icon-arrow el-icon-arrow-down"></i>
            </el-button>
            <span class="el-dropdown-link"></span>
            <el-dropdown-menu
              style="
                border: 1px solid var(--color-blue);
                border-radius: 10px;
                padding: 10px;
              "
              slot="dropdown"
              class="slate-dropdown custom_dropdown dropdown_bottom_200"
            >
              <el-dropdown-item
                class=""
                :command="crm.name"
                v-for="crm in crms"
                v-bind:key="crm.id"
              >
                {{ crm.name }}
              </el-dropdown-item>

              <!--                                    <el-dropdown-item class="" command="recordCamera">Record Camera-->
              <!--                                    </el-dropdown-item>-->
            </el-dropdown-menu>
          </el-dropdown>
        </div>
              <div>
                  <button
                    class="el-button el-button--primary is-plain el-dropdown-selfdefine"
                    @click="newIntegration"
                  >
                    Connect New CRM
                  </button>
                </div>
            </div>
        </div>
   

    <div v-if="mailers.connected.length > 0">
      <div>
        

        <p class="head-title mr-3">On Sign Up</p>

        <el-row>
          <el-col :span="12">
            <label class=" key-label">Add/Update to list</label>
            
            <el-select
              v-model="setting.signup_list"
              placeholder="Activity zone"
            >
              <el-option-group
                v-if="
                  group !== null &&
                  group.mailer === 'mailchimp' &&
                  activeCrm.name === 'Mailchimp'
                "
                v-for="group in email_providers"
                :key="group.mailer"
                :label="getNicename(group.mailer)"
              >
                <el-option
                  v-for="item in group.lists"
                  :key="item.value"
                  :label="item.name"
                  :value="group.mailer + '_' + item.id"
                >
                </el-option>
              </el-option-group>
              <el-option-group
                v-if="
                  group !== null &&
                  group.mailer === 'aweber' &&
                  activeCrm.name === 'Aweber'
                "
                v-for="group in email_providers"
                :key="group.mailer"
                :label="getNicename(group.mailer)"
              >
                <el-option
                  v-for="item in group.lists"
                  :key="item.value"
                  :label="item.name"
                  :value="group.mailer + '_' + item.id"
                >
                </el-option>
              </el-option-group>
            </el-select>
          </el-col>
          <label class=" key-label">Add Tag</label>
          <el-col :span="12">
            <!-- <el-form-item label="ADD TAG" class="mb-0"> </el-form-item> -->

            <el-select
              v-model="setting.signup_tags"
              multiple
              filterable
              allow-create
              default-first-option
              placeholder="Add tags"
            >
              <!-- <el-option-group v-if="group !== null" v-for="group in email_providers"
                                                 :key="group.mailer"
                                                 :label="getNicename(group.mailer)">
                                    <el-option v-for="item in group.lists" :key="item.value" :label="item.name"
                                               :value="group.mailer + '_' + item.id">
                                    </el-option>
                                </el-option-group> -->
            </el-select>
          </el-col>
        </el-row>

        <div class="plan-tags">
          <label class=" key-label">If Plan tags</label>

          <div class="d-flex mt-4">
            <label class=" key-label">Free</label>
            <!-- <el-form-item label="Free:"> </el-form-item> -->
            <el-input></el-input>
          </div>

          <div class="d-flex mt-4">
            <label class=" key-label">Starter</label>
            <!-- <el-form-item label="Starter:"> </el-form-item> -->
            <el-input></el-input>
          </div>
          <div class="d-flex mt-4">
            <label class=" key-label">Pro</label>
            <!-- <el-form-item label="Pro:"> </el-form-item> -->
            <el-input></el-input>
          </div>
        </div>

        <p class="head-title mr-3">On Cancellation</p>
        <el-row>
          <el-col :span="12">
            <label class=" key-label">Add/Update to list</label>
            <!-- <el-form-item label="ADD/UPADATE TO LIST" class="mb-0">
            </el-form-item> -->
            <el-select
              v-model="setting.cancelation_list"
              placeholder="Activity zone"
            >
              <el-option-group
                v-if="
                  group !== null &&
                  group.mailer === 'mailchimp' &&
                  activeCrm.name === 'Mailchimp'
                "
                v-for="group in email_providers"
                :key="group.mailer"
                :label="getNicename(group.mailer)"
              >
                <el-option
                  v-for="item in group.lists"
                  :key="item.value"
                  :label="item.name"
                  :value="group.mailer + '_' + item.id"
                >
                </el-option>
              </el-option-group>
              <el-option-group
                v-if="
                  group !== null &&
                  group.mailer === 'aweber' &&
                  activeCrm.name === 'Aweber'
                "
                v-for="group in email_providers"
                :key="group.mailer"
                :label="getNicename(group.mailer)"
              >
                <el-option
                  v-for="item in group.lists"
                  :key="item.value"
                  :label="item.name"
                  :value="group.mailer + '_' + item.id"
                >
                </el-option>
              </el-option-group>
            </el-select>
          </el-col>
          <label class=" key-label">Add Tag</label>
          <el-col :span="12">
            <!-- <el-form-item label="ADD TAG" class="mb-0"> </el-form-item> -->

            <el-select
              v-model="setting.cancelation_tags"
              multiple
              filterable
              allow-create
              default-first-option
              placeholder="Choose tags for your article"
            >
              <!-- <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option> -->
            </el-select>
          </el-col>
        </el-row>

        <p class="head-title mr-3">On Ban/Suspension</p>
        <el-row>
          <el-col :span="12">
            <label class=" key-label">Add/Update to list</label>
            <!-- <el-form-item label="ADD/UPADATE TO LIST" class="mb-0">
            </el-form-item> -->
            <el-select
              v-model="setting.suspension_list"
              placeholder="Activity zone"
            >
              <el-option-group
                v-if="
                  group !== null &&
                  group.mailer === 'mailchimp' &&
                  activeCrm.name === 'Mailchimp'
                "
                v-for="group in email_providers"
                :key="group.mailer"
                :label="getNicename(group.mailer)"
              >
                <el-option
                  v-for="item in group.lists"
                  :key="item.value"
                  :label="item.name"
                  :value="group.mailer + '_' + item.id"
                >
                </el-option>
              </el-option-group>
              <el-option-group
                v-if="
                  group !== null &&
                  group.mailer === 'aweber' &&
                  activeCrm.name === 'Aweber'
                "
                v-for="group in email_providers"
                :key="group.mailer"
                :label="getNicename(group.mailer)"
              >
                <el-option
                  v-for="item in group.lists"
                  :key="item.value"
                  :label="item.name"
                  :value="group.mailer + '_' + item.id"
                >
                </el-option>
              </el-option-group>
            </el-select>
          </el-col>
          <label class=" key-label">Add Tag</label>
          <el-col :span="12">
            <!-- <el-form-item label="ADD TAG" class="mb-0"> </el-form-item> -->

            <el-select
              v-model="setting.suspension_tags"
              multiple
              filterable
              allow-create
              default-first-option
              placeholder="Choose tags for your article"
            >
              <!-- <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option> -->
            </el-select>
          </el-col>
        </el-row>

        <p class="head-title mr-3">On Delete</p>
        <el-row>
          <el-col :span="12">
            <label class=" key-label">Add/Update to list</label>
            <!-- <el-form-item label="ADD/UPADATE TO LIST" class="mb-0">
            </el-form-item> -->
            <el-select
              v-model="setting.delete_list"
              placeholder="Activity zone"
            >
              <el-option-group
                v-if="
                  group !== null &&
                  group.mailer === 'mailchimp' &&
                  activeCrm.name === 'Mailchimp'
                "
                v-for="group in email_providers"
                :key="group.mailer"
                :label="getNicename(group.mailer)"
              >
                <el-option
                  v-for="item in group.lists"
                  :key="item.value"
                  :label="item.name"
                  :value="group.mailer + '_' + item.id"
                >
                </el-option>
              </el-option-group>
              <el-option-group
                v-if="
                  group !== null &&
                  group.mailer === 'aweber' &&
                  activeCrm.name === 'Aweber'
                "
                v-for="group in email_providers"
                :key="group.mailer"
                :label="getNicename(group.mailer)"
              >
                <el-option
                  v-for="item in group.lists"
                  :key="item.value"
                  :label="item.name"
                  :value="group.mailer + '_' + item.id"
                >
                </el-option>
              </el-option-group>
            </el-select>
          </el-col>
          <label class=" key-label">Add Tag</label>
          <el-col :span="12">
            <!-- <el-form-item label="ADD TAG" class="mb-0"> </el-form-item> -->

            <el-select
              v-model="setting.delete_tags"
              multiple
              filterable
              allow-create
              default-first-option
              placeholder="Choose tags for your article"
            >
              <!-- <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option> -->
            </el-select>
          </el-col>
        </el-row>
      </div>
      <div class="mt-5 text-right mb-5">
        <button
          type="button"
          class="btn btn-primary btn-primary-outline"
          @click="storeCRM"
        >
          Save
        </button>
      </div>
    </div>
    <div class="settings-crm-integration" id="settings-integration">
      <new-integration
        :integration-dialog="integrationDialog"
        :user="user"
      ></new-integration>
    </div>
  </div>
</template>


<script>
import NewIntegration from "./../../4kplayer/settings/NewIntegration";
export default {
  name: "WhiteBoardSettingsUserCrm",
  components: {
    NewIntegration,
  },
  data() {
    return {
         mailers: {
                connected: [],
                not_connected: [],
                email: {connected: [], not_connected: []},
                webinar: {connected: [], not_connected: []},
                crm: {connected: [], not_connected: []},
                social_media: {connected: [], not_connected: []},
                other: {connected: [], not_connected: []}
            },
      integrationDialog: {
        show: false,
        step: 1,
        active: "all",
      },
      user: null,

      ruleForm: {
        name: "",
        desc: "",
      },
      // options: [
      //   {
      //     value: "HTML",
      //     label: "HTML",
      //   },
      //   {
      //     value: "CSS",
      //     label: "CSS",
      //   },
      //   {
      //     value: "JavaScript",
      //     label: "JavaScript",
      //   },
      // ],
      value: [],
      crms: null,
      uploadButtonVisible: true,
      email_providers: [],
      activeCrm: {
        name: "",
        id: null,
      },
      setting: {
        signup_tags: [],
        signup_list: "",
        cancelation_tags: [],
        cancelation_list: "",
        suspension_tags: [],
        suspension_list: "",
        delete_tags: [],
        delete_list: "",
      },
    };
  },
  created() {
    this.getCrm();
    this.getList();
  },
     beforeDestroy() {
            window.vEvent.stop("load-integration-list", this.load);
            window.vEvent.stop("load-integration-refresh", this.refreshLists);
        },
        mounted() {
            this.load();
            window.vEvent.listen("load-integration-list", this.load);
            window.vEvent.listen("load-integration-refresh", this.refreshLists);
            this.refreshLists('');
        },
  methods: {
     load() {
                this.axios({
                    url: "/integrations"
                })
                .then(response => {
                    this.mailers = response.data;
                })
                .catch(error => {
                    this.$message.error(error.message);
                });
            },
              refreshLists(service_key) {
                // Save Mailing Lists
                let post_data = {
                    url: `/integrations/refresh`,
                    data: {}
                };

                if (service_key !== '') {
                    post_data.data['service_key'] = service_key;
                }

                this.$store.dispatch('post', {...post_data}).then(response => {
//                    console.log(response.data);
                });
            },
    newIntegration() {
      this.integrationDialog.step = 1;
      this.integrationDialog.show = true;
    },
    getNicename(name) {
      // if (this.nicenames[name] !== undefined) {
      //     return this.nicenames[name];
      // }
      return name;
    },
    getList() {
      var $this = this;
      this.axios({
        url: "integrations/lists",
        method: "GET",
      })
        .then(function (response) {
          $this.email_providers = $this.email_providers.concat(response.data);
        })
        .catch(function (response) {
          $this.$message({
            showClose: true,
            message:
              "An error occurred while retrieving email providers: " +
              response.message,
            type: "error",
          });
        });
    },
    getCrm() {
      this.axios({
        method: "post",
        url: `whitelabel/crm`,
        data: {
          domain: this.domain,
        },
      })
        .then((response) => {
          this.crms = response.data.crms;
         if(response.data.crms.length > 0 && this.activeCrm.name === ''){

if(response.data.active !== null){
this.activeCrm.name = response.data.active.name
            this.activeCrm.id = response.data.active.id
}else{
  this.activeCrm.name = response.data.crms[0].name
            this.activeCrm.id = response.data.crms[0].id
}

            
         }
          console.log(this.crms);
          this.getCrmSetting();
        })
        ["catch"]((error) => {});
    },
    uploadAudioVideoCommand(command = null) {
      this.activeCrm.name = command;
      this.activeCrm.id = null;
      var $this = this;
      this.crms.forEach(function (ele, index) {
        if (ele.name === $this.activeCrm.name) {
          $this.activeCrm.id = ele.id;
        }
      });

      this.getCrmSetting();
      console.log(command, "command");
    },
    storeCRM() {
      this.axios({
        method: "post",
        url: `whitelabel/crm/store/` + this.activeCrm.id,
        data: {
          setting: this.setting,
          activeCrmId: this.activeCrm.id,
        },
      })
        .then((response) => {
          console.log(response);
        })
        ["catch"]((error) => {});
    },
    getCrmSetting() {
      this.axios({
        method: "post",
        url: `whitelabel/crm/list/` + this.activeCrm.id,
      })
        .then((response) => {
          if (response.data.setting !== null) {
            this.setting = response.data.setting;

            var str = response.data.setting.signup_tags;
            str = str.replace(/"/g, "");
            var result = str.substring(1, str.length - 1);
            this.setting.signup_tags = result.split(",");

            var str = response.data.setting.cancelation_tags;
            str = str.replace(/"/g, "");
            var result = str.substring(1, str.length - 1);
            this.setting.cancelation_tags = result.split(",");

            var str = response.data.setting.suspension_tags;
            str = str.replace(/"/g, "");
            var result = str.substring(1, str.length - 1);
            this.setting.suspension_tags = result.split(",");

            var str = response.data.setting.delete_tags;
            str = str.replace(/"/g, "");
            var result = str.substring(1, str.length - 1);
            this.setting.delete_tags = result.split(",");
          } else {
            this.setting = {
              signup_tags: [],
              cancelation_tags: [],
              suspension_tags: [],
              delete_tags: [],
            };
          }
        })
        ["catch"]((error) => {});
    },
  },
};
</script>

<style lang="less" scoped>
//.el-input__icon{
//  width:50px;
//  font-size: 30px;
//  font-weight: 100;
//}
.setting-user-crm {
  // left: 150px;
  //position: absolute;
  //width: 100%;
  //height: calc(100% - 81px) !important;
  // top: 55px;
  padding: 30px;
  form {
    padding: 20px;
    background: #f9fbfb;

    > div {
      max-width: 1024px;
      margin-bottom: 50px;
    }
    .el-select {
      min-width: 400px;
    }
    .el-input {
      max-width: 400px;
    }
  }
  .mb-0 {
    margin-bottom: 0;
  }
  .email-head {
    align-items: center;
    width: 100%;
  }
  .email-setting form > div {
    max-width: 1050px;
  }
  .el-button--primary.is-plain {
    background: #ebf9fa;
    border-color: #33acdb;
    border-radius: 40px;
    color: #406e97;
    height: 45px;
    min-width: 142px;
    .el-submenu__icon-arrow{

    }
  }
  .plan-tags {
    max-width: 700px;
    margin: 30px auto 0;
  }
  .d-flex {
    .el-form-item {
      min-width: 80px;
    }
  }
  h3 {
    font-size: 20px;
    font-weight: bold;
    margin: 20px 25px 20px 0;
    color: #0c4077;
  }
  p.head-title {
        font-size: 16px;
    font-weight: bold;
      margin: 30px 25px 10px 0;
    color: #0c4077;
  }
  .el-select{
    background-color: #ebf0f88c !important;
    border: none !important;
    border-radius: 7px !important;
    height: 40px;
    padding: 0 10px;
    min-width: 100%;
    max-width: 100% !important;
    .el-input__inner{
          cursor: pointer;
          padding: 0;
          border: none !important;
          background: transparent !important;
    }
  }
}
.text-white {
  color: var(--textThemeColor) !important;
}

.min65 {
  min-width: 65px !important;
}
.el-select {
  width: 75%;
}

.mb-5 {
  margin-bottom: 5px !important;
}

</style>
<style lang='less'>
.settings-crm-integration {
  .el-dialog--center .el-dialog__header {
    text-align: center !important;
  }
  .new-integration-dialog .el-dialog__header {
    font-size: 15px;
    font-weight: 500;
}
.el-dialog__header {
    padding: 20px 20px 0;
}
.el-dialog__body {
    padding: 30px 40px!important;
}
  //NEMESIS ADD START
    div.integration-step-1 {
        background-color: #FAFEFF;
        height: 44px;
        line-height: 44px;
        font-size: 13px;
        color: var(--color-content);
        font-weight: 500 !important;
        text-align: left;
    }
    //NEMESIS ADD END
    .integration-step-2 {
        .integration-description {
            img {
                width: 80px;
                height: 60px;
            }
        }
    }

.el-menu {
    border-right: 1px solid #e6e6e6;
    list-style: none;
    position: relative;
    margin: 0;
    padding-left: 0;
    background-color: #fff;
}
.m-right-2 {
    margin-right: 10px!important;
}
.img-area{
  img{
    height: 25px;
  }
}
.search-group img {
    position: absolute;
    left: 15px;
    top: 13px;
}
  .new-integration-table {
        &.el-table {
            .el-table__header-wrapper {
                thead {
                    div {
                        background: transparent;
                        text-align: center;
                    }

                    th {
                        padding: 0;
                        border-right: 0;
                        border-bottom: 0;
                        font-weight: normal;
                        background: transparent;
                    }

                    .el-input__inner {
                        font-weight: normal;
                        position: fixed;
                        top: 102px;
                        right: 44px;
                        width: 65%;
                        height: 35px;
                        border-radius: 20px;
                        padding-left: 18px;
                        padding-right: 18px;
                    }
                }
            }

            tbody {
                td {
                    border-right: 0;

                    .integration-name {
                        height: 110px;
                        line-height: 110px;
                        display: flex;

                        .img-area {
                            width: 90px;       //NEMESIS CHANGE width: 100px;

                            img {
                                height: 40px;   //NEMESIS CHANGE height: 60px;
                                width: 60px;    //NEMESIS CHANGE width: 80px;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
