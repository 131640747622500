<template>
    <div class='confirm-container'>
        <div class='confirm-content'>
            <div class='confirm-header text-center'>
                <b>{{ head }}</b>
            </div>
            <div class='confirm-body text-center'>
                <div class='confirm-body-text'>{{ body }}</div>
                <div v-if='confirmInput' class="confirm-input-container">
                    <div class='confirm-text'>Type <b>{{ confirmInput }}</b> to process this request.</div>
                    <el-input
                        v-model="confirmText"
                    ></el-input>
                    <div class="confirm-text" v-if='showConfirmError'>
                        You must type <b>{{ confirmInput }}</b> to confirm the action.
                    </div>
                </div>
            </div>
            <div class='confirm-footer text-center'>
                <el-button
                    class='footer-button cancel-button'
                    @click='trigger("cancel")'>
                    Cancel
                </el-button>
                <el-button
                    class='footer-button confirm-button'
                    @click='trigger("confirm")'>
                    Confirm
                </el-button>
            </div>
        </div>
    </div>
</template>

<style lang="less" scoped>
    .confirm-container {
        font-family: "Helvetica Neue";
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        margin: auto;
        background-color: rgba(1, 26, 34, 0.3);
        transition: 1s;
        backdrop-filter: blur(2px);
        .confirm-content {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            height: 280px;
            width: max-content;
            background: #FFFFFF;
            border: 1px solid #0BACDB;
            border-radius: 12px;
            .confirm-header {
                padding: 15px 0;
                border-bottom: 1px solid #EBF9FA;
                text-transform: uppercase;
            }
            .confirm-body {
                border-bottom: 1px solid #EBF9FA;
                .confirm-body-text {
                    padding: 30px;
                    font-size: 2rem;
                }
                .confirm-input-container {
                    padding: 0 30px;
                    .confirm-text {
                        color: #ee5951;
                        text-align: left;
                    }
                }
            }
            .confirm-footer {
                padding: 15px 0;
                .footer-button {
                    border-radius: 20px;
                    color: #fff;
                    outline: none;
                    height: 36px;
                }
                .confirm-button {
                    background: #0BACDB;
                }
                .cancel-button {
                    background: #707070;
                }
            }
        }
    }
</style>

<script>
export default {
    name: 'BaseConfirm',
    props: ['head', 'body', 'confirmInput'],
    data: () => ({
        confirmText: '',
        showConfirmError: false,
    }),
    methods: {
        trigger(type) {
            if (type === 'confirm') {
                const confirmInput = this.$props.confirmInput;
                console.log({confirmInput, confirmText: this.confirmText});
                if (confirmInput && confirmInput != '') {
                    if (this.confirmText === confirmInput) {
                        this.showConfirmError = false;
                        this.$emit(type);
                    } else {
                        this.showConfirmError = true;
                    }
                } else {
                    this.showConfirmError = false;
                    this.$emit(type);
                }
            } else {
                this.showConfirmError = false;
                this.$emit(type);
            }
        }
    }
}
</script>