<template>
    <div class="info-holder">

    <h1>Apps you own</h1>
    <img alt="4KPlayer" class="logo" src="../../assets/4kplayerlogo.svg" />
    <div class="details">
        <p v-if="user && !user.is_first_login"><router-link :to="{name : 'WhiteBoardDashBoard'}">Click here</router-link> to access your instance</p>
        <p v-else><router-link :user="user" :to="{name : 'FirstAccess.branding'}">Click here</router-link> to access your instance</p>
        <p>Software sendbox demo {{ '</>' }}</p>
    </div>
    </div>
</template>

<script>
    export default {
        name: "FirstAccessInfo",
        props : {
            user:{
                type: Object,
                   default: function () {
                    return {};
                },
            }
            },
        created (){
            
        }
    };
</script>

<style lang="less" scoped>
.info-holder{
    text-align: center;
    .details{
        margin-top: 40px;
    }
    .logo{
    height: 80px;
}
}

</style>