const getters = {
	
	/**
	 * Return authenticated user subscription data.
	 *
	 * @param state
	 * @returns {*}
	 */
	subscriptionData: (state) => {
		return state.subscription;
	},

	/**
	 * Return authenticated user subscription data.
	 *
	 * @param state
	 * @returns {*}
	 */
	subscriptionPlans: (state) => {
		return state.subscriptionPlans;
	},

	/**
	 * Return authenticated user billing status data.
	 *
	 * @param state
	 * @returns {*}
	 */
	billingStatus: (state) => {
		return state.billingStatus;
	},

	/**
	 * Return authenticated user usage data.
	 *
	 * @param state
	 * @returns {*}
	 */
	usageData: (state) => {
		return state.usageData;
	},

	/**
	 * Return authenticated user billing estimate data.
	 *
	 * @param state
	 * @returns {*}
	 */
	billingEstimate: (state) => {
		return state.billingEstimate;
	},

	/**
	 * Return authenticated user billing Information data.
	 *
	 * @param state
	 * @returns {*}
	 */
	billingInformation: (state) => {
		return state.billingInformation;
	},

	/**
	 * Return authenticated user billing Information data.
	 *
	 * @param state
	 * @returns {*}
	 */
	billingInvoices: (state) => {
		return state.billingInvoices;
	},

	/**
	 * Return authenticated user invoiceDetails data.
	 *
	 * @param state
	 * @returns {*}
	 */
	invoiceDetails: (state) => {
		return state.invoiceDetails;
	},

	/**
	 * Return isMobile check boolean.
	 *
	 * @param state
	 * @returns {*}
	 */
	isMobile: (state) => {
		return state.isMobile;
	},
};

export default getters;
