import axios from "axios";
import auth_actions from '../auth/actions';
import settings_actions from '../settings/actions';
import admin_actions from '../admin/actions';
import projects_actions from '../projects/actions';
import plan_actions from '../plans/actions';
import collaboration_actions from '../collaborations/actions';

const actions = Object.assign(
    auth_actions,
    settings_actions,
    admin_actions,
    projects_actions,
    plan_actions,
    collaboration_actions,
    {
        post({commit}, post_data) {
            return new Promise((resolve, reject) => {
                axios.post(post_data.url, post_data.data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
            });
        },
        
        getData({commit}, params) {
            return new Promise((resolve, reject) => {
                axios.get(params.url, params.config)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
            });
        },
        
        putData({commit}, put_data) {
            return new Promise((resolve, reject) => {
                axios.put(put_data.url, put_data.data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
            });
        },
        
        delete({commit}, url) {
            return new Promise((resolve, reject) => {
                axios.delete(url)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
            });
        }
    }
);

export default actions;
