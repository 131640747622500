import store from "@/store/store";
const ViededingueSignUp = () => import('@/components/Auth/ViededingueSignup');


const Login = () =>
  import(/* webpackChunkName: "login" */ "@/components/Auth/Login.vue");
const Logout = () =>
  import(/* webpackChunkName: "logout" */ "@/components/Auth/Logout.vue");
const Register = () =>
  import(/* webpackChunkName: "register" */ "@/components/Auth/Register.vue");
const Subscription = () =>
  import(
    /* webpackChunkName: "subscription" */ "@/components/Auth/SubscriptionV2.vue"
  );
const SubscriptionNewFlow = () =>
  import(
    /* webpackChunkName: "SubscriptionNewFlow" */ "@/components/Auth/SubscriptionNewFlow.vue"
  );
const Suspended = () =>
  import(/* webpackChunkName: "suspended" */ "@/components/Auth/Suspended.vue");
const ForgotPassword = () =>
  import(
    /* webpackChunkName: "forgotpassword" */ "@/components/Auth/ForgotPassword.vue"
  );
// const PaymentHold = () => import("@/components/PaymentHold");
const ResetPassword = () =>
  import(
    /* webpackChunkName: "resetpassword" */ "@/components/Auth/ResetPassword.vue"
  );
const AccountCancelled = () =>
  import(
    /* webpackChunkName: "resetpassword" */ "@/components/Auth/AccountCancelled.vue"
  );
const CompleteSetup = () =>
  import(
    /* webpackChunkName: "resetpassword" */ "@/components/Auth/CompleteSetup.vue"
  );
  const AutoLogin = () =>
  import(/* webpackChunkName: "login" */ "@/components/Auth/AutoLogin.vue");

const SubscriptionCheckout = () =>
  import(
    /* webpackChunkName: "subscription" */ "@/components/Auth/SubscriptionCheckout.vue"
  );

let loginBeforeEnter = function (next) {
  if (store.getters.isAuthenticated) {
    console.log(store.getters["auth/userInfo"]);
    if (store.getters.userInfo.user_status === 1) {
      if (
        store.getters.userInfo.billing_status &&
        store.getters["auth/userInfo"] &&
        store.getters["auth/userInfo"].billing_status !== null
      ) {
        if (
          store.getters.userInfo.billing_status !== "Cancelled" &&
          store.getters.userInfo.billing_status !== "Failed" &&
          store.getters.userInfo.billing_status !== "Inactive" &&
          store.getters.userInfo.billing_status !== "Expired"
        ) {
          return next({
            name: "Dashboard",
          });
        }
      }
    }
  }

  return next();
};

const auth_routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      title: "Login",
      auth: false,
    },
    beforeEnter: (to, from, next) => {
      return loginBeforeEnter(next);
    },
  },
  {
    path: "/login/:step",
    name: "Login1",
    component: Login,
    meta: {
      title: "Login",
      auth: false,
    },
    beforeEnter: (to, from, next) => {
      return loginBeforeEnter(next);
    },
  },
  {
    path: "/logout",
    name: "Logout",
    component: Logout,
    meta: {
      title: "Logout",
      auth: false,
    },
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
    meta: {
      title: "Sign up",
      auth: false,
    },
  },
  {
    path: "/suspended",
    name: "Suspended",
    component: Suspended,
    meta: {
      title: "Plan Setup",
      auth: true,
    },
  },

  {
    path: "/subscription/:id?",
    name: "Subscription",
    component: Subscription,
    meta: {
      title: "Plan Setup",
      auth: true,
    },
  },
  {
    path: "/subscribe-plan/:id?/:plan?",
    name: "SubscriptionNewFlow",
    component: SubscriptionNewFlow,
    meta: {
      title: "Plan Setup",
      auth: false,
    },
  },
  // {
  //   path: "/payment_hold",
  //   name: "Payment Hold",
  //   component: PaymentHold,
  //   meta: {
  //     title: "Payment Hold",
  //     auth: true,
  //   },
  // },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
    meta: {
      title: "Send Email",
      auth: false,
    },
  },
  {
    path: "/reset-password/:token",
    name: "ResetPassword",
    component: ResetPassword,
    meta: {
      title: "Reset Password",
      auth: false,
    },
  },
  {
    path: "/account_cancelled/:id",
    name: "AccountCancelled",
    component: AccountCancelled,
    meta: {
      title: "AccountCancelled",
      auth: false,
    },
  },
  {
    path: "/complete_setup/:token",
    name: "CompleteSetup",
    component: CompleteSetup,
    meta: {
      title: "CompleteSetup",
      auth: false,
    },
  },
  {
    path: "/user_login/:id",
    name: "AutoLogin",
    component: AutoLogin,
    meta: {
      title: "AutoLogin",
      auth: false,
    },
  },
  {
    path: "/checkout",
    name: "SubscriptionCheckout",
    component: SubscriptionCheckout,
    meta: {
      title: "Plan Payment",
      auth: true,
    },
  },
  {
    path: '/viededingue',
    name: 'ViededingueSignUp',
    component: ViededingueSignUp,
    meta: {
        title: 'Sign Up',
        auth: false
    },
    beforeEnter: (to, from, next) => {
      if (to.name === 'ViededingueSignUp') {
        if (!store.getters.isAuthenticated) return next();
        else return next({ name: "Dashboard" });
      }
      next();
    },
},
];

export default auth_routes;
