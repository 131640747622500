import {
    isEmpty
} from 'lodash';

const presentInLocalStorage = function (...args) {
    let localStorageItems = JSON.parse(localStorage.getItem('vuex'));
    if (localStorageItems) {
        for (let i = 0; i < args.length; i++) {
            localStorageItems = localStorageItems[args[i]];
        }
        return Boolean(localStorageItems.length);
    }
    return Boolean(localStorageItems);
};

const buildBreadcrumbsPath = function (route, name) {
    if (route.path.includes('history')) {
        const breadcrumbs = [{
                id: 1,
                name: 'Active users',
                path: '/admin/active'
            },
            {
                id: 2,
                name: name,
                path: `/admin/users/${route.params.id}`
            },
            {
                id: 3,
                name: 'Billing History',
                path: ``
            }
        ];
        return breadcrumbs;
    }

    if (route.path.includes('receipt')) {
        const breadcrumbs = [{
                id: 1,
                name: 'Active users',
                path: '/admin/active'
            },
            {
                id: 2,
                name: name,
                path: `/admin/users/${route.params.id}`
            },
            {
                id: 3,
                name: 'Billing History',
                path: `/admin/active/${route.params.id}/user-info/billing-history`
            },
            {
                id: 4,
                name: `Receipt ${route.params.receiptId}`,
                path: ''
            }
        ];
        return breadcrumbs;
    }

    if (route.path.includes('account-users')) {
        const breadcrumbs = [{
                id: 1,
                name: 'Active Users',
                path: '/admin/active'
            },
            {
                id: 2,
                name: name,
                path: `/admin/users/${route.params.id}`
            },
            {
                id: 3,
                name: 'Account Users',
                path: ''
            }
        ];
        return breadcrumbs;
    }

    if (route.path.includes('account-info')) {
        const breadcrumbs = [{
                id: 1,
                name: 'Active users',
                path: '/admin/active'
            },
            {
                id: 2,
                name: name,
                path: `/admin/users/${route.params.id}`
            },
            {
                id: 3,
                name: 'Account Information',
                path: ''
            }
        ];
        return breadcrumbs;
    }
    if (route.path.includes('admin/users')) {
        const breadcrumbs = [{
                id: 1,
                name: 'Active users',
                path: '/admin/active'
            },
            {
                id: 2,
                name: name,
                path: ''
            }
        ];
        return breadcrumbs;
    }
    if (route.path.includes('admin/new_user')) {
        const breadcrumbs = [{
                id: 1,
                name: 'Users',
                path: '/admin/active'
            },
            {
                id: 2,
                name: name,
                path: ''
            }
        ];
        return breadcrumbs;
    }
    if (route.path.includes('admin/user')) {
        const breadcrumbs = [{
                id: 1,
                name: 'Users',
                path: '/admin/active'
            },
            {
                id: 2,
                name: name,
                path: ''
            }
        ];
        return breadcrumbs;
    }
};

export {
    presentInLocalStorage,
    buildBreadcrumbsPath,
};