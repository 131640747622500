import AppHelper from "../../../utils/AppHelper";

export default {
  name: "VideoUploadProgressing",
  props: {
    hash: String
  },
  data: function() {
    const progressingData = this.$store.getters['VideoUpload/getByHash'](this.hash);
    return {
      r: 90,
      progressingData: progressingData
    }
  },
  computed: {
    progress() {
      return this.progressingData.progress;
    },
    progressStrokeDashoffset() {
      const c = Math.PI*(this.r*2);
      return ((100 - this.progressingData.progress) / 100) * c;
    }
  },
  watch: {
  },
  mounted() {
  }
}
