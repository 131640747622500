import VideoUploadProgressing from '../components/Video/VideoUploadProgressing/VideoUploadProgressing.vue';
// import ProjectRoutes from './ChildRoutes/ProjectRoutes';

// const ProjectDefault = () =>
//     import(
//         /* webpackChunkName: "AllProjects" */ '@/components/Project/AllProjects/ProjectDefault/ProjectDefault.vue'
//     );
const ProjectDefault = () =>
    import(
        /* webpackChunkName: "AllProjects" */ '@/components/Project.vue'
    );
const PowerPlayerDefault = () =>
    import(
        '@/components/PowerPlayer/PowerPlayerDefault.vue'
    );
// const Dashboard = () =>
//     import(/* webpackChunkName: "dashboard" */ '@/components/Dashboard.vue');
const Project = () =>
    import(/* webpackChunkName: "project" */ '@/components/Project.vue');
// const ProjectView = () =>
//     import(
//         /* webpackChunkName: "projectView" */ '@/components/Project/ProjectView/ProjectView.vue'
//     );
const VideoEditor = () =>
    import(
        /* webpackChunkName: "videoEditor" */ '@/components/VideoEditor.vue'
    );
// const Stage = () =>
//     import(/* webpackChunkName: "stage" */ '@/components/Stage.vue');
// const StagePublic = () =>
//     import(
//         /* webpackChunkName: "stage-public" */ '@/components/StagePublic.vue'
//     );
// const Contacts = () =>
//     import(/* webpackChunkName: "contacts" */ '@/components/Contacts.vue');
const Analytics = () =>
    import(/* webpackChunkName: "analytics" */ '@/components/Analytics.vue');
const Settings = () =>
    import(/* webpackChunkName: "settings" */ '@/components/Settings.vue');
// const Admin = () => import('@/components/Admin.vue');
// const Users = () => import('@/components/Admin/UsersTab/Users');
// const Partners = () => import('@/components/Admin/Partners');
//
// const UserInfo = () => import('@/components/Admin/UsersTab/UserInfo/UserInfo');
// const PartnersInfo = () => import('@/components/Admin/Partners/PartnersInfo/PartnersInfo');
// const NewUser = () => import('@/components/Admin/UsersTab/NewUser/NewUser');
// const ChangeUserSubscriptionPlan = () => import('@/components/Admin/UsersTab/UserInfo/ChangeUserSubscriptionPlan');

const SubscriptionPlan = () =>
    import(
        /* webpackChunkName: "settings" */ '@/components/Settings/Items/Subscription/SubscriptionPlan.vue'
    );
const ChangeSubscriptionPlan = () =>
    import(
        /* webpackChunkName: "settings" */ '@/components/Settings/Items/Subscription/ChangeSubscriptionPlan.vue'
    );

const BillingEstimate = () =>
    import(
        /* webpackChunkName: "settings" */ '@/components/Settings/Items/Billings/BillingEstimate.vue'
    );
const BillingInformationPage = () =>
    import(
        /* webpackChunkName: "settings" */ '@/components/Settings/Items/Billings/BillingInformationPage.vue'
    );
const BillingReceipt = () =>
    import(
        /* webpackChunkName: "settings" */ '@/components/Shared/BillingInformation/BillingReceipt.vue'
    );
// const BillingMainInfo = () =>
//     import('@/components/Admin/UsersTab/UserInfo/BillingMainInfo.vue');
// const GeneralInfoPage = () =>
//     import('@/components/Admin/UsersTab/UserInfo/GeneralInfoPage.vue');
const AccountInformation = () =>
    import('@/components/Settings/AccountInformation.vue');

// const Snaps = () =>
// import(
//     /* webpackChunkName: "Snaps" */ '@/components/Snaps/Snaps.vue'
// );

// const SnapRecording = () =>
// import(
//     /* webpackChunkName: "Snaps" */ '@/components/Snaps/components/SnapRecording/SnapRecording.vue'
// );

// const SnapPageCreate = () =>
// import(
//     /* webpackChunkName: "Snap Page Create" */ '@/components/SnapPage/SnapPageCreate.vue'
// );

// const SnapPageView = () =>
// import(
//     /* webpackChunkName: "Snap Page View" */ '@/components/SnapPage/SnapPageView.vue'
// );

// const SnapPage = () =>
// import(
//     /* webpackChunkName: "Snap Page" */ '@/components/SnapPage/SnapPage.vue'
// );
//
// const SnapPageInSnap = () =>
// import (
//     /* webpackChunkName: "SnapPage" */ '@/components/Snaps/components/SnapPage/SnapPage.vue'
// );
//
// const SharedSnaps = () =>
// import(
//     /* webpackChunkName: "Snaps" */ '@/components/Snaps/components/SharedSnaps/SharedSnaps.vue'
// );
//
// const SharedSnapVideos = () =>
// import(
//     /* webpackChunkName: "Snaps" */ '@/components/Snaps/components/SharedSnaps/SharedSnapVideos.vue'
// );

const PageNotFound = () => 
import (
    /* webpackChunkName: "404" */ '@/components/Errors/PageNotFound.vue'
);

// const Rooms = () =>
// import (
//     /* webpackChunkName: "Rooms" */ '@/components/Rooms/Rooms.vue'
// );
//
// const RoomPage = () =>
// import (
//     /* webpackChunkName: "Rooms" */ '@/components/Rooms/Layout/components/RoomPage/RoomPage.vue'
// );

const PublicVideoPage = () =>
    import(
        /* webpackChunkName: "Public video" */ '@/components/Video/PublicVideo.vue'
        );

const EmailUpdate = () =>
        import (
            /* webpackChunkName: "Email Update" */  '@/components/EmailUpdate.vue'
        );

// const Transactions = () => import('@/components/Admin/Transactions/Transactions.vue');
//
// const Sales = () => import('@/components/Admin/Transactions/Sales.vue');
// const Sales = () => import('@/components/Admin/Transactions/Sales.vue');
import WhiteBoard from '../components/WhiteBoard.vue'
import WhiteBoardDashBoard from '../components/4kplayer/WhiteBoardDashBoard.vue'
import WhiteBoardUsers from '../components/4kplayer/WhiteBoardUsers.vue'
import WhiteBoardCreateUsers from '../components/4kplayer/WhiteBoardCreateUsers.vue'
import WhiteBoardResetPass from '../components/4kplayer/WhiteBoardResetPass.vue'
import WhiteBoardPlans from '../components/4kplayer/WhiteBoardPlans.vue'
import WhiteBoardCreatePlans from '../components/4kplayer/WhiteBoardCreatePlans.vue'
import WhiteBoardMovePlans from '../components/4kplayer/WhiteBoardMovePlans.vue'
import WhiteBoardEditPlans from '../components/4kplayer/WhiteBoardEditPlans.vue'
import WhiteBoardSettings from '../components/4kplayer/WhiteBoardSettings.vue'

import WhiteBoardSettingsBranding from '../components/4kplayer/settings/WhiteBoardSettingsBranding.vue'
import WhiteBoardSettingsEmail from '../components/4kplayer/settings/WhiteBoardSettingsEmail.vue'
import WhiteBoardSettingsIntegration from '../components/4kplayer/settings/WhiteBoardSettingsIntegration.vue'
import WhiteBoardSettingsUserCrm from '../components/4kplayer/settings/WhiteBoardSettingsUserCrm.vue'

/** First Access components */
import FirstAccess from '../components/FirstLogin/Index.vue'
import FirstAccessInfo from '../components/FirstLogin/Info.vue'
import FirstAccessBranding from '../components/FirstLogin/Branding.vue'
import FirstAccessSMTPIntegration from '../components/FirstLogin/SmtpIntegration.vue'
import FirstAccessPlanWebhook from '../components/FirstLogin/PlanWebhook.vue'
import FirstAccessLaunch from '../components/FirstLogin/Launch.vue'

const main_routes = [
  
    {
        path: '/projects/:id',
        name: 'Project All Videos',
        component: PowerPlayerDefault,
        meta: {
            title: 'Project All Videos',
            auth: true,
            whitelabel : false,
        }
    },
    {
        path: '/',
        name: '4kplayer',
        component: PowerPlayerDefault,
        meta: {
            title: 'My Videos',
            auth: true,
            whitelabel : false,
        },
        beforeEnter: (to, from, next) => {
            window.location.replace('https://'+location.host+'/projects');
          },
    },
    {
        path: '/whitelabel',
        name: 'WhiteBoard',
        component: WhiteBoard,
        meta: {
            auth: true,
            whitelabel : true,
        },
        children:[

            {
                path: '/whitelabel/dashboard',
                name: 'WhiteBoardDashBoard',
                component: WhiteBoardDashBoard,
                meta: {
                    auth: true,
                    whitelabel : true,
                },
            },
            {
                path: '/whitelabel/users',
                name: 'WhiteBoardUsers',
                component: WhiteBoardUsers,
                meta: {
                    auth: true,
                    whitelabel : true,
                },
            },
            {
                path: '/whitelabel/users/create',
                name: 'WhiteBoardUserCreate',
                component: WhiteBoardCreateUsers,
                meta: {
                    auth: true,
                    whitelabel : true,
                },
            },
            {
                path: '/whitelabel/reset-pass/:id',
                name: 'WhiteBoardResetPass',
                component: WhiteBoardResetPass,
                meta: {
                    auth: true,
                    whitelabel : true,
                },
            },
            {
                path: '/whitelabel/plans',
                name: 'WhiteBoardPlans',
                component: WhiteBoardPlans,
                meta: {
                    auth: true,
                    whitelabel : true,
                },
            },
            {
                path: '/whitelabel/plans/create',
                name: 'WhiteBoardCreatePlans',
                component: WhiteBoardCreatePlans,
                meta: {
                    auth: true,
                    whitelabel : true,
                },
            },
            {
                path: '/whitelabel/plans/edit/:id',
                name: 'WhiteBoardEditPlans',
                component: WhiteBoardEditPlans,
                meta: {
                    auth: true,
                    whitelabel : true,
                },
            },
            {
                path: '/whitelabel/plans/move/:id',
                name: 'WhiteBoardMovePlans',
                component: WhiteBoardMovePlans,
                meta: {
                    auth: true,
                    whitelabel : true,
                },
            },
            {
                path: '/whitelabel/settings',
                name: 'WhiteBoardSettings',
                component: WhiteBoardSettings,
                meta: {
                    auth: true,
                    whitelabel : true,
                },
                children:[
                    {
                        path: '/whitelabel/settings/branding',
                        name: 'WhiteBoardSettingsBranding',
                        component: WhiteBoardSettingsBranding,
                        meta: {
                            auth: true,
                            whitelabel : true,
                        },
                    },
                    {
                        path: '/whitelabel/settings/user-crm',
                        name: 'WhiteBoardSettingsUserCrm',
                        component: WhiteBoardSettingsUserCrm,
                        meta: {
                            auth: true,
                            whitelabel : true,
                        },
                    },
                    {
                        path: '/whitelabel/settings/email',
                        name: 'WhiteBoardSettingsEmail',
                        component: WhiteBoardSettingsEmail,
                        meta: {
                            auth: true,
                            whitelabel : true,
                        },
                    },
                    {
                        path: '/whitelabel/settings/integration',
                        name: 'WhiteBoardSettingsIntegration',
                        component: WhiteBoardSettingsIntegration,
                        meta: {
                            auth: true,
                            whitelabel : true,
                        },
                    },
                ]
                // meta: {
                //     title: 'Stage Public Video Lists',
                //     auth: false
                // }
            },
        ]
        // meta: {
        //     title: 'Stage Public Video Lists',
        //     auth: false
        // }
    },
    {
        path: '/404',
        name: 'PageNotFound',
        component: PageNotFound,
        meta: {
            title: 'Not Found',
            auth: false
        }
    },
   
    {
        path: '/email-update/:hash',
        name: 'EmailUpdate',
        component: EmailUpdate,
        meta: {
            title: 'Email Update Verification',
            auth: false,
        }
    },
    // ProjectRoutes,
    {
        path: '/video-upload-progressing/:id',
        name: 'VideoUploadProgressing',
        component: VideoUploadProgressing,
        props: route => ({
            hash: route.params.hash
        }),
        meta: {
            title: 'Project View',
            auth: true
        }
    },
   
    {
        path: '/power-player/edit/:id',
        name: 'PowerPlayerEditor',
        component: VideoEditor,
        meta: {
            title: 'Power Player Editor',
            auth: true,
            whitelabel : false,
        }
    },
    
    {
        path: '/player/:video_id',
        name: 'PublicVideo',
        component: PublicVideoPage,
        meta: {
            title: 'Public video page',
            auth: false
        }
    },
   
    {
        path: '/settings',
        name: 'Settings',
        component: Settings,
        meta: {
            title: 'Settings',
            auth: true,
            whitelabel : false,
        }
    },
    {
        path: '/projects',
        name: 'AllProject',
        component: ProjectDefault,
        meta: {
            title: 'All Projects',
            auth: true,
            whitelabel : false,
        }
    },
    {
        path: '/settings/:section',
        name: 'SettingsSection',
        component: Settings,
        meta: {
            title: 'Settings',
            auth: true,
            whitelabel : false,
        },
        children: [
            {
                path: '/settings/:section/change-subscription-plan',
                name: 'ChangeSubscriptionPlan',
                component: ChangeSubscriptionPlan,
                meta: {
                    title: 'ChangeSubscriptionPlan',
                    auth: true,
                    whitelabel : false,
                },
            },
            {
                path: '/settings/:section/estimate',
                name: 'BillingEstimate',
                component: BillingEstimate,
                meta: {
                    title: 'BillingEstimate',
                    auth: true,
                    whitelabel : false,
                },
                beforeEnter(to, from, next) {
                    if (from.path === '/settings/billing') {
                        next();
                    } else {
                        next({
                            name: 'SubscriptionPlan'
                        });
                    }
                }
            },
            {
                path: '/settings/:section/information',
                name: 'BillingInformationPage',
                component: BillingInformationPage,
                meta: {
                    title: 'BillingInformationPage',
                    auth: true,
                    whitelabel : false,
                }
            },
            {
                path: '/settings/:section/receipt/:receiptID',
                name: 'BillingReceipt',
                component: BillingReceipt,
                meta: {
                    title: 'BillingReceipt',
                    auth: true,
                    whitelabel : false,
                }
            },
            {
                path: '/settings/:section',
                name: 'SubscriptionPlan',
                component: SubscriptionPlan,
                meta: {
                    title: 'SubscriptionPlan',
                    auth: true,
                    whitelabel : false,
                }
            }
        ]
    },
    {
        path: '/analytics/',
        name: 'Analytics',
        component: Analytics,
        meta: {
            title: 'Analytics',
            auth: true
        }
    },
    {
        path: '/analytics/:section',
        name: 'AnalyticsView',
        component: Analytics,
        meta: {
            title: 'Analytics',
            auth: true
        }
    },
    
    {
        path : '/whitelabel/beginning',
        name : 'FirstAccess',
        component : FirstAccess,
        meta : {
            title: 'Whitelabel',
            auth:true,
            whitelabel : true
        },
        children : [
            {
                path: 'info',
                name: 'FirstAccess.info',
                component: FirstAccessInfo,
                meta: {
                    title: 'Whitelabel Info',
                    auth: true,
                    whitelabel : true,
                },
            },
            {
                path: 'branding',
                name: 'FirstAccess.branding',
                component: FirstAccessBranding,
                meta: {
                    title: 'Whitelabel Branding',
                    auth: true,
                    whitelabel : true,
                },
            },
            {
                path: 'smtp-integration',
                name: 'FirstAccess.smtp.integration',
                component: FirstAccessSMTPIntegration,
                meta: {
                    title: 'Whitelabel SMTP & Integration',
                    auth: true,
                    whitelabel : true,
                },
            },
            {
                path: 'plan-webhook',
                name: 'FirstAccess.plan.webhook',
                component: FirstAccessPlanWebhook,
                meta: {
                    title: 'Whitelabel Plans & Webhook',
                    auth: true,
                    whitelabel : true,
                },
            },
            {
                path: 'launch',
                name: 'FirstAccess.Launch',
                component: FirstAccessLaunch,
                meta: {
                    title: 'Launching...',
                    auth: true,
                    whitelabel : true,
                },
            },
        ]
    }
   
];

export default main_routes;
