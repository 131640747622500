<template>
    <el-dropdown placement="bottom" v-if='isMobile' @command='handleDropdownCommand($event)'>
        <el-button type='default' round style='padding: 10px !important'>
            <MenuDots></MenuDots>
        </el-button>
        <el-dropdown-menu 
            slot="dropdown"
            class="filter__dropdown__menu custom_dropdown dropdown_bottom_200" :class='rightArrow ? "rigthArrow" : ""'>
            <el-dropdown-item command='profile'>Account Information</el-dropdown-item>
            <el-dropdown-item v-if="!$store.getters.userInfo.subuser" command='users'>Users</el-dropdown-item>
            <el-dropdown-item command='billing'>Subscription & Billing</el-dropdown-item>
            <el-dropdown-item command='integration'>Integration</el-dropdown-item>
<!--            <el-dropdown-item command='users'>Users</el-dropdown-item>-->
<!--            <el-dropdown-item command='stage'>Stage</el-dropdown-item>-->
<!--            <el-dropdown-item command='notification'>Notification</el-dropdown-item>-->
<!--            <el-dropdown-item command='privacy'>Global video settings</el-dropdown-item>-->
<!--            <el-dropdown-item command='user_referral'>Earnings & Payouts</el-dropdown-item>-->
        </el-dropdown-menu>
    </el-dropdown>
</template>

<script>
import MenuDots from '../../Project/AllProjects/Components/MenuDots';
export default {
    props: ['isMobile', 'rightArrow'],
    components: {
        MenuDots
    },
    methods: {
        handleDropdownCommand(subSection)
        {
            let route = `/settings/${subSection}`;
            this.$router.push({ path: route });
        }
    },
    watch: {
        $route(to, from) {
            if(to.path.includes('/billing/') || to.path.includes('/billing')) {
                this.activatedTab = to.path.replace(to.path, "billing");
            } else {
                this.activatedTab = to.path.replace("/settings/", "");
            }

            if (!this.activatedTab || this.activatedTab === "" || this.activatedTab === "/settings") {
                this.activatedTab = "profile";
            }

            window.vEvent.fire(
                "handle-settings-menu-select",
                this.activatedTab
            );

        }
    }
}
</script>

<style>
.rigthArrow:before {
    left: calc(160px) !important;
}
</style>