<template>
    <div v-if='show' class='alert-container' :style="{ background: backdrop ? backdrop: 'rgba(0, 0, 0, 0.445)' }">
        <div
            class="alert-message"
            :class='theme'
            :style='{
                background: background ? background : "",
                color: messageColor ? messageColor : "#fff"
            }'>
            {{ message }}
        </div>
    </div>
</template>

<style lang="less" scoped>
    .alert-container {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        margin: auto;
        backdrop-filter: blur(4px);
        transition: 0.3s;
        .alert-message {
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            top: 0;
            bottom: 0;
            width: max-content;
            height: 8%;
            width: -moz-max-content;
            padding: 8px 25px;
            font-size: 20px;
            border-radius: 5px;
            box-shadow: 2px 2px 2px 2px rgba(102, 102, 102, 0.172);
        }
        .error-theme {
            background: #cb3837;
        }
        .success-theme {
            background: #00C642;
        }
    }
</style>

<script>
export default {
    name: 'BaseAlert',
    props: ['message', 'theme', 'show', 'background', 'backdrop', 'messageColor'],
    mounted() {
        setTimeout(() => {
            this.$emit('close');
        }, 3000);
    }
}
</script>