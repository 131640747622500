import { render, staticRenderFns } from "./WhiteBoard.vue?vue&type=template&id=6002ccc6&scoped=true&"
import script from "./WhiteBoard.vue?vue&type=script&lang=js&"
export * from "./WhiteBoard.vue?vue&type=script&lang=js&"
import style0 from "./WhiteBoard.vue?vue&type=style&index=0&id=6002ccc6&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6002ccc6",
  null
  
)

export default component.exports