<template>
  <div id="app" :class="extraAppClass" v-cloak>
    <app-header
      v-if="
        !isAdmin &&
        isLoggedIn &&
        userInfo &&
        ((userInfo.whitelabel_licence !== null && !userInfo.is_first_login) ||
          userInfo.whitelabel_licence === null)
      "
      :user="userInfo"
      :whitelabelBrandDetail="whitelabelBrandDetail"
      @openSearch="handleSearchOpen"
    ></app-header>
    <router-view
      :user="userInfo"
      :whitelabelBrandDetail="whitelabelBrandDetail"
      :class="{ failed_user: userInfo && userInfo.billing_status == 'Failed' }"
      ref="routerView"
    ></router-view>
    <vue-progress-bar></vue-progress-bar>
    <app-search
      v-if="isLoggedIn && userInfo && userActive"
      ref="appSearch"
    ></app-search>
    <!--    <modal-video v-if='isLoggedIn && userInfo && userActive'/>-->
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import AppHeader from "./components/App/AppHeader.vue";
import AppSearch from "./components/App/AppSearch.vue";
import ModalVideo from "./components/Video/ModalVideo";

export default {
  name: "app",
  components: {
    AppHeader,
    AppSearch,
    ModalVideo,
  },
  computed: {
    ...mapGetters({
      isLoggedIn: "isAuthenticated",
    }),
    isAdmin: function () {
      return window.location.pathname.includes("admin");
    },
    user: {
      get() {
        return this.$store.state.userData;
      },
    },
  },
  data: () => {
    return {
      userId: 0,
      name: "",
      email: "",
      currentTeam: 0,
      extraAppClass: "",
      userInfo: null,
      userActive: false,
      blockedRoutes: ["RoomPage", "SnapSharedRecording", "SnapPageView"],
      whitelabelBrandDetail: null,
    };
  },
  beforeDestroy() {
    window.vEvent.stop("update-user-data", this.updateUserInfo);
  },
  mounted() {
    if (this.isLoggedIn) {
      this.getUserDetail();
    }

    this.eventListen();
  },
  created() {
    if (process.env.VUE_APP_WHITELABEL_DOMAIN === location.host) {
      this.extraAppClass = "mbsm_whitelabel_platform";
      var r = document.querySelector(":root");
      r.style.setProperty("--backgroundThemeColor", "#fff");
      r.style.setProperty("--buttonThemeColor", "#0b3098");
      r.style.setProperty("--textThemeColor", "#383737");
      //  document.title = ` | MBSM`
      
       this.$store.state.siteName = "MBSM"
        this.$store.state.pageTitle = "MBSM"
    }

    this.axios({
      method: "post",
      url: `whitelabel/brand/checkBrandDetail`,
      data: {
        url: window.location.host,
      },
    })
      .then((response) => {
        this.whitelabelBrandDetail = response.data.whitelabelBrandDetail;
        if (
          this.whitelabelBrandDetail !== null &&
          this.whitelabelBrandDetail.app_name !== null
        ) {
          // this.$store.state.siteName = this.whitelabelBrandDetail.app_name;
           this.$store.state.siteName = this.whitelabelBrandDetail.app_name
        this.$store.state.pageTitle = this.whitelabelBrandDetail.app_name
          // document.title = $route.name || this.whitelabelBrandDetail.app_name;
        }
        if (
          this.whitelabelBrandDetail !== null &&
          this.whitelabelBrandDetail.favicon_logo !== null
        ) {
          const favicon = document.getElementById("favicon");
          favicon.href = this.whitelabelBrandDetail.favicon_logo;
        }

        if (this.whitelabelBrandDetail !== null) {
          if (
            this.whitelabelBrandDetail.backgroundColor !== null &&
            this.whitelabelBrandDetail.backgroundColor !== ""
          ) {
            // console.log(this.whitelabelBrandDetail.backgroundColor, 'this.whitelabelBrandDetail.backgroundColor');

            var r = document.querySelector(":root");
            r.style.setProperty(
              "--backgroundThemeColor",
              this.whitelabelBrandDetail.backgroundColor
            );
          }

          if (
            this.whitelabelBrandDetail.buttonColor !== null &&
            this.whitelabelBrandDetail.buttonColor !== ""
          ) {
            var r = document.querySelector(":root");
            r.style.setProperty(
              "--buttonThemeColor",
              this.whitelabelBrandDetail.buttonColor
            );
          }

          if (
            this.whitelabelBrandDetail.textColor !== null &&
            this.whitelabelBrandDetail.textColor !== ""
          ) {
            var r = document.querySelector(":root");
            r.style.setProperty(
              "--textThemeColor",
              this.whitelabelBrandDetail.textColor
            );
          }
        }

        // backgroundThemeColor

        // this.domain = response.data;
      })
      ["catch"]((error) => {});
  },
  methods: {
    ...mapMutations({
      setUserInfo: "setUserInfo",
    }),

    /**
     * Components Events Listen.
     */
    eventListen() {
      window.vEvent.listen("update-user-data", this.updateUserInfo);
      window.Ls.remove("sub-user-info");
    },

    async getUserDetail() {
      var userInfo =
        this.$store && this.$store.getters && this.$store.getters.userInfo
          ? this.$store.getters.userInfo
          : false;

      if (userInfo && userInfo.status_id) {
        if (userInfo.status_id == 5) {
          this.$router.push("/payment_hold");
        } else {
          this.$nextTick(() => {
            this.setUserData(this.user);
            this.setUserActive();
          });
        }
      } else {
        await this.$store
          .dispatch("getUserData")
          .then((r) => {
            if (this.user.status_id == 5) {
              this.$router.push("/payment_hold");
            } else {
              this.$nextTick(() => {
                this.setUserData(this.user);
                this.setUserActive();
              });
            }
          })
          .catch((e) => {
            console.log(e, "*-*-**-*-*-*-*");
            this.$store.commit("destroyAccessToken");
            this.$router.push("/login/1");
          });
      }
    },

    handleSearchOpen() {
      this.$refs.appSearch.handleOpen();
    },
    setUserActive() {
      this.userActive = true;
      // this.userActive = false;{{this.userInfo}}
      // if (this.userInfo.user_status === 1) {
      //   if (this.userInfo.billing_status) {
      //     switch (this.userInfo.billing_status) {
      //       case "Active":
      //       case "Trial":
      //       case "VerifyRequired":
      //       case "Failed":
      //       case "Cancelled":
      //         this.userActive = true;
      //         break;
      //       case "Expired":
      //       case "Inactive":
      //       // case "Cancelled":
      //         // case 'Failed':
      //         this.userActive = false;
      //         break;
      //     }
      //   }
      // } else {
      //   this.userActive = false;
      // }
    },
    setUserData(data) {
      if (data) {
        this.userInfo = data;
        this.userId = this.userInfo.id;
        this.name = this.userInfo.first_name;
        this.email = this.userInfo.email;

        // this.readyInterCom();
      }
    },
    updateUserInfo(user) {
      this.setUserInfo(user);
    },
    readyInterCom() {
      this.$intercom.boot({
        user_id: this.userId,
        name: this.user.first_name,
        email: this.user.email,
      });
    },
    loadKoyaku() {

      var url = process.env.VUE_APP_WHITELABEL_DOMAIN === location.host ? process.env.VUE_APP_WHITELABEL_URL : process.env.VUE_APP_BACKEND_URL;

      if(location.host === process.env.VUE_APP_DOMAIN){
      window.$crisp = [];
      window.CRISP_WEBSITE_ID = "c8170ec3-c603-43f8-886a-9b8a69bf03c8";
      (function () {
        var d = document;
        var s = d.createElement("script");
        s.src = "https://client.crisp.chat/l.js";
        s.async = 1;
        d.getElementsByTagName("head")[0].appendChild(s);
      })();

      }else{
        
        if(this.whitelabelBrandDetail !== null && this.whitelabelBrandDetail.chat_script !== null){
          console.log('chat script');
        this.whitelabelBrandDetail.chat_script
        }
      }


   
      //(function(d,a){function c(){var b=d.createElement("script");b.async=!0;b.type="text/javascript";b.src=a._settings.messengerUrl;b.crossOrigin="anonymous";var c=d.getElementsByTagName("script")[0];c.parentNode.insertBefore(b,c)}window.kayako=a;a.readyQueue=[];a.newEmbedCode=!0;a.ready=function(b){a.readyQueue.push(b)};a._settings={apiUrl:"https://bigcommand-help.kayako.com/api/v1",messengerUrl:"https://bigcommand-help.kayakocdn.com/messenger",realtimeUrl:"wss://kre.kayako.net/socket"};window.attachEvent?window.attachEvent("onload",c):window.addEventListener("load",c,!1)})(document,window.kayako||{});
    },
  },
  watch: {
    "user.email": function (email) {
      if (this.isLoggedIn) {
        // this.$intercom.update({
        //   email
        // });
      }
    },
    user(newUser, oldUser) {
      this.userInfo = false;
      this.userActive = false;
      if (this.isLoggedIn) {
        if (newUser) {
          this.setUserData(newUser);
          this.setUserActive();
          let vm = this;
          this.$nextTick(function () {
            if (!oldUser || newUser !== oldUser) {
              vm.$forceUpdate();
            }
          });
        }
      }
    },
    $route(to, from) {
      if (!this.blockedRoutes.includes(to.name)) this.loadKoyaku();
    },
  },
};
</script>
<style lang="less">
@import "assets/less/bootstrap/bootstrap";
/*@import "assets/less/bootstrap-select/bootstrap-select";*/
@import "assets/less/variables";
/*@import "assets/less/spacing";*/
/*@import "assets/less/form";*/
/*@import "assets/less/text";*/
/*@import "assets/less/general-hacks";*/
/*@import "assets/less/sweetalert";*/
/*@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700,800');*/
@import "~@fortawesome/fontawesome-free/less/solid.less";
@import "~@fortawesome/fontawesome-free/less/regular.less";
@import "~@fortawesome/fontawesome-free/less/brands.less";
@import "~@fortawesome/fontawesome-free/less/fontawesome.less";
@import "assets/less/layout";
@import "assets/less/vars";
@import "assets/less/app";

#app {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.mbsm_whitelabel_platform {
  width: 1200px;
  overflow: hidden;
  margin: 0 auto;
  .topbar {
    left: auto;
    right: auto;
    width: inherit;
  }
  .users-main {
    padding-top: 0;
  }
  .whitelable-page-heading {
    padding-top: 12px;
    margin-bottom: 20px;
    clear: both;
  }
  .user-header {
    position: absolute;
    top: 10px;
    right: 0px;
    margin: 0;
    padding: 0;
    .right-side {
      border-left: none !important;
    }
  }
  .el-aside.k-el-asid {
    z-index: 9;
    // border: 2px solid #eef0f7 !important;
    height: calc(100% - 81px) !important;
    border-radius: 20px;
  }
  .card-container {
    padding: 50px 30px;
    padding-top: 0px !important;
    .right-side {
      margin: 0 !important;
      padding: 0 !important;
    }
  }
  #main_menu {
    .el-menu-main {
      font-size: 16px;
      background: var(--backgroundThemeColor) !important;
      box-shadow: 0 0 5px 0 #f9f9fc;
      border: 1px solid #e6e6f1;
      display: flex;
    }
    .el-menu {
      background: #f9f9fc !important;
      border-radius: 10px;
    }
  }
  .mbsm_main_logo {
    border-radius: 3px;
  }
  .el-menu-item {
    border-radius: 10px;
    font-size: 15px;
    height: 48px;
    line-height: 48px;
    margin-top: 5px;
    margin-bottom: 5px;
    &.no-action {
      background-color: transparent !important;
      color: inherit;
    }
    &.sub-menu {
      margin-left: 30px;
    }
    &.is-active {
      background-color: rgb(249 249 252);
    }
    &:hover {
      background-color: rgb(249 249 252);
    }
  }
  .user-list-table {
    .table-header {
      display: flex;
      text-transform: uppercase;
      font-weight: bold;
      padding: 20px;
      border-bottom: 2px solid #f4f7fb;
      margin-bottom: 0 !important;
    }
    .table-row {
      display: flex;
      padding: 10px 20px;
      border-bottom: 1px solid #f4f7fb;
      align-items: center;
    }
  }
  .el-container.main-container,
  > div {
    width: inherit;
    display: block;
    .right-side {
      position: relative;
      width: inherit;
      margin-left: 280px;
      /* background: #f9f9fc !important; */
      margin-top: 30px;
      margin-bottom: 10px;
      left: 0;
      border-radius: 0;
      border-left: 1px solid rgb(228 232 241);
      .all-cards,
      .users-main,
      .brand-setting,
      .setting-user-crm,
      .email-setting,
      .integration-setting {
        position: relative;
        top: 0;
      }
    }
  }
}

[v-cloak] * {
  display: none;
}
</style>

<style>
@media screen and (max-width: 991px) {
  .mobile-col-lg-12 {
    width: 100%;
  }

  .mobile-border-right-none-md-12 {
    border-right: none;
  }

  .mobile-flex-wrap {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 767px) {
  .mobile-col-md-12 {
    width: 100%;
  }

  .mobile-col-md-6 {
    width: 50%;
  }

  .mobile-border-right-none-md-12 {
    border-right: none !important;
  }
}

@media screen and (max-width: 575px) {
  .mobile-col-sm-12 {
    width: 100%;
  }

  .mobile-w-100-sm {
    width: 100% !important;
  }

  .mobile-px-2-sm {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
</style>
