import 'es6-promise/auto';
import Vue from 'vue';
import Vuex from 'vuex';
import state from './common/state';
import mutations from './common/mutations';
import getters from './common/getters';
import actions from './common/actions';
import publish from './publish';
import VideoUploadStore from './store_modules/VideoUploadStore';
import AuthStore from './store_modules/AuthStore';
import VuexPersistence from 'vuex-persist';

const vuexLocal = new VuexPersistence({
    storage: window.localStorage,
    reducer: state => ({
        activeColumns: {
            checkedColumnsDefault: state.activeColumns.checkedColumnsDefault,
            availableColumnsDefault: state.activeColumns.availableColumnsDefault,
            checkedColumnsForTable: state.activeColumns.checkedColumnsForTable,
            checkedColumnsForMenu: state.activeColumns.checkedColumnsForMenu,
            availableColumns: state.activeColumns.availableColumns,
            availableColumnsTemporary: state.activeColumns.availableColumnsTemporary
        },
        suspendedColumns: {
            checkedColumnsDefault: state.suspendedColumns.checkedColumnsDefault,
            availableColumnsDefault: state.suspendedColumns.availableColumnsDefault,
            checkedColumnsForTable: state.suspendedColumns.checkedColumnsForTable,
            checkedColumnsForMenu: state.suspendedColumns.checkedColumnsForMenu,
            availableColumns: state.suspendedColumns.availableColumns,
            availableColumnsTemporary: state.suspendedColumns.availableColumnsTemporary
        },
        accountUsersColumns: {
            checkedColumnsDefault: state.accountUsersColumns.checkedColumnsDefault,
            availableColumnsDefault: state.accountUsersColumns.availableColumnsDefault,
            checkedColumnsForTable: state.accountUsersColumns.checkedColumnsForTable,
            checkedColumnsForMenu: state.accountUsersColumns.checkedColumnsForMenu,
            availableColumns: state.accountUsersColumns.availableColumns,
            availableColumnsTemporary: state.accountUsersColumns.availableColumnsTemporary
        }
    }),
    filter: mutation =>
        mutation.type == 'addAvailableColumns' ||
        mutation.type == 'addTemporaryOrder' ||
        mutation.type == 'checkColumn' ||
        mutation.type == 'columnsCancelPushed' ||
        mutation.type == 'columnsDonePushed' ||
        mutation.type == 'changeColumnsOrder' ||
        mutation.type == 'changeAvailableColumnsOrder' ||
        mutation.type == 'restoreDefaultCheckedColumns' ||
        mutation.type == 'restoreInitialColumnsOrder' ||
        mutation.type == 'setDefaultCheckedColumns' ||
        mutation.type == 'addDefaultAvailableColumns' 
});

Vue.use(Vuex);

//=======vuex store start===========
const store = new Vuex.Store({
    state: state,
    getters,
    mutations,
    actions,
    modules: {
        VideoUpload: VideoUploadStore,
        Auth: AuthStore,
        publish
    },
    plugins: [vuexLocal.plugin]
});

//=======vuex store end===========
export default store;
