import { render, staticRenderFns } from "./ViewChart.vue?vue&type=template&id=6358be42&scoped=true&"
import script from "./ViewChart.vue?vue&type=script&lang=js&"
export * from "./ViewChart.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6358be42",
  null
  
)

export default component.exports