<template>
    <transition name='slide-fade'>
        <div class="modal-video" v-if='video'>
            <div class="modal-video-container">
                <div class="modal-video-content">
<!--                    <Video :video='video' />-->
                    <div class="modal-video-buttons d-flex justify-content-center">
                        <div @click='close' class="btn">Close This Video</div>
                        <div @click='showWarning = true' class="btn">Skip & Don't Show</div>
                    </div>
                </div>
            </div>
            <ConfirmModal
                :show='showWarning'
                @cancel='showWarning = false'
                @confirm='skipForever'
                title='Skip Video?'
                :loading='loading'
                subText='This video will not show up again'
            />
        </div>
    </transition>
</template>

<script>
// import Video from '../Video';
import ConfirmModal from '../Common/ConfirmModal';

export default {
    name: 'ModalVideo',
    components: {
        ConfirmModal
    },
    data: () => ({
        video: null,
        showWarning: false,
        loading: false,
    }),
    mounted() {
        this.getIntroVideo();
    },
    methods: {
        getIntroVideo() {
            const url = `tutorial/video/intro`;
            this.showWarning = false;
            this.axios.get(url)
                .then(response => {
                    if (response.data.success) {
                        if (!response.data.seen) {
                            this.video = response.data.video;
                        }
                    }
                })
                .catch(error => {
                    console.log(error);
                })
        },
        close() {
            this.video = null;
            this.$emit('close');
        },
        skipForever() {
            const video = this.video;
            this.loading = true;
            this.axios.post('seen/video', {
                user_id: video.owner,
                video_id: video.id,
            }).catch(error => {
                console.log(error);
                this.loading = false;
                this.video = null;
            }).then(() => {
                this.loading = false;
                this.video = null;
            });
        }
    }
}
</script>

<style lang='less' scoped>
    .modal-video {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.5);
        z-index: 15;
        .modal-video-container {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            .modal-video-content {
                width: 60%;
                height: max-content;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                border-radius: 12px;
                background: #fff;
                padding: 30px 30px 0 30px;
            }
            .modal-video-buttons {
                width: 100%;
                background: #fff;
                border-bottom-left-radius: 12px;
                border-bottom-right-radius: 12px;
                overflow-wrap: break-word;
                word-break: break-all;
                .btn {
                    border-radius: 0px;
                    font-size: 1rem;
                    text-align: center;
                    transition: 0.3s;
                    &:hover {
                        background: rgba(0, 0, 0, 0.5);
                        color: #fff;
                    }
                }
            }
        }
    }
    @media (max-width: 960px) {
        .modal-video {
            .modal-video-container {
                .modal-video-content {
                    width: 95%;
                }
            }
        }
    }
    .slide-fade-enter-active {
        transition: all .3s ease;
    }
    .slide-fade-leave-active {
        transition: all .1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }
    .slide-fade-enter, .slide-fade-leave-to {
        transform: translateX(10px);
        opacity: 0;
    }
</style>