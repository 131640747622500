<template>
    <div>
        <div
            class="limit-popover"
            v-if='showLimitAlert'
            :style='{ 
                "position": position ? position : "absolute",
                "z-index": zIndex ? zIndex : "95",
            }'>
            <div class="warn-sign"><img src="../../../../static/img/exclamation.svg" alt=""></div>
            <p>{{ messageHead}}</p>
            <p>{{ messageBody }}</p>
            <div class='d-flex'>
                <el-button round class="restriction_dialog_btn" type="success" @click="showUpgradeOptions"> See Upgrade
                    Options
                </el-button>
                <el-button v-if='!disableCancelButton' round type="danger" class='restriction_dialog_btn' @click='$emit("close")'>Close</el-button>
            </div>
        </div>
    </div>
</template>

<style lang="less" scoped>
    .limit-popover {
        font-size: 14px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 95;
        background: rgba(1, 26, 34, 0.822);
        backdrop-filter: blur(8px);
        p {
            color: #fff;
        }
    }

    .warn-sign {
        position: relative;
        width: 60px;
        height: 60px;
        text-align: center;
        margin-bottom: 15px;

        img {
            max-width: 100%;
            object-fit: cover;
        }
    }
</style>

<script>
export default {
    name: 'BaseUpgradeModal',
    props: ['showLimitAlert', 'messageHead', 'messageBody', 'position', 'zIndex', 'disableCancelButton'],
    methods: {
        showUpgradeOptions() {
            this.$router.push({name : 'ChangeSubscriptionPlan', params: {'section': 'billing'} });
        },
    }
}
</script>