<template>
  <div class="">
    <el-aside width="260px" class="k-el-asid">
      <!-- <ul>
        <li
          v-if=" user && (
            user.whitelabel_setting === null ||
            (user.whitelabel_setting !== null &&
              user.whitelabel_setting.app_logo === null))
          "
        >
          <router-link :to="{ name: 'PowerPlayer' }"
            ><img src="../../../assets/img/4kplayer/4k-logo.png" width="100px"
          /></router-link>
        </li>
        <li
          v-if=" user && (
            user.whitelabel_setting !== null &&
            user.whitelabel_setting.app_logo !== null)
          "
        >
          <router-link :to="{ name: 'PowerPlayer' }"
            ><img :src="user ? user.whitelabel_setting.app_logo : ''" width="100px"
          /></router-link>
        </li>
      </ul> -->

  

      <!-- <el-radio-group v-model="isCollapse" style="margin-bottom: 20px">
        <el-radio-button :label="false">
          <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.08984 0.785156C8.16406 0.710938 8.27539 0.673828 8.42383 0.673828C8.53516 0.673828 8.64648 0.710938 8.7207 0.785156L16.4766 8.57812C16.5508 8.65234 16.625 8.76367 16.625 8.875C16.625 9.02344 16.5508 9.09766 16.4766 9.17188L8.7207 16.9648C8.64648 17.0391 8.53516 17.0762 8.42383 17.0762C8.27539 17.0762 8.16406 17.0391 8.08984 16.9648L7.34766 16.2227C7.27344 16.1484 7.23633 16.0742 7.23633 15.9258C7.23633 15.8145 7.27344 15.7031 7.34766 15.5918L13.0996 9.83984H0.445312C0.296875 9.83984 0.185547 9.80273 0.111328 9.72852C0.0371094 9.6543 0 9.54297 0 9.39453V8.35547C0 8.24414 0.0371094 8.13281 0.111328 8.05859C0.185547 7.98438 0.296875 7.91016 0.445312 7.91016H13.0996L7.34766 2.1582C7.27344 2.08398 7.23633 1.97266 7.23633 1.82422C7.23633 1.71289 7.27344 1.60156 7.34766 1.52734L8.08984 0.785156Z" fill="#B780BA"></path></svg>        
          </el-radio-button>
        <el-radio-button :label="true">
          <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.63672 14.8633C7.53711 14.9297 7.4375 14.9629 7.33789 14.9629C7.20508 14.9629 7.13867 14.9297 7.07227 14.8633L0.132812 7.89062C0.0332031 7.82422 0 7.75781 0 7.625C0 7.52539 0.0332031 7.42578 0.132812 7.35938L7.07227 0.386719C7.13867 0.320312 7.20508 0.287109 7.33789 0.287109C7.4375 0.287109 7.53711 0.320312 7.63672 0.386719L8.30078 1.05078C8.36719 1.11719 8.40039 1.2168 8.40039 1.31641C8.40039 1.44922 8.36719 1.54883 8.30078 1.61523L3.1543 6.76172H14.4766C14.5762 6.76172 14.6758 6.82812 14.7422 6.89453C14.8086 6.96094 14.875 7.06055 14.875 7.16016V8.08984C14.875 8.22266 14.8086 8.32227 14.7422 8.38867C14.6758 8.45508 14.5762 8.48828 14.4766 8.48828H3.1543L8.30078 13.6348C8.36719 13.7344 8.40039 13.834 8.40039 13.9336C8.40039 14.0664 8.36719 14.1328 8.30078 14.1992L7.63672 14.8633Z" fill="#B780BA"></path></svg>
        </el-radio-button>
      </el-radio-group> -->
      <el-menu
        default-active="0"
        class="el-menu-vertical-demo"
        @open="handleOpen"
        @close="handleClose"
        :collapse="isCollapse"
      >
        
        <el-menu-item :class="{'is-active' : $route.name === 'WhiteBoardDashBoard'}"  @click="handleClick('WhiteBoardDashBoard')" index="1">
          <i class="el-icon-menu"></i>
          <span slot="title">Dashboard</span>
        </el-menu-item>
        <el-menu-item :class="{'is-active' : $route.name === 'WhiteBoardUsers'}" @click="handleClick('WhiteBoardUsers')" index="2">
            <i class="el-icon-document"></i>
            <span slot="title">Users</span>
        </el-menu-item>
        <el-menu-item :class="{'is-active' : $route.name === 'WhiteBoardPlans'}" @click="handleClick('WhiteBoardPlans')" index="3">
          <i class="el-icon-document"></i>
          <span slot="title">Plans</span>
        </el-menu-item>
        <el-menu-item class="no-action" index="4">
          <i class="el-icon-setting"></i>
            <span slot="title">Settings</span>
        </el-menu-item>
        
          <el-menu-item class="sub-menu" :class="{'is-active' : $route.name === 'WhiteBoardSettingsBranding'}" @click="handleClick('WhiteBoardSettingsBranding')"  index="5">- Branding</el-menu-item>
          <el-menu-item class="sub-menu" :class="{'is-active' : $route.name === 'WhiteBoardSettingsUserCrm'}" @click="handleClick('WhiteBoardSettingsUserCrm')"  index="6">- User/CRM Sync</el-menu-item>
          <el-menu-item class="sub-menu" :class="{'is-active' : $route.name === 'WhiteBoardSettingsEmail'}" @click="handleClick('WhiteBoardSettingsEmail')"  index="7">- Email Settings</el-menu-item>
          <el-menu-item class="sub-menu" :class="{'is-active' : $route.name === 'WhiteBoardSettingsIntegration'}" @click="handleClick('WhiteBoardSettingsIntegration')"  index="8">- Integrations</el-menu-item>
        
      </el-menu>
    </el-aside>
  </div>
</template>

<script>
export default {
  props: ["user"],
  data() {
    return {
      isCollapse: false
    };
  },
  created() {
    
  },
  methods:{
     handleOpen(key, keyPath) {
        console.log(key, keyPath);
      },
      handleClose(key, keyPath) {
        console.log(key, keyPath);
      },
      handleClick(name){
        this.$router.push({name: name})
      }
  }
};
</script>

<style scoped lang="less">
.el-aside.k-el-asid {
  background: var(--backgroundThemeColor);
  height: 100%;
  margin-left: 0;
  // padding-top: 60px;
  position: fixed;
  border-right: 1px solid #fff;
  .pt-2{
    padding-top:20px;
  }
  @media (max-width: 767px) {
    display: none;
  }
  .el-menu, .el-submenu .el-menu{
    background: transparent;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: center;
    li{
      text-align: left !important;
    }
    img {
      width: 70%;
    }
  }
}
</style>
