export default {
  namespaced: true,
  state: {
    user: false,
    isLoggedIn: false
  },
  mutations: {
    setUser(state, payload) {
      state.user = payload;
      state.isLoggedIn = !!state.user;
    }
  },
  actions: {
    setUser({dispatch, commit, state}, payload) {
      commit('setUser', payload);
    }
  }
}
