let state = {
  selectedSortOption: {
    order: "desc",
    value: "byDate",
    label: "By Date"
  },
  plansMenuItem: {
    selectedStatusOption: "",
    statusesList: []
  },
  tags: {
    usersWithAllTags: "",
    customTagsList: [
      {
        tag: "Custom Tag",
        usersCount: "55"
      }
    ],
    selectedTagOption: null
  },
  users: {
    usersCount: "",
    usersList: "",
    allUsers: [],
    isLoading: true,
    accountName: "",
    userInfo: {
      settings: {
        city: "",
        coutry: "",
        company: "",
        timezone: ""
      },
      currentTeam: {
        role: ""
      },
      full_contact: {
        original: {
            details: {
                interests: []
            }
        }
      },
      subscription: {
        name: '',
        created_at: '',
        updated_at: ''
      }
    },
    search: "",
    notes: []
  },
  noteText: "",
  currentSection: "",
  activeColumns: {
    checkedColumnsDefault: [
      "User",
      "Related Users",
      "Plan",
      "Subscription Status",
      "Location"
    ],
    availableColumnsDefault: [],
    checkedColumnsForTable: [
      "User",
      "Related Users",
      "Plan",
      "Subscription Status",
      "Location"
    ],
    checkedColumnsForMenu: [
      "User",
      "Related Users",
      "Plan",
      "Subscription Status",
      "Location"
    ],
    availableColumns: [],
    availableColumnsTemporary: []
  },
  suspendedColumns: {
    checkedColumnsDefault: [
      "User",
      "Related Users",
      "Plan",
      "Subscription Status",
      "Location"
    ],
    availableColumnsDefault: [],
    checkedColumnsForTable: [
      "User",
      "Related Users",
      "Plan",
      "Subscription Status",
      "Location"
    ],
    checkedColumnsForMenu: [
      "User",
      "Related Users",
      "Plan",
      "Subscription Status",
      "Location"
    ],
    availableColumns: [],
    availableColumnsTemporary: []
  },
  deletedColumns: {
    checkedColumnsDefault: [
      "User",
      "Related Users",
      "Plan",
      "Subscription Status",
      "Location"
    ],
    availableColumnsDefault: [],
    checkedColumnsForTable: [
      "User",
      "Related Users",
      "Plan",
      "Subscription Status",
      "Location"
    ],
    checkedColumnsForMenu: [
      "User",
      "Related Users",
      "Plan",
      "Subscription Status",
      "Location"
    ],
    availableColumns: [],
    availableColumnsTemporary: []
  },
  accountUsersColumns: {
    checkedColumnsDefault: ["User", "Role", "Plan", "Projects", "Location"],
    availableColumnsDefault: [],
    checkedColumnsForTable: ["User", "Role", "Plan", "Projects", "Location"],
    checkedColumnsForMenu: ["User", "Role", "Plan", "Projects", "Location"],
    availableColumns: [],
    availableColumnsTemporary: []
  },
  complianceIssues: [],
  suspensionReasons: []
};

export default state;
