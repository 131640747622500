import auth_getters from '../auth/getters';
import settings_getters from '../settings/getters';

const getters = Object.assign(
    auth_getters,
    settings_getters,
    {}
);

export default getters;
