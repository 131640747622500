import axios from 'axios'

const actions = {
  async getOneProject ({ commit }, id) {
    return await axios
      .get(`projects/show?id=${id}`)
      .then(res => {
        commit('changeCurrentProject', res.data)
      })
      .catch(err => {
        console.log(err)
      })
  },
  // async getAllUsersProjects ({ commit }, id) {
  //   try {
  //     await commit('setProjectsError' , null);
  //         let incomingResponse = await userApi.updateUserPassword(formInfo);
  //     if(incomingResponse.err){
  //         let error_message = "Error! Something went wrong, kindly try again later"
  //         await commit("setProjectsError", error_message);
  //     } 
  //     else{
  //         if(incomingResponse.response.data.status != 200){
  //             let error_message = incomingResponse.response.data.message
  //             await commit("setProjectsError", error_message);
  //         }
  //         else{
  //             await commit('setProjects', incomingResponse.response.data.data)
  //         }
  //     }
  //   }
  //   catch(err) {
  //     let error_message = "Error! Something went wrong, kindly try again later"
  //     await commit("setProjectsError", error_message);
  //   }
  //   return await axios
  //     .get(`projects/show?id=${id}`)
  //     .then(res => {
  //       commit('changeCurrentProject', res.data)
  //     })
  //     .catch(err => {
  //       console.log(err)
  //     })
  // }
}

export default actions

