<template>
    <div class="tab-holder">
        <div class="">
            <h3>First Time? Launch Your App Instance</h3>
        </div>
        <ul class="tabs">
            <li :class="$route.name=='FirstAccess.branding' ? 'active' : ''"><router-link :to="{name:'FirstAccess.branding'}">Step:1 Branding</router-link></li>
            <li :class="$route.name=='FirstAccess.smtp.integration' ? 'active' : ''"><router-link :to="{name: 'FirstAccess.smtp.integration'}">Step:2 SMTP & Integrations</router-link></li>
            <li :class="$route.name=='FirstAccess.plan.webhook' ? 'active' : ''"><router-link :to="{name: 'FirstAccess.plan.webhook'}">Step:3 Plans & Webhook</router-link></li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: "FirstAccessTabs",
    };
</script>
<style lang="less" scoped>

.tabs{
    margin-top: 30px;
    margin-bottom: 20px;
    list-style-type: none;
        display: flex;
        padding: 0;
        // border: 1px solid #ccc;
    li{
        padding: 15px 10px;
        width: 33.33333%;
        background: #dae3f38a;
        flex-grow: 1;
        color: #053083;
        text-align: center;
        font-weight: 500;
        line-height: 19px;
        &:first-child{
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
        }
        &:last-child{
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;
        }
        &.active{
            background: #0b44b124;
        }
        a{
              color: inherit;
            text-decoration: none;
            &:hover{
                color: inherit;
            }
        }
    }
    
}

</style>