<template>
    <div class='date-range-picker-container' :class='title ? "d-flex": ""'>
        <div class="title" v-if='title'>
            {{ title }}
        </div>
        <div class="el-select condition-select date-range-select">
            <!-- <div class="show-date-range-label" v-if="showRange">{{ range_duration }}</div> -->
            <div class="el-input el-input--suffix">
                <input
                    autocomplete="off"
                    class="el-input__inner no-border"
                    placeholder="Select"
                    readonly="readonly"
                    type="text"
                    v-model="rangeLabel"
                    style="width:140px"
                />
                <span class="el-input__suffix">
                    <span class="el-input__suffix-inner">
                        <i class="el-select__caret el-input__icon el-icon-arrow-up"></i>
                    </span>
                </span>
            </div>
        </div>
    </div>
</template>
<script>
import moment from "moment";
import "daterangepicker/daterangepicker";
import "daterangepicker/daterangepicker.css";
import $ from "jquery";
import { DATE_FORMATS } from "../../../constants";

const cur_date = new Date();

export default {
    name: "CustomDateRangePicker",
    props: {
        conditionItem: Object,
        parentEl: String,
        startDate: String,
        showRange: {
            type: Boolean,
            default: false
        },
        title: String,
    },
    data: () => ({
        customOptions: {
            startDate: moment(),
            // minDate: moment(this.startDate, "YYYY-MM-DD"),
            endDate: moment(),
            opens: "left",
            applyButtonClasses: "btn-primary btn-rounded",
            cancelButtonClasses: "btn-cancel btn-rounded",
            autoApply: false,
            parentEl: ".date-range-select",
            ranges: {
                Yesterday: [
                    moment().subtract(1, "days"),
                    moment().subtract(1, "days")
                ],
                "Last 7 Days": [moment().subtract(6, "days"), moment()],
                "Last 30 Days": [moment().subtract(29, "days"), moment()],
                "Last 90 Days": [moment().subtract(89, "days"), moment()],
                "Last 365 Days": [moment().subtract(364, "days"), moment()],
                "Last Year": [
                    moment()
                        .subtract(1, "year")
                        .startOf("year"),
                    moment()
                        .subtract(1, "year")
                        .endOf("year")
                ],
                "This Year": [moment().startOf("year"), moment().endOf("year")],
                "All Time": [moment("2018-09-01", "YYYY-MM-DD"), moment()],
                "Last Month": [
                    moment()
                        .subtract(1, "month")
                        .startOf("month"),
                    moment()
                        .subtract(1, "month")
                        .endOf("month")
                ],
                "This Month": [
                    moment().startOf("month"),
                    moment().endOf("month")
                ]
            }
        },
        rangeLabel: "All Time",
        range_duration: "",
        conditionItemAdminCopy: {
            timeline_type: "any_time",
            start_date: moment("2018-09-01", "YYYY-MM-DD"),
            end_date: moment().format("YYYY-MM-DD")
        }
    }),
    computed: {
        path: function() {
            return this.$route.path;
        }
    },
    mounted() {
        this.customOptions.startDate = moment(this.startDate, "YYYY-MM-DD");
        if (this.parentEl) {
            this.customOptions.parentEl = this.parentEl;
        }
        if (this.conditionItem) {
            if (this.conditionItem.timeline_type === "1") {
                this.setStartEnd(
                    moment().subtract(1, "days"),
                    moment().subtract(1, "days")
                );
            } else if (this.conditionItem.timeline_type === "7") {
                this.setStartEnd(moment().subtract(6, "days"), moment());
            } else if (this.conditionItem.timeline_type === "30") {
                this.setStartEnd(moment().subtract(29, "days"), moment());
            } else if (this.conditionItem.timeline_type === "90") {
                this.setStartEnd(moment().subtract(89, "days"), moment());
            } else if (this.conditionItem.timeline_type === "365") {
                this.setStartEnd(moment().subtract(364, "days"), moment());
            } else if (this.conditionItem.timeline_type === "last_year") {
                this.setStartEnd(
                    moment()
                        .subtract(1, "year")
                        .startOf("year"),
                    moment()
                        .subtract(1, "year")
                        .endOf("year")
                );
            } else if (this.conditionItem.timeline_type === "year") {
                this.setStartEnd(
                    moment().startOf("year"),
                    moment().endOf("year")
                );
            } else if (this.conditionItem.timeline_type === "last_month") {
                this.setStartEnd(
                    moment()
                        .subtract(1, "month")
                        .startOf("month"),
                    moment()
                        .subtract(1, "month")
                        .endOf("month")
                );
            } else if (this.conditionItem.timeline_type === "month") {
                this.setStartEnd(
                    moment().startOf("month"),
                    moment().endOf("month")
                );
            } else if (this.conditionItem.timeline_type === "custom") {
                let start_val = moment(
                    this.conditionItem.start_date,
                    "YYYY-MM-DD"
                );
                let end_val =
                    this.conditionItem.end_date === ""
                        ? moment()
                        : moment(this.conditionItem.end_date, "YYYY-MM-DD");
                this.setStartEnd(start_val, end_val);
            } else if (this.conditionItem.timeline_type === "any_time") {
                const end_date = moment(this.conditionItem.end_date).format(
                    "MM/DD/YYYY"
                );
                this.setStartEnd(this.conditionItem.start_date, end_date);
            } else {
                this.setStartEnd(
                    moment(this.startDate, "YYYY-MM-DD"),
                    moment()
                );
            }

            this.$nextTick(() => {
                this.changeLabel(this.conditionItem);
            });
        }
        this.$nextTick(() => {
            this.initDatePicker();
        });
    },
    methods: {
        initDatePicker() {
            const el = $(this.$el);
            el.daterangepicker(this.customOptions, (start, end, label) => {
                this.conditionItem.start_date = start.format("YYYY-MM-DD");
                this.conditionItem.end_date = end.format("YYYY-MM-DD");

                switch (label) {
                    case "Yesterday":
                        this.conditionItem.timeline_type = "1";
                        break;
                    case "Last 7 Days":
                        this.conditionItem.timeline_type = "7";
                        break;
                    case "Last 30 Days":
                        this.conditionItem.timeline_type = "30";
                        break;
                    case "Last 90 Days":
                        this.conditionItem.timeline_type = "90";
                        break;
                    case "Last 365 Days":
                        this.conditionItem.timeline_type = "365";
                        break;
                    case "Last Year":
                        this.conditionItem.timeline_type = "last_year";
                        break;
                    case "This Year":
                        this.conditionItem.timeline_type = "year";
                        break;
                    case "All Time":
                        this.conditionItem.timeline_type = "any_time";
                        break;
                    case "Last Month":
                        this.conditionItem.timeline_type = "last_month";
                        break;
                    case "This Month":
                        this.conditionItem.timeline_type = "month";
                        break;
                    case "Custom Range":
                        this.conditionItem.timeline_type = "custom";
                        break;
                }

                this.$emit("change-date-range-picker-value");
                this.changeLabel(this.conditionItem);
            });

            // change ranges' label
            var range = $(".ranges").find("ul li");
            var thisYear = moment().format("YYYY");
            var thisMonth = moment().format("MMMM");
            var lastYear = moment()
                .subtract(1, "year")
                .format("YYYY");
            var lastMonth = moment()
                .subtract(1, "month")
                .format("MMMM");

            range.map((k, elem) => {
                let range = elem.getAttribute("data-range-key");
                switch (range) {
                    case "Last Year":
                        elem.innerText = lastYear;
                        break;
                    case "This Year":
                        elem.innerText = thisYear;
                        break;
                    case "Last Month":
                        elem.innerText = lastMonth;
                        break;
                    case "This Month":
                        elem.innerText = thisMonth;
                        break;
                    case "Custom Range":
                        elem.innerHTML =
                            "Custom <i class='el-input__icon el-icon-arrow-right'></i>";
                        break;
                    // default:  return elem.innerText;
                }
            });
        },
        setStartEnd(start, end) {
            this.customOptions.startDate = start;
            this.customOptions.endDate = end;
            // this.$emit('dates', { start, end });
        },
        addToRanges(obj, key, value, index) {
            // Create a temp object and index variable
            let temp = {};
            let i = 0;

            // Loop through the original object
            for (let prop in obj) {
                if (obj.hasOwnProperty(prop)) {
                    // If the indexes match, add the new item
                    if (i === index && key && value) {
                        temp[key] = value;
                    }

                    // Add the current item in the loop to the temp obj
                    temp[prop] = obj[prop];

                    // Increase the count
                    i++;
                }
            }

            // If no index, add to the end
            if (!index && key && value) {
                temp[key] = value;
            }

            return temp;
        },
        changeLabel(newVal) {
            let labelAry = {
                "1": "Yesterday",
                "7": "Last 7 Days",
                "30": "Last 30 Days",
                "90": "Last 90 Days",
                "365": "Last 365 Days",
                last_year: "Last Year",
                year: "This Year",
                any_time: "All Time",
                last_month: "Last Month",
                month: "This Month",
                custom: "Custom Range"
            };
// console.log(newVal.start_date, newVal.end_date);
            this.rangeLabel = labelAry[newVal.timeline_type];
            this.$emit('dates', {start_date: newVal.start_date, end_date: newVal.end_date});
            let start_val = moment(newVal.start_date, "YYYY-MM-DD").format(
                "MMM D"
            );
            let end_val =
                newVal.end_date === ""
                    ? moment(moment(), "YYYY-MM-DD").format("MMM D, YYYY")
                    : moment(newVal.end_date, "YYYY-MM-DD").format(
                          "MMM D, YYYY"
                      );

            this.range_duration = `${start_val} - ${end_val}`;
        }
    },
    beforeDestroy() {
        $(this.$el)
            .daterangepicker("hide")
            .daterangepicker("destroy");
    },
    watch: {
        conditionItem(newVal, oldVal) {
            if (newVal.timeline_type !== oldVal.timeline_type) {
                this.changeLabel(newVal);

                let start_val = moment(newVal.start_date, "YYYY-MM-DD");
                let end_val =
                    newVal.end_date === ""
                        ? moment()
                        : moment(newVal.end_date, "YYYY-MM-DD");

                this.setStartEnd(start_val, end_val);
                this.$nextTick(() => {
                    this.initDatePicker();
                });
            }
        },
        path: function(newVal, oldVal) {
            if (newVal.includes("admin")) {
                this.changeLabel(this.conditionItemAdminCopy);
                const start = moment(
                    this.conditionItemAdminCopy.start_date
                ).format("MM/DD/YYYY");
                const end = moment(this.conditionItemAdminCopy.end_date).format(
                    "MM/DD/YYYY"
                );
                $(this.$el)
                    .data("daterangepicker")
                    .setStartDate(start);
                $(this.$el)
                    .data("daterangepicker")
                    .setEndDate(end);
            }
        }
    }
};
</script>

<style lang="less" scoped>
    .date-range-picker-container {
        .title {
            margin-right: 15px;
            align-self: center;
        }
    }
</style>