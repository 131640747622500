export default {
  setCollaborationLoading (state, val) {
    state.collaborations.isLoading = val
  },
  setCollaborators (state, { total, users }) {
    state.collaborations.total = total
    state.collaborations.users = users
    state.collaborations.isLoading = false
  }
}
