import axios from 'axios'
import { GET_PLANS_API } from '../../../config/endpoints'

export default {
  getPlans: ({ commit }) => new Promise((resolve, reject) => {
    axios
      .get(GET_PLANS_API)
      .then(({ data }) => {
        commit('setPlans', data)
        resolve(data)
      })
      .catch(reject)
  })
}
