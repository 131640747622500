<template>
    <div v-bind:class="mainClass">
        <div>
            <label class="control-label" for="login-email">Email address</label>

            <input :class="{'has-error': (errorsForm.emailInvalid || errorsForm.emailNotFound || errorsForm.emailExist)}"
                   class="form-control" id="login-email" placeholder="Enter email address" type="email" v-model="credentials.email"/>
        </div>
        <div v-if="isMobile">
            <label class="text-danger small" v-if="errorsForm.emailNotFound">
                <img alt class="warning-icon" src="../../../assets/img/auth/warning.svg"/>
                {{ errorsForm.email }}
            </label>

            <label class="text-danger small" v-if="errorsForm.emailExist">
                <img alt class="warning-icon" src="../../../assets/img/auth/warning.svg"/>
                {{ errorsForm.email }}
            </label>

            <label class="text-danger small" v-if="errorsForm.emailInvalid">
                <img alt class="warning-icon" src="../../../assets/img/auth/warning.svg"/>
                {{ errorsForm.invalidEmail }}
            </label>

            <label class="text-danger small" v-if="errorsForm.bemailEmpty">
                <img alt class="warning-icon" src="../../../assets/img/auth/warning.svg"/>
                {{ errorsForm.requireEmail}}
            </label>
        </div>
        <div v-else>
            <div  class="register-control-warning" v-if="errorsForm.emailNotFound || errorsForm.emailExist || errorsForm.emailInvalid || errorsForm.bemailEmpty">
                <label class="text-danger small" v-if="errorsForm.emailNotFound">
                    <img alt class="warning-icon" src="../../../assets/img/auth/warning.svg"/>
                    {{ errorsForm.email }}
                </label>

                <label class="text-danger small" v-if="errorsForm.emailExist">
                    <img alt class="warning-icon" src="../../../assets/img/auth/warning.svg"/>
                    {{ errorsForm.email }}
                </label>

                <label class="text-danger small" v-if="errorsForm.emailInvalid">
                    <img alt class="warning-icon" src="../../../assets/img/auth/warning.svg"/>
                    {{ errorsForm.invalidEmail }}
                </label>

                <label class="text-danger small" v-if="errorsForm.bemailEmpty">
                    <img alt class="warning-icon" src="../../../assets/img/auth/warning.svg"/>
                    {{ errorsForm.requireEmail}}
                </label>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "AuthEmailField",
        props: {
            credentials: Object,
            errorsForm: Object,
            mainClass: Object,
        },
        data: {
            isMobile: false
        },
        created() {
            this.mobileCheck();
        },

        methods: {
            mobileCheck() {
                let BC_onMobile = false;
                if (window.screen.availWidth <= 414 || /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                    BC_onMobile = true;
                }

                this.isMobile = BC_onMobile;
            }
        }
    };
</script>
