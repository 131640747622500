<template>
    <div class='popover-container' v-if='showModal'>
        <div class="upload-restriction-dialog">
            <BaseLoader
                :lightTheme='true'
                message='Please Wait..'
                :showLoader='loading'/>
            <div v-if='showLimitMessage'>
                <div class="warn-sign"><img src="../../../../static/img/exclamation.svg" alt=""></div>
                <p>Your current plan does not include {{ module }}.</p>
                <p>
                    Please upgrade to our premium plans to access this feature.
                </p>
                <el-button round class="restriction_dialog_btn" type="success" @click="showUpgradeOptions"> See Upgrade
                    Options
                </el-button>
            </div>
        </div>
    </div>
</template>

<style lang="less" scoped>
    .popover-container {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        z-index: 95;
        background: rgba(1, 26, 34, 0.575);
        backdrop-filter: blur(5px);
        .upload-restriction-dialog {
            font-size: 14px;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            z-index: 41000;
            p {
                color: #fff;
            }
        }

        .warn-sign {
            position: relative;
            width: 60px;
            height: 60px;
            text-align: center;
            left: 0;
            right: 0;
            margin: auto;
            bottom: 25px;
            img {
                max-width: 100%;
                object-fit: cover;
            }
        }
    }
</style>

<script>
export default {
    name: 'BasePlanRestriction',
    props: ['showModal'],
    data: () => ({
        loading: false,
        showLimitMessage: false,
        lifeTimePlanData: [],
        module: 'this feature',
    }),
    methods: {
        showUpgradeOptions()
        {
			this.$router.push({name: 'ChangeSubscriptionPlan', params: {'section': 'billing'}});
		},
        getPlanDetails()
        {
            // To get both stripe & lifetime plan details.
            // if (this.$store && this.$store.getters) {
            //     if (this.$store.getters.userInfo) {
            //         var id = this.$store.getters.userInfo.paykick_subscription_id;
            //         if (id == 'null' || id === null) {
            //             this.$emit('close');
            //             return false;
            //         }
            //     }
            // }
            this.loading = true;
            this.axios.get('restrictions/index')
                .then(response => {
                    this.loading = false;
                    if (response.data.lifetime) {
                        this.lifeTimePlanData = response.data.lifetime;
                    }
                })
                .catch(error => {
                    console.log(error);
                    this.loading = false;
                });
        }
    },
    mounted()
    {
        this.getPlanDetails();
    },
    watch: {
        lifeTimePlanData: {
            handler(data)
            {
                const routeName = this.$route.name.toLowerCase();
                const modules = data['modules'];
                const content = data['content'];
                const params = this.$route.params;

                if (modules.includes(routeName)) {
                    if (routeName == 'projectview.experimentation') {
                        this.module = 'Video Experimentation';

                    } else if (routeName == 'projectview.collaboration') {
                        this.module = 'collaboration';

                    } else if (routeName == 'subscriptionplan') {
                        if (params.section && params.section == 'privacy') {
                            if (modules.includes(params.section)) {
                                this.module = 'Global video settings';
                            } else {
                                this.$emit('close');
                                return false;
                            }
                        } else if (params.section && params.section == 'stage') {
                            if (modules.includes(params.section)) {
                                this.module = 'Stage Settings';
                            } else {
                                this.$emit('close');
                                return false;
                            }
                        } else {
                            this.module = 'User Settings';
                        }

                    }
                    else this.module = routeName;

                    this.showLimitMessage = true;

                } else if (params.section && modules.includes(params.section)) {
                    if (params.section == 'privacy') {
                        if (modules.includes(params.section)) {
                            this.module = 'Global video settings';
                        } else {
                            this.$emit('close');
                            return false;
                        }
                    } else {
                        this.module = params.section.replace('-', ' ');

                    }
                    this.showLimitMessage = true;

                } else if (routeName == 'projectview.analytics') {
                    if (!content.project_analytics) {
                        this.module = 'Project analytics';
                        this.showLimitMessage = true;

                    } else {
                        this.$emit('close');

                    }

                } else {
                    this.$emit('close');
                }
            },
            deep: true,
        }
    }
}
</script>