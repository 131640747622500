<template>
  <div class="users-main">
    <div class="user-header">
      <div class="left-side">
        <div class="title text-white">Edit {{ plan_name }}</div>
      </div>
    </div>

    <div class="relative mb-3">
      <el-row :gutter="40" class="m-top-3">
        <el-col :span="8" :xs="24">
          <div class="branding-logo-position">
            <div class="positions title">
              <span class="textUppercase text-white">Plan Name</span>
            </div>
            <div class="positions">
              <input
                class="form-control with_arrow_190"
                id="user-first-name"
                placeholder="Enter first name"
                type="text"
                v-model="plan_name"
              />
            </div>
            <span class="error" v-if="validation.type === 'plan_name'">
              <img
                alt
                class="warning-icon"
                src="../../assets/img/auth/warning.svg"
              />
              {{ validation.message }}</span
            >
          </div>

          <div class="branding-logo-position">
            <div class="positions title">
              <span class="textUppercase text-white">Plan Type</span>
            </div>
            <div class="positions">
              <el-row>
                <el-col :span="1">&nbsp;</el-col>
                <el-col :span="10" :xs="23">
                  <el-radio
                    label="1"
                    class="text-white"
                    v-model="plan_type"
                    :label="'free'"
                    @click="plan_type = 'free'"
                  >
                    Free
                  </el-radio>
                </el-col>
              </el-row>

              <el-row class="m-top-2">
                <el-col :span="1">&nbsp;</el-col>
                <el-col :span="10" :xs="23">
                  <el-radio
                    label="2"
                    class="text-white"
                    v-model="plan_type"
                    :label="'paid'"
                    @click="plan_type = 'paid'"
                  >
                    Paid
                  </el-radio>
                </el-col>
              </el-row>
            </div>
          </div>
          <div class="branding-logo-position" v-if="plan_type !== 'free'">
            <div class="positions title">
              <span class="textUppercase text-white">Recurring</span>
            </div>
            <div class="positions">
              <el-row>
                <el-col :span="1">&nbsp;</el-col>
                <el-col :span="10" :xs="23">
                  <el-radio
                    label="1"
                    class="text-white"
                    v-model="recurring"
                    :label="'never'"
                    @click="recurring = 'never'"
                  >
                    Never
                  </el-radio>
                </el-col>
              </el-row>

              <el-row class="m-top-2">
                <el-col :span="1">&nbsp;</el-col>
                <el-col :span="10" :xs="23">
                  <el-radio
                    label="2"
                    class="text-white"
                    v-model="recurring"
                    :label="'monthly'"
                    @click="recurring = 'monthly'"
                  >
                    Monthly
                  </el-radio>
                </el-col>
              </el-row>

              <el-row class="m-top-2">
                <el-col :span="1">&nbsp;</el-col>
                <el-col :span="10" :xs="23">
                  <el-radio
                    label="2"
                    class="text-white"
                    v-model="recurring"
                    :label="'annually'"
                    @click="recurring = 'annually'"
                  >
                    Annually
                  </el-radio>
                </el-col>
              </el-row>
            </div>
          </div>
          <div class="branding-logo-position" v-if="plan_type !== 'free'">
            <div class="positions title">
              <span class="textUppercase text-white">Free Trial</span>
            </div>
            <div class="positions">
              <el-row>
                <el-col :span="1">&nbsp;</el-col>
                <el-col :span="10" :xs="23">
                  <el-radio
                    class="text-white"
                    label="1"
                    v-model="free_trial"
                    :label="'none'"
                    @click="free_trial = 'none'"
                    >None
                  </el-radio>
                </el-col>
              </el-row>

              <el-row class="m-top-2">
                <el-col :span="1">&nbsp;</el-col>
                <el-col :span="10" :xs="23">
                  <el-radio
                    label="2"
                    class="text-white"
                    v-model="free_trial"
                    :label="'7_days_free'"
                    @click="free_trial = '7_days_free'"
                    >7 Days Free
                  </el-radio>
                </el-col>
              </el-row>

              <el-row class="m-top-2">
                <el-col :span="1">&nbsp;</el-col>
                <el-col :span="10" class="text-white" :xs="23">
                  <el-radio
                    label="2"
                    class="text-white"
                    v-model="free_trial"
                    :label="'14_days_free'"
                    @click="free_trial = '14_days_free'"
                    >14 Days Free
                  </el-radio>
                </el-col>
              </el-row>

              <el-row class="m-top-2">
                <el-col :span="1">&nbsp;</el-col>
                <el-col :span="10" :xs="23">
                  <el-radio
                    label="2"
                    class="text-white"
                    v-model="free_trial"
                    :label="'30_days_free'"
                    @click="free_trial = '30_days_free'"
                    >30 Days Free
                  </el-radio>
                </el-col>
              </el-row>
            </div>
          </div>

          <div class="branding-logo-position" v-if="plan_type !== 'free'">
            <div class="positions title">
              <span class="textUppercase text-white">FEE (US $)</span>
            </div>
            <div class="positions">
              <input
                class="form-control with_arrow_190"
                id="user-last-name"
                placeholder="Enter first name"
                type="text"
                v-model="fees"
              />
            </div>
            <span class="error" v-if="validation.type === 'fees'">
              <img
                alt
                class="warning-icon"
                src="../../assets/img/auth/warning.svg"
              />
              {{ validation.message }}</span
            >
          </div>

          <div class="branding-logo-position">
            <div class="positions title">
              <span class="textUppercase text-white">Video count</span>
            </div>
            <div class="positions">
              <input
                class="form-control with_arrow_190"
                id="user-last-name"
                placeholder="Enter first name"
                type="number"
                min="0"
                v-model="video_count"
              />
            </div>
          </div>

          <div class="branding-logo-position">
            <div class="positions title">
              <span class="textUppercase text-white">Video source</span>
            </div>
            <div class="positions text-white">
              <el-checkbox-group v-model="videoSource">
                <el-checkbox label="Vimeo" class="text-white"></el-checkbox>
                <el-checkbox label="Youtube" class="text-white"></el-checkbox>
                <el-checkbox label="Wistia" class="text-white"></el-checkbox>
                <el-checkbox label="MP4" class="text-white"></el-checkbox>
                <el-checkbox label="M3U8" class="text-white"></el-checkbox>
                <el-checkbox label="Live" class="text-white"></el-checkbox>
              </el-checkbox-group>
            </div>
          </div>

          <div class="branding-logo-position">
            <div class="positions title">
              <span class="textUppercase text-white">Player Skin</span>
            </div>
            <div class="positions text-white">
              <el-checkbox-group v-model="playerSkin">
                <el-checkbox label="AERO" class="text-white"></el-checkbox>
                <el-checkbox label="BOLD" class="text-white"></el-checkbox>
                <el-checkbox label="CLEAN" class="text-white"></el-checkbox>
                <el-checkbox label="MODERN" class="text-white"></el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
          <div class="branding-logo-position">
            <div class="positions title">
              <span class="textUppercase text-white">Interaction Tools</span>
            </div>
            <div class="positions text-white">
              <el-switch
                v-model="interaction_tools"
                active-color="#13ce66"
                inactive-color="#ff4949"
              >
              </el-switch>
            </div>
          </div>

          <div class="branding-logo-position">
            <div class="positions title">
              <span class="textUppercase text-white">Video Behaviors</span>
            </div>
            <div class="positions text-white">
              <el-switch
                v-model="video_behaviours"
                active-color="#13ce66"
                inactive-color="#ff4949"
              >
              </el-switch>
            </div>
          </div>

          <div class="branding-logo-position">
            <div class="positions title">
              <span class="textUppercase text-white"
                >Dynamic Video Funnels</span
              >
            </div>
            <div class="positions text-white">
              <el-switch
                v-model="dynamic_video_funnel"
                active-color="#13ce66"
                inactive-color="#ff4949"
              >
              </el-switch>
            </div>
          </div>

           <div class="branding-logo-position">
            <div class="positions title">
              <span class="textUppercase text-white">Add User Webhook</span>
            </div>
            <div class="positions text-white">
              <div>
                <input
                  class="form-control with_arrow_190"
                  id="user-first-name"
                  placeholder="Enter first name"
                  type="text"
                  v-model="add_user_webhook"
                />
                <span
                  class="error"
                  v-if="validation.type === 'add_user_webhook'"
                >
                  <img
                    alt
                    class="warning-icon"
                    src="../../assets/img/auth/warning.svg"
                  />
                  {{ validation.message }}</span
                >
              </div>
            </div>
          </div>

          <div class="branding-logo-position" >
            <div class="positions title">
              <span class="textUppercase text-white"
                >Add User Webhook Method</span
              >
            </div>
            <div class="positions text-white">
              <div>
                <el-select
                  v-model="add_user_webhook_method"
                  placeholder="Select"
                >
                  <el-option label="get" value="get"></el-option>
                  <el-option label="post" value="post"></el-option>
                </el-select>
              </div>
            </div>
          </div>

          <div class="branding-logo-position" v-if="add_user_webhook_method === 'post'">
            <div class="positions title">
              <span class="textUppercase text-white"
                >Add User Webhook Payload</span
              >
            </div>
            <div class="positions text-white">
              <div>
                <textarea
                  class="form-control with_arrow_190"
                  id="user-first-name"
                  placeholder="Enter first name"
                  type="text"
                  v-model="add_user_webhook_payload"
                />
                <span
                  class="error"
                  v-if="validation.type === 'add_user_webhook_payload'"
                >
                  <img
                    alt
                    class="warning-icon"
                    src="../../assets/img/auth/warning.svg"
                  />
                  {{ validation.message }}</span
                >
              </div>
            </div>
          </div>

          <div class="branding-logo-position">
            <div class="positions title">
              <span class="textUppercase text-white">Remove User Webhook</span>
            </div>
            <div class="positions text-white">
              <input
                class="form-control with_arrow_190"
                id="user-first-name"
                placeholder="Enter first name"
                type="text"
                v-model="remove_user_webhook"
              />
              <span
                class="error"
                v-if="validation.type === 'remove_user_webhook'"
              >
                <img
                  alt
                  class="warning-icon"
                  src="../../assets/img/auth/warning.svg"
                />
                {{ validation.message }}</span
              >
            </div>
          </div>

          <div class="branding-logo-position">
            <div class="positions title">
              <span class="textUppercase text-white"
                >Remove User Webhook Method</span
              >
            </div>
            <div class="positions text-white">
              <div>
                <el-select
                  v-model="remove_user_webhook_method"
                  placeholder="Select"
                >
                  <el-option label="get" value="get"></el-option>
                  <el-option label="post" value="post"></el-option>
                </el-select>
              </div>
            </div>
          </div>

          <div class="branding-logo-position" v-if="remove_user_webhook_method === 'post'">
            <div class="positions title">
              <span class="textUppercase text-white"
                >Remove User Webhook Payload</span
              >
            </div>
            <div class="positions text-white">
              <div>
                <textarea
                  class="form-control with_arrow_190"
                  id="user-first-name"
                  placeholder="Enter first name"
                  type="text"
                  v-model="remove_user_webhook_payload"
                />
                <span
                  class="error"
                  v-if="validation.type === 'remove_user_webhook_payload'"
                >
                  <img
                    alt
                    class="warning-icon"
                    src="../../assets/img/auth/warning.svg"
                  />
                  {{ validation.message }}</span
                >
              </div>
            </div>
          </div>

        </el-col>
      </el-row>
    </div>
    <!--        <el-button-->
    <!--                class="btn btn-primary add-power-player-button" @click="savePlan()"-->
    <!--        > Update-->
    <!--            Plan-->
    <!--        </el-button>-->

    <el-button
      type="custom"
      class="button__cancle"
      @click="$router.push('/whitelabel/plans')"
      >Cancel
    </el-button>
    <el-button type="custom" class="button__upload" @click="savePlan"
      >Update Plan
    </el-button>
  </div>
</template>


<script>
import SearchQuery from "../../components/Project/AllProjects/Components/SearchQuery";
import AuthEmailField from "../Auth/Items/AuthEmailField.vue";
import PasswordChecker from "./PasswordChecker.vue";

export default {
  name: "WhiteBoardEditPlans",
  components: {},
  data() {
    return {
      plan_name: "",
      fees: "0",
      plan_type: "paid",
      recurring: "monthly",
      free_trial: "7_days_free",
      video_count: 0,
      videoSource: [],
      playerSkin: [],
      interaction_tools: true,
      video_behaviours: true,
      dynamic_video_funnel: true,
      validation: {
        message: "",
        type: "",
      },

         add_user_webhook: "",
      add_user_webhook_method: "post",
      add_user_webhook_payload: null,

      remove_user_webhook: null,
      remove_user_webhook_method: "post",
      remove_user_webhook_payload: null,
    };
  },
  computed: {},
  created() {
    this.getPlan();
  },

  methods: {
     validURL(str) {
      var pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      ); // fragment locator
      return !!pattern.test(str);
    },
    isValidJson(json) {
      try {
        JSON.parse(json);
        return true;
      } catch (e) {
        return false;
      }
    },
    getPlan() {
      let post_data = {
        url: "/whitelabel/plan/" + this.$route.params.id,
      };

      var $this = this;
      this.$store
        .dispatch("post", { ...post_data })
        .then((response) => {
          $this.plan_name = response.data.plan.plan_name;
          $this.fees = response.data.plan.cost;
          $this.plan_type = response.data.plan.plan_type;
          $this.recurring = response.data.plan.recurring;
          $this.free_trial = response.data.plan.free_trial;

      $this.add_user_webhook = response.data.plan.add_user_webhook;
      $this.add_user_webhook_method = response.data.plan.add_user_webhook_method;
      $this.add_user_webhook_payload = response.data.plan.add_user_webhook_payload;

      $this.remove_user_webhook = response.data.plan.remove_user_webhook;
      $this.remove_user_webhook_method = response.data.plan.remove_user_webhook_method;
      $this.remove_user_webhook_payload = response.data.plan.remove_user_webhook_payload;


            if(typeof response.data.plan.feature !== 'undefined' && response.data.plan.feature !== null){

              $this.video_count = response.data.plan.feature.video_count;
              $this.videoSource = JSON.parse(response.data.plan.feature.video_source);
              $this.playerSkin = JSON.parse(response.data.plan.feature.player_skin);
              $this.interaction_tools = response.data.plan.feature.interaction_tools === 'true' ? true : false ;
              $this.video_behaviours = response.data.plan.feature.video_behaviours === 'true' ? true : false ;
              $this.dynamic_video_funnel = response.data.plan.feature.dynamic_video_funnel === 'true' ? true : false ;

            }

        })
        .catch((error) => {});
    },
    savePlan() {
      if (this.plan_name === "") {
        this.validation.message = "Please enter plan name";
        this.validation.type = "plan_name";

        return false;
      } else if (this.fees === "0") {
        this.validation.message = "Please enter fees";
        this.validation.type = "fees";

        return false;
      } else if (
        this.add_user_webhook === "" ||
        this.add_user_webhook === null
      ) {
        this.validation.message = "Please enter url";
        this.validation.type = "add_user_webhook";

        return false;
      } else if (
        this.add_user_webhook !== "" ||
        this.add_user_webhook !== null
      ) {
        var valid = this.validURL(this.add_user_webhook);

        if (!valid) {
          this.validation.message = "Please enter valid url";
          this.validation.type = "add_user_webhook";

          return false;
        }
      } else if (
        this.add_user_webhook_method === "post" &&
        (this.add_user_webhook_payload === null ||
          this.add_user_webhook_payload === "")
      ) {
        this.validation.message = "Please enter payload";
        this.validation.type = "add_user_webhook_payload";

        return false;
      } else if (
        this.add_user_webhook_payload !== "" ||
        this.add_user_webhook_payload !== null
      ) {
        var valid = this.validURL(this.add_user_webhook_payload);
        if (!valid) {
          this.validation.message = "Please enter valid payload";
          this.validation.type = "add_user_webhook_payload";

          return false;
        }
      } else if (
        this.remove_user_webhook === "" ||
        this.remove_user_webhook === null
      ) {
        this.validation.message = "Please enter url";
        this.validation.type = "remove_user_webhook";

        return false;
      } else if (
        this.remove_user_webhook !== "" ||
        this.remove_user_webhook !== null
      ) {
        var valid = this.validURL(this.remove_user_webhook);

        if (!valid) {
          this.validation.message = "Please enter valid url";
          this.validation.type = "remove_user_webhook";

          return false;
        }
      } else if (
        this.remove_user_webhook_method === "post" &&
        (this.remove_user_webhook_payload === null ||
          this.remove_user_webhook_payload === "")
      ) {
        this.validation.message = "Please enter payload";
        this.validation.type = "remove_user_webhook_payload";

        return false;
      } else if (
        this.remove_user_webhook_payload !== "" ||
        this.remove_user_webhook_payload !== null
      ) {
        var valid = this.validURL(this.remove_user_webhook_payload);
        if (!valid) {
          this.validation.message = "Please enter valid payload";
          this.validation.type = "remove_user_webhook_payload";

          return false;
        }
      } else {
        this.validation.message = "";
        this.validation.type = "";
      }

      let post_data = {
        url: "/whitelabel/plan/update/" + this.$route.params.id,
        data: {
          plan_name: this.plan_name,
          fees: this.fees,
          plan_type: this.plan_type,
          recurring: this.recurring,
          free_trial: this.free_trial,
          video_count: this.video_count,
          videoSource: this.videoSource,
          playerSkin: this.playerSkin,
          interaction_tools: this.interaction_tools,
          video_behaviours: this.video_behaviours,
          dynamic_video_funnel: this.dynamic_video_funnel,

          add_user_webhook: this.add_user_webhook,
          add_user_webhook_method: this.add_user_webhook_method,
          add_user_webhook_payload: this.add_user_webhook_payload,
          remove_user_webhook: this.remove_user_webhook,
          remove_user_webhook_method: this.remove_user_webhook_method,
          remove_user_webhook_payload: this.remove_user_webhook_payload,
        },
      };

      this.$store
        .dispatch("post", { ...post_data })
        .then((response) => {
          if (response.data.result === "success") {
            // $router.push('/white-label/users')
            this.$router.push({ name: "WhiteBoardPlans" });
          }
        })
        .catch((error) => {});
    },
  },
};
</script>

<style lang="less">
.error {
  color: red;
}

.users-main {
  padding: 40px;
  overflow: auto;
  top: 55px;
  position: absolute;
  width: 100%;
}

.user-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  .left-side {
    .title {
      font-weight: bold;
      font-size: 22px;
    }

    .total {
      margin-left: 15px;
      font-weight: normal;
    }
  }

  .el-button--custom {
    font-weight: bolder;
  }

  .right-side {
    display: flex;
    align-items: center;

    .text-uppercase {
      font-weight: 700;
    }

    div {
      margin-right: 20px;
    }

    .button__upload {
      padding: 10px 25px 9px;
      background-color: var(--buttonThemeColor);
      border: none;
      font-size: 16px;
      line-height: 19px;
      color: white;
      border-radius: 5px;
    }
  }
}

.branding-logo-position {
  margin-top: 30px;

  .positions {
    display: flex;
    margin-top: 15px;

    .el-row {
      margin-right: 15px;
    }
  }
}

.user-list-table {
  min-width: 900px;

  p {
    margin-bottom: 5px;
  }

  .table-header {
    display: flex;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .contact-row {
    width: 19%;

    p.name {
      font-weight: bold;
    }

    p {
      margin-bottom: 5px;
      color: #2c3e50;
    }
  }

  .status-row {
    width: 10%;
  }

  .plan-row {
    width: 10%;
  }

  .joined-row {
    width: 10%;
  }

  .last-activity-row {
    width: 17%;
  }

  .asset-row {
    width: 17%;
  }

  .table-body {
    .plan-row,
    .joined-row,
    .last-activity-row,
    .asset-row {
      color: #485050cf;
    }
  }

  .action-row {
    width: 17%;
    display: inline-grid;

    button {
      background: none;
      border: 0px;
      text-align: left;
    }
  }

  .table-row {
    display: flex;
    padding-bottom: 30px;

    .actions {
      display: block;
    }
  }
}

.color-red {
  color: #d93636;
}

.color-pink {
  color: #ea00d8;
}

.color-green {
  color: #0bd603;
}

.color-blue {
  color: #0bacdb;
}

.textUppercase {
  text-transform: uppercase;
}
</style>

