const languages = [
    {
        "name": "English",
        "code": "en",
    },
    {
        "name": "Spanish",
        "code": "es",
    },
    {
        "name": "French",
        "code": "fr",
    },
    {
        "name": "Chinese",
        "code": "zh",
    },
    {
        "name": "Italian",
        "code": "it",
    },
    {
        "name": "Vietnamese",
        "code": "vi",
    },
    {
        "name": "Portuguese",
        "code": "pt",
    },
    {
        "name": "Arabic",
        "code": "ar",
    },
    {
        "name": "German",
        "code": "de",
    },
    {
        "name": "Hindi",
        "code": "hi",
    },
    {
        "name": "Japanese",
        "code": "jv",
    },
    {
        "name": "Korean",
        "code": "ko",
    },
    {
        "name": "Romanian",
        "code": "ro",
    },
    {
        "name": "Polish",
        "code": "pl",
    },
    {
        "name": "Irish",
        "code": "ga",
    },
    {
        "name": "Dutch",
        "code": "nl",
    },
    {
        "name": "Hebrew",
        "code": "he",
    },
    {
        "name": "Javanese",
        "code": "jv",
    },
    {
        "name": "Indonesian",
        "code": "id",
    },
    {
        "name": "Greek",
        "code": "el",
    },
    {
        "name": "Filipino",
        "code": "tl",
    },
    {
        "name": "Czech",
        "code": "cs",
    },
    {
        "name": "Thai",
        "code": "th",
    },
    {
        "name": "Urdu",
        "code": "ur",
    },

    {
        "name": "Malay",
        "code": "ms",
    },

    {
        "name": "Persian",
        "code": "fa",
    },
    {
        "name": "Finnish",
        "code": "fi",
    },
    {
        "name": "Turkish",
        "code": "tr",
    },

    {
        "name": "Danish",
        "code": "da",
    },
    {
        "name": "Swedish",
        "code": "sv",
    },
];

export default languages;



