<template>
  <div class="card-container">
    <h2 class="whitelable-page-heading">Dashboard</h2>
    <el-row :gutter="20">
      <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
        <div class="grid-content text-center text-uppercase">
          <p>{{ cardData.users }}</p>
          <p>of {{ cardData.totalUsers }} users</p>
          <!-- <el-progress
            :text-inside="true"
            :show-text="false"
            :percentage="userPercentage"
          ></el-progress> -->
          <!-- <div class="el-progress-bar">
            <div class="el-progress-bar__outer">
              <div
                class="el-progress-bar__inner"
                :style="{ width: userPercentage }"
              ></div>
            </div>
          </div> -->
        </div>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
        <!-- <div class="grid-content text-center text-uppercase">
          <p>{{ cardData.language }}</p>
          <p>of 0 languages</p>

          <div class="el-progress-bar">
            <div class="el-progress-bar__outer" style="height: 6px">
              <div
                class="el-progress-bar__inner"
                :style="{ width: lanPercentage }"
              ></div>
            </div>
          </div>
        </div> -->
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
        <!-- <div class="grid-content text-center text-uppercase upgrade-plan">
          <p class="">upgrade your whitelabel</p>
        </div> -->
      </el-col>
      <!-- </el-row>
    <el-row :gutter="20"> -->
      <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
        <div class="grid-content text-center text-uppercase">
          <p>{{ cardData.video }}</p>
          <p>videos</p>
        </div>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
        <div class="grid-content text-center text-uppercase">
          <p>{{ cardData.impression }}</p>
          <p>impression</p>
        </div>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
        <div class="grid-content text-center text-uppercase">
          <p>{{ cardData.view }}</p>
          <p>views</p>
        </div>
      </el-col>
    </el-row>
    <div>
      <el-col class="condition-content second" :span="2" :xs="24">
        <span class="size-14">Timeline:</span>
        <custom-date-range-picker
          :conditionItem="conditionTime"
          :start-date="$store.state.start_date"
          :show-range="true"
          v-on:change-date-range-picker-value="listData"
          @click.native="closeOpenedDropdowns()"
          class="border-contentt"
          parent-el=".analytics-area"
        ></custom-date-range-picker>
      </el-col>
    </div>
    <div style="margin-top:50px">
      <div></div>

      <div class="chart">
        <chart
          v-if="load"
          :chartData="chartData"
          :chartOptions="chartOptions"
          :chartType="chartType"
        />
      </div>

      <div class="videochart">
        <video-chart
          v-if="loadvideoChart"
          :chartData="videochartData"
          :chartOptions="videochartOptions"
          :chartType="videochartType"
        />
      </div>

      <div class="viewchart">
        <view-chart
          v-if="loadviewChart"
          :chartData="viewchartData"
          :chartOptions="viewchartOptions"
          :chartType="viewchartType"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Chart from "../dashboard/Chart.vue";
import VideoChart from "../dashboard/VideoChart.vue";
import ViewChart from "../dashboard/ViewChart.vue";
import CustomDateRangePicker from "../../Contacts/Items/CustomDateRangePicker";
import $ from "jquery";
const cur_date = new Date();
export default {
  components: { Chart, VideoChart, ViewChart, CustomDateRangePicker },
  data() {
    return {
      cardData: {
        users: 0,
        totalUsers: 0,
        impression: 0,
        view: 0,
        video: 0,
        language: 0,
      },

      cardDatas: [
        {
          cardNumber: 3295,
          text: "of",
          totalNumber: 5000,
          otherMessage: "users",
        },

        {
          cardNumber: 3,
          text: "of",
          totalNumber: 5,
          otherMessage: "language",
        },

        {
          cardNumber: null,
          text: "UPGRADE YOUR WHITELABEL",
          totalNumber: null,
          otherMessage: null,
        },

        {
          cardNumber: 50093,
          text: "videos",
          totalNumber: null,
          otherMessage: null,
        },
        {
          cardNumber: 50093,
          text: "Impression",
          totalNumber: null,
          otherMessage: null,
        },
        {
          cardNumber: 3293940,
          text: "views",
          totalNumber: null,
          otherMessage: null,
        },
      ],
      load: false,
      loadvideoChart: false,
      loadviewChart: false,
      chartType: "line",
      videochartType: "line",
      viewchartType: "line",
      chartData: {
        // labels: ['Apr 16 2022', 'Apr 17 2022', 'Apr 18 2022', 'Apr 19 2022', 'Apr 20 2022', 'Apr 21 2022', 'Apr 22 2022', 'Apr 23 2022', 'Apr 24 2022', 'Apr 25 2022', 'Apr 26 2022', 'Apr 27 2022', 'Apr 28 2022', 'Apr 29 2022', 'Apr 30 2022', 'May 01 2022', 'May 02 2022', 'May 03 2022', 'May 04 2022', 'May 05 2022'],
        labels: [],
        datasets: [
          //         {
          //             label: "Users",
          //             data: [0, 2, 0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0],
          //             backgroundColor: "rgba(224, 248, 255, 0.4)",
          //             borderColor: "#5cddff",
          //             lineTension: 0,
          //             pointBackgroundColor: "#5cddff",
          // }
          // {
          //   label: "Last week",
          //   data: [10, 25, 3, 25, 17, 4, 9],
          //   backgroundColor: "rgba(241, 225, 197, 0.4)",
          //   borderColor: "#ffc764",
          //   lineTension: 0,
          //   pointBackgroundColor: "#ffc764",
          // },
          //   {
          //   label: "next week",
          //   data: [2, 25, 3, 25, 74, 4, 95],
          //   backgroundColor: "rgba(241, 225, 197, 0.4)",
          //   borderColor: "#ffc764",
          //   lineTension: 0,
          //   pointBackgroundColor: "#ffc764",
          // },
        ],
      },
      videochartData: {
        labels: [],
        datasets: [],
      },
      viewchartData: {
        labels: [],
        datasets: [],
      },
      chartOptions: {
        scales: {
          xAxes: [
            {
              stacked: true,
              gridLines: { display: false },
            },
          ],
          yAxes: [
            {
              ticks: {
                stepSize: 1,
                callback: function (value, index, values) {
                  if (value % Math.round(values[0] / 6) == 0) {
                    return value;
                  } else if (value === 0) {
                    return value;
                  }
                },
              },
              // stacked: true
            },
          ],
        },
        maintainAspectRatio: false,
        legend: {
          labels: {
            boxWidth: 10,
          },
          position: "top",
        },
        animation: {
          duration: 2000,
          easing: "easeInOutQuart",
        },
      },
      videochartOptions: {
        scales: {
          xAxes: [
            {
              stacked: true,
              gridLines: { display: false },
            },
          ],
          yAxes: [
            {
              ticks: {
                stepSize: 1,
                callback: function (value, index, values) {
                  if (value % Math.round(values[0] / 6) == 0) {
                    return value;
                  } else if (value === 0) {
                    return value;
                  }
                },
              },
              // stacked: true
            },
          ],
        },
        maintainAspectRatio: false,
        legend: {
          labels: {
            boxWidth: 10,
          },
          position: "top",
        },
        animation: {
          duration: 2000,
          easing: "easeInOutQuart",
        },
      },
      viewchartOptions: {
        scales: {
          xAxes: [
            {
              stacked: true,
              gridLines: { display: false },
            },
          ],
          yAxes: [
            {
              ticks: {
                stepSize: 1,
                callback: function (value, index, values) {
                  if (value % Math.round(values[0] / 6) == 0) {
                    return value;
                  } else if (value === 0) {
                    return value;
                  }
                },
              },
              // stacked: true
            },
          ],
        },
        maintainAspectRatio: false,
        legend: {
          labels: {
            boxWidth: 10,
          },
          position: "top",
        },
        animation: {
          duration: 2000,
          easing: "easeInOutQuart",
        },
      },
      conditionTime: {
        timeline_type: "30",
        start_date: `${cur_date.getFullYear()}-${cur_date.getUTCMonth()}-${cur_date.getDate()}`,
        end_date: `${cur_date.getFullYear()}-${
          cur_date.getUTCMonth() + 1
        }-${cur_date.getDate()}`,
      },
    };
  },

  created() {
    this.listData();
  },
  methods: {
    closeOpenedDropdowns() {
      this.projectPopOver = false;
      this.videoPopOver = false;
      this.advanceFilterPop = [false, false, false];
      // setTimeout(() => {
      //   this.changeScrollAutoHeight(false, "", "video-popover", 0);
      //   this.changeScrollAutoHeight(false, "", "project-popover", 0);
      // }, 10);
    },

    changeScrollAutoHeight(popover, h, changedContent, key) {
      let popoverH = $(`.${changedContent}`)[key].offsetHeight;
      popover === true && window.innerWidth <= 414
        ? $(".scroll_auto").css("height", `calc(${popoverH}px + ${h}px)`)
        : $(".scroll_auto").css("height", "auto");
    },

    listData() {
      let post_data = {
        url: "/whitelabel/dashboard",
        data:{
           start_date: this.conditionTime.start_date,
                                end_date: this.conditionTime.end_date,
        }
      };

      this.$store
        .dispatch("post", { ...post_data })
        .then((response) => {
          console.log(response.data.data.videos);
          this.cardData.video = response.data.data.videos;
          this.cardData.view = response.data.data.views;
          this.cardData.impression = response.data.data.impressions;
          this.cardData.users = response.data.data.user;
          this.cardData.totalUsers = response.data.data.user;

          // console.log(this.chartData.labels, response.data.chart.userData.label, response.data.chart.userData.userData);

          this.chartData.labels = response.data.chart.userData.label;
          // console.log(this.chartData.labels, 'this.chartData.labels');
          this.chartData.datasets=[];
          this.chartData.datasets.push({
            label: "Users",
            data: response.data.chart.userData.userData,
            backgroundColor: "rgba(224, 248, 255, 0.4)",
            borderColor: "#5cddff",
            lineTension: 0,
            pointBackgroundColor: "#5cddff",
          });

          this.videochartData.labels = response.data.chart.videoData.label;
          this.videochartData.datasets = [];
          this.videochartData.datasets.push({
            label: "Videos",
            data: response.data.chart.videoData.videoData,
            backgroundColor: "rgba(224, 248, 255, 0.4)",
            borderColor: "#5cddff",
            lineTension: 0,
            pointBackgroundColor: "#5cddff",
          });

          this.viewchartData.labels = response.data.chart.viewData.label;
          this.viewchartData.datasets = [];
          this.viewchartData.datasets.push({
            label: "Views",
            data: response.data.chart.viewData.viewData,
            backgroundColor: "rgba(224, 248, 255, 0.4)",
            borderColor: "#5cddff",
            lineTension: 0,
            pointBackgroundColor: "#5cddff",
          });

          this.load = true;
          this.loadvideoChart = true;
          this.loadviewChart = true;
          console.log(this.chartData.datasets);
        })
        .catch((error) => {});
    },
  },
  computed: {
    userPercentage() {
      return (
        Math.floor(
          (this.cardDatas[0].cardNumber / this.cardDatas[0].totalNumber) * 100
        ) + "%"
      );
    },
    lanPercentage() {
      return (
        Math.floor(
          (this.cardDatas[1].cardNumber / this.cardDatas[1].totalNumber) * 100
        ) + "%"
      );
    },
  },
};
</script>

<style lang="less" scoped>
.card-container {
  padding: 50px 30px;
  .el-row {
    margin: 0 !important;
    max-width: 1400px;
  }
  .condition-content{
    display: flex;
    align-items: center;
    margin-left: 15px;
  }
  .grid-content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #f9f9fc;
    border-radius: 7px;
    margin-bottom: 20px;
    padding: 15px;
    min-height: 170px;
    max-width: 420px;
    p {
      font-size: 22px;
      color: #309ce3;
    }
    p:first-child {
      font-size: 60px;
      font-weight: bold;
      color: #309ce3;
    }
    .el-progress-bar {
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 0;
    }
    .el-progress-bar__outer {
      background: #0bd603;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-left-radius: 50px;
      height: 15px !important;
    }
    .el-progress-bar__inner {
      background: #d93636;
      border-radius: 0;
    }
  }
  .upgrade-plan {
    border-radius: 5px;
    background-image: linear-gradient(114deg, #094077 0%, #d93636 100%);
    p {
      font-size: 35px !important;
    }
  }

  .videochart,
  .viewchart,
  .chart {
       background-color: #f9f9fc;
       margin: 10px;
      margin-top: 15px;
      border-radius: 10px;
      .card-container{
        padding: 30px;
      }
  }
}

@media (max-width: 1440px) {
  .card-container {
    min-height: 150px;

    .grid-content.upgrade-plan {
      p {
        font-size: 22px !important;
      }
    }
  }
}

@media (max-width: 960px) {
  .card-container {
    min-height: 200px;

    .grid-content.upgrade-plan {
      p {
        font-size: 20px !important;
      }
    }
  }
}
</style>
