<template>
  <div class="card-container">
    <canvas id="videoChart"></canvas>
  </div>
</template>

<script>

import Chart from "chart.js";

export default {
props:[
    'chartType',
    'chartData',
    'chartOptions',
  ],
  data() {
    return {
     
    };
  },
mounted(){
  let {chartType,chartData,chartOptions} = this;
  this.chartConstructor(chartType, chartData, chartOptions);
},
  created() {
    
  },
  methods: {
    chartConstructor(chartType, chartData, chartOptions) {
    const chartElement = document.querySelector("canvas#videoChart");
    const chart = new Chart(chartElement, {
    type: chartType,
    data: chartData,
    options: chartOptions,
  });
},
  },
  computed: {

  },
   watch: {
    "chartData.datasets": {
      handler(newVal, oldVal) {
        // here having access to the new and old value
        this.chartConstructor(
          this.chartType,
          this.chartData,
          this.chartOptions
        );
        console.log("dskjjfffffffffffffffffhhhkjjjjjjjjjjjjjjjjjjjjjjjjjjj");
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="less" scoped>

</style>
