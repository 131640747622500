<template>
    <div class="users-main">
        <div class="user-header">
            <div class="left-side">
                <div class="title text-white">
                    Move Users
                </div>
            </div>

        </div>


        <div class="relative mb-3">
            <el-row :gutter="40" class="m-top-3">
                <el-col :span="8" :xs="24">

                    <div class="branding-logo-position">

                        <div class="positions">
                            <input
                                    class="form-control with_arrow_190"
                                    id="user-first-name"
                                    placeholder="Enter first name"
                                    type="text"
                                    v-model="plan.plan_name"
                            />
                        </div>
                    </div>

                    <div class="branding-logo-position">
                        <div class="positions title">
                            <span class="textUppercase text-white">Users</span>
                        </div>
                        <div class="positions">
                            <div class="planUsers text-white" v-if="plan.users_count == 0">


                                No Users

                            </div>
                            <div class="planUsers" v-else>
                                <!--                                <el-checkbox-group v-model="selectedUsers">-->

                                <!--                              <div v-for="user in plan.users" class="listUsersForMove">-->
                                <!--                                  -->
                                <!--                                      <el-checkbox class="custom-el-checkbox-green" :key="user.id"-->
                                <!--                                                   >     <div>{{user.name}}</div>-->
                                <!--                                          <div>{{user.email}}</div></el-checkbox>-->

                                <!--                                 -->
                                <!--                              -->

                                <!--                              </div>-->
                                <div class="userHeader">

                                    <el-checkbox @change="checkAll($event)" v-model="allCheck"
                                                 class="custom-el-checkbox-green textNormal">{{selectedUsers.length}} of {{plan.users.length}} users selected
                                    </el-checkbox>

                                    <div>
                                        <input
                                                class="form-control with_arrow_190"
                                                placeholder="Search users"
                                                type="text"
                                                v-model="keywords"
                                                v-on:keyup.enter="filterUsers(keywords)"
                                        />
                                    </div>
                                </div>
                                <div class="userBody">
                                <el-checkbox-group v-model="selectedUsers">
                                    <div v-for="user in users" class="moveUserList text-white">

                                        <el-checkbox class="custom-el-checkbox-green textNormal text-white"
                                                     :label="user.id" :key="user.id">{{user.name}}
                                        </el-checkbox>
                                        <span class="userInfo text-white">{{user.email}}</span>
                                        <span class="userInfo text-white">Joined {{changeDateFormate(user.created_at)}}</span>
                                    </div>
                                </el-checkbox-group>
                                </div>

                                <!--                                </el-checkbox-group>-->

                            </div>
                        </div>
                    </div>

                </el-col>
                <el-col :span="8" :xs="24">

                    <div class="branding-logo-position">

                        <div class="positions">
                            <el-select
                                    popper-class="custom_select_pop with_arrow_190"
                                    placeholder="Select"
                                    v-model="new_plan_id">
                                <el-option v-for="item in this.plans" :key="item.id"
                                           :label="item.plan_name" :value="item.id"></el-option>
                            </el-select>
                        </div>
                        <span class="error" v-if="validation.type === 'new_plan_id'">
                         <img alt class="warning-icon" src="../../assets/img/auth/warning.svg"/>
                        {{validation.message}}</span>
                    </div>

                </el-col>
            </el-row>
        </div>

        <el-button
                type="custom"
                class="button__cancle"
                @click="$router.push('/whitelabel/plans')"
        >Cancel
        </el-button>
        <el-button
                type="custom"
                class="button__upload"
                @click="moveUsers"
        >Update
            Plan
        </el-button>

    </div>
</template>


<script>


    export default {
        name: 'WhiteBoardMovePlans',
        components: {},
        data() {
            return {

                plan: {},
                plans: {},
                new_plan_id: '',
                selectedUsers: [],
                validation: {
                    message: '',
                    type: '',
                },
                keywords: '',
                users: {},

            };
        },
        computed: {},
        created() {
            this.getPlan();
            this.getPlans();
        },
        watch: {


            selectedUsers: function (oldVal, newVal) {
                if (newVal.length === this.plan.users.length()) {

                    // console.log(oldVal, newVal);

                }
            }
        },
        methods: {
            changeDateFormate(date){
                var myArr = date.split(" ");
var data = myArr[0].split("-");


                var  months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
                var d = new Date(myArr[0]);
                var monthName=months[d.getMonth()];

return monthName+ ' '+ data[2] + ', '+ data[0];

            },

            filterUsers(keywords){

                console.log(keywords, 'keywords');


                if(this.keywords == ''){
                    this.users = this.plan.users;
                    return
                }

                this.users = this.plan.users.filter(video => {
                    if (this.keywords) {
                        return video.name
                            .toLowerCase()
                            .search(this.keywords.toLowerCase()) !== -1
                            ? true
                            : false;
                    }
                    return true;
                });
            },


            moveUsers() {


                if (this.new_plan_id === '') {
                    this.validation.message = 'Please select plan';
                    this.validation.type = 'new_plan_id';

                    return false;
                } else {
                    this.validation.message = '';
                    this.validation.type = '';
                }

                let post_data = {
                    url: "/whitelabel/plan-move",
                    data: {
                        new_plan_id: this.new_plan_id,
                        user_ids: this.selectedUsers,
                        existing_plan_id: this.$route.params.id,
                    }
                };


                this.$store.dispatch("post", {...post_data})
                    .then(response => {
                        this.$router.push({name: 'WhiteBoardPlans'})
                    })
                    .catch(error => {

                    });
            },
            checkAll($event) {
                this.selectedUsers = [];
                if ($event) {

                    var $this = this;
                    this.users.forEach(function (el, i) {
                        $this.selectedUsers.push(el.id)
                    })
                    // console.log(this.selectedUsers);
                }
            },
            getPlans() {
                let post_data = {
                    url: "/whitelabel/plans",
                     data:{
                        all: true
                    }
                };


                this.$store.dispatch("post", {...post_data})
                    .then(response => {
                        this.plans = response.data.plans;

                    })
                    .catch(error => {

                    });
            },
            getPlan() {

                let post_data = {
                    url: "/whitelabel/plan/" + this.$route.params.id,

                };

                var $this = this;
                this.$store.dispatch("post", {...post_data})
                    .then(response => {
                        this.plan = response.data.plan;
                        this.users = response.data.plan.users;

                    })
                    .catch(error => {

                    });
            },

        },

    };
</script>

<style lang="less">
    .textNormal{
        font-weight: normal !important;
    }
    .planUsers {
.userInfo{
margin-left: 15px;
}
        .userBody{

            border: 1px solid #d0d5d5;
            padding: 15px;
        }

        .userHeader{
            padding: 15px;
            background: #ccc;
            display: flex;

            input {
                border-radius: 25px;
                height: 33px;
                margin-left: 15px;
            }
        }

        .moveUserList {
label{
    max-width: 100px;
    width: 100px;
    text-overflow: ellipsis;
    overflow: hidden;

}

            .userInfo{
                max-width: 300px;
                min-width: 300px;
                width: 300px;
                text-overflow: ellipsis;
                overflow: hidden;

            }

        }

    }


    .error {
        color: red;
    }

    .listUsersForMove {
        display: flex;
    }

    .users-main {
        padding: 40px;
        overflow: auto;
        top: 55px;
        position: absolute;
        width: 100%;
    }

    .user-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;

        .left-side {
            .title {
                font-weight: bold;
                font-size: 22px;
            }

            .total {
                margin-left: 15px;
                font-weight: normal;
            }
        }

        .el-button--custom {
            font-weight: bolder;
        }

        .right-side {
            display: flex;
            align-items: center;

            .text-uppercase {
                font-weight: 700;
            }

            div {
                margin-right: 20px;
            }

            .button__upload {
                padding: 10px 25px 9px;
                background-color: var(--buttonThemeColor);
                border: none;
                font-size: 16px;
                line-height: 19px;
                color: white;
                border-radius: 5px;
            }
        }


    }

    .branding-logo-position {
        margin-top: 30px;

        .positions {
            display: block !important;
            margin-top: 15px;

            .el-row {
                margin-right: 15px;
            }
        }
    }

    .user-list-table {
        min-width: 900px;

        p {
            margin-bottom: 5px;
        }

        .table-header {
            display: flex;
            text-transform: uppercase;
            font-weight: bold;
            margin-bottom: 20px;
        }

        .contact-row {
            width: 19%;

            p.name {
                font-weight: bold;
            }

            p {
                margin-bottom: 5px;
                color: #2c3e50;
            }
        }

        .status-row {
            width: 10%
        }

        .plan-row {
            width: 10%;
        }

        .joined-row {
            width: 10%;
        }

        .last-activity-row {
            width: 17%;
        }

        .asset-row {
            width: 17%;
        }

        .table-body {
            .plan-row, .joined-row, .last-activity-row, .asset-row {
                color: #485050cf;
            }
        }

        .action-row {
            width: 17%;
            display: inline-grid;

            button {
                background: none;
                border: 0px;
                text-align: left;
            }
        }

        .table-row {
            display: flex;
            padding-bottom: 30px;

            .actions {
                display: block;
            }
        }
    }

    .color-red {
        color: #d93636;
    }

    .color-pink {
        color: #ea00d8;
    }

    .color-green {
        color: #0bd603;
    }

    .color-blue {
        color: #0bacdb;
    }

    .textUppercase {
        text-transform: uppercase;
    }
    .text-white{
        color: #fff;
    }
</style>

