<template>
    <div class='popover-message' :style='{
        "width": width ? width : "inherit",
        "height": height ? height : "inherit",
        "top": top ? top : "inherit",
        "bottom": bottom ? bottom : "inherit",
        "right": right ? right : "inherit",
        "left": left ? left : "inherit"
    }'>
     {{ message }}
    </div>
</template>
<script>
    export default {
        name: 'Popover',
        props: ['message', 'width', 'height', 'top', 'bottom', 'left', 'right']
    }
</script>
<style scope lang='less'>
    .popover-message {
        position: absolute;
        border: 1px solid #99D6E9;
        background: #FFFFFF;
        border-radius: 20px;
        padding: 15px;
        text-align: left;
    }
    .popover-message::before {
        content: '';
        display: block;
        position: absolute;
        width: 10px;
        height: 10px;
        box-sizing: border-box;
        bottom: -5px;
        right: 0;
        left: 0;
        margin: auto;
        transform: rotate(223deg);
        border: 1px solid var(--color-blue);
        border-bottom-color: transparent;
        border-right-color: transparent;
        background-color: white;
        border-radius: 3px;
    }
</style>